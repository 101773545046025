import { Link } from "react-router-dom";
import SiteAlert from "../../alert";
import TitleWithCircle from "../../title";
import { separateNumberWithComma } from "../../../utilities";
import { useSelector } from "react-redux";

const PaymentTypeComponent = ({
    type = "reportage", // backlink , reportage
    PaymentMethods = {},
    PaymentMethodSelected,
    setPaymentMethodSelected = () => null,
    setShowModalAccount = () => null,
    setReceiptFile = () => null,
    ReceiptFile
}) => {

    const userData = useSelector(store => store.user);


    return (
        <>
            <div className="showCartListMainBox py-4">
                <TitleWithCircle title={"پرداخت کمپین"} />

                <div className="paymentMethodsMainBox">

                    {PaymentMethods.data.map((method, index) =>

                        <>

                            <div className={"paymentMethodCard" + ((PaymentMethodSelected.title === method.title && method.isEnable !== false) ? " active" : "") + (method.isEnable === false ? " disable" : "")}
                                onClick={
                                    () => method.isEnable !== false && setPaymentMethodSelected({ index: index, title: method.title })
                                }
                            >
                                <div className="d-flex paymentMethodCardImage">
                                    <img src={method.image} alt="" style={{ width: method.image.search("wallet") > -1 ? 26 : "auto" }} />
                                </div>
                                <div className="paymentMethodCardBody">
                                    <div className="paymentMethodCardTitle">{method.title}</div>
                                    <div className="paymentMethodCardDesc">
                                        {method.title === "برداشت از موجودی" ? `موجودی : ${separateNumberWithComma(parseInt(userData?.wallet) || 0)} تومان` : method.description}
                                    </div>
                                </div>
                                <div className="d-flex paymentMethodCardMoreInfo">
                                    {method.title !== "برداشت از موجودی" &&
                                        <>
                                            <img src="/assets/images/info-square.svg" alt="" />
                                        </>
                                    }
                                </div>
                            </div>

                        </>

                    )}

                </div>

                {PaymentMethodSelected.title === "کارت به کارت" &&

                    <>
                        <div className="uploadReceiptInCartMainBox">
                            <div className="siteTempBtn reverse" onClick={() => setShowModalAccount(true)}>
                                دریافت شماره حساب
                            </div>


                            <label className="uploadReceiptInCartBox">
                                {ReceiptFile ?
                                    <>
                                        <img src={URL.createObjectURL(ReceiptFile)} alt="" className="previewReceiptFile" />
                                        <div className="uploadReceiptInCartHeader">
                                            <div className="uploadReceiptInCartDesc">
                                                رسید شما انتخاب شده است <br />
                                                برای انتخاب دوباره کلیک کنید
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <img src="/assets/images/paper-upload.svg" alt="" />
                                        <div className="uploadReceiptInCartHeader">
                                            <div className="uploadReceiptInCartDesc">
                                                برای آپلود فایل رسید کلیک کنید
                                                یا فایل را در اینجا رها کنید
                                            </div>
                                            <div className="uploadReceiptInCartSizeLimit">
                                                حداکثر حجم 1 مگابایت
                                            </div>
                                        </div>
                                    </>
                                }
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={e => setReceiptFile(e.target.files[0])}
                                    hidden
                                />
                            </label>

                        </div>
                    </>

                }

            </div>

            <SiteAlert
                className={"paymentAlertInPage"}
                showImage={false}
                status={"error"}
            >

                <span className="font-extraBold">پس ازپرداخت </span>
                <span>کمپین، شما میتوانید به صفحه </span>
                <span style={{
                    paddingLeft: 4
                }}>
                    <Link to={"#campain"}>
                        لیست کمپین ها
                    </Link>
                </span>
                <span>
                    مراجعه کرده و با انتخاب اضافه‌کردن رپورتاژ برای این کمپین رپورتاژ‌های خود را برای پلن‌های خریداری شده انتشار دهید.
                </span>

            </SiteAlert>
        </>
    );
}

export default PaymentTypeComponent;