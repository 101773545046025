import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { isAuthenticated } from "../../utilities/auth";
import { routes } from "../../routes";

const AuthLayout = () => {

    const location = useLocation();

    if (isAuthenticated()) {
        return <Navigate to={routes.dashboard} replace />
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row authFormRowMainBox">
                    <div className="col-md-6 p-0">
                        <div className={"authLayoutRightSide" + (true ? " loginPage" : "")}>
                            <div className="siteTempTitle authLayoutRightSideTitle">
                                {/* {location.pathname === routes.auth.login ? */}
                                {true ?
                                    <>
                                        <div className="loginAuthLogoBox">
                                            <img src="/assets/images/login_logo.png" alt="" />

                                        </div>
                                    </>
                                    :
                                    <>
                                        <img src="/assets/images/authImage.png" alt="" />
                                        <div>به ویترین ادز خوش آمدید</div>
                                    </>
                                }
                            </div>

                            <div className="authLayoutRightSideFooter">
                                <Swiper
                                    pagination={{
                                        clickable: true,
                                        el: ".authPagination"
                                    }}
                                    spaceBetween={25}
                                    slidesPerView={1}
                                    className="storeMainSlider"
                                    modules={[Pagination, Autoplay]}
                                    autoplay={{
                                        delay: 5000,
                                        pauseOnMouseEnter: true,
                                    }}
                                // loop={true}
                                >
                                    {Array(3).fill("").map((item, index) =>

                                        <SwiperSlide>
                                            <div className="authSliderCard">
                                                <div className="authSliderCardTitle">
                                                    پلتفـــــرم ارتباط با رسانه
                                                </div>
                                                <div className="authSliderCardDesc">
                                                    در پنل فوتونو رپورتاژ های خود را به سادگی ثبت کرده و در تاریخ مورد نظر خود منتشر کنید .
                                                </div>
                                            </div>

                                        </SwiperSlide>

                                    )}


                                </Swiper>

                                <div className="authPagination customPagination mt-3"></div>

                                <div className="authLayoutRightSideSocialBox">
                                    <div className="authLayoutRightSideSocialCard">
                                        <img src="/assets/images/instagram-withBg.svg" alt="" />
                                    </div>
                                    <div className="authLayoutRightSideSocialCard">
                                        <img src="/assets/images/telegram-withBg.svg" alt="" />
                                    </div>
                                    <div className="authLayoutRightSideSocialCard">
                                        <img src="/assets/images/whatsapp-withBg.svg" alt="" />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 p-md-0 authFormColMainBox">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuthLayout;