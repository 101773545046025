import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { routes } from "../../routes";
import { Link } from "react-router-dom";

const AccountPopupComponent = ({
    showPopup = false,
    setShowPopup = () => null,
    setShowLogoutModal = () => null,
}) => {
    const user = useSelector(store => store.user);
    const popupRef = useRef(null);

    const ACCOUNT_POPUP_MENU = [
        {
            title: "اطلاعات کاربری",
            image: "/assets/images/user.svg",
            link: routes.profile.root,
        },
        {
            title: "ارسال تیکت جدید",
            image: "/assets/images/chat.svg",
            link: routes.ticket.add,
        },
        {
            title: "اطلاعات حساب مالی",
            image: "/assets/images/work.svg",
            link: routes.profile.financial,
        },
        {
            title: "قوانین ویترین اد",
            image: "/assets/images/document1.svg",
            link: "",
        },
    ]


    function handleClickOutside(event) {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setShowPopup(false);
        }
    }
    
    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowPopup]);

    return (
        <>
            <div className="userDropdownBoxInHeader" ref={popupRef}>

                <div className="userDropdownInHeaderDetail"
                    onClick={() => setShowPopup(!showPopup)}
                >
                    {/* <div>
                        <img src="/assets/images/user-octagon.svg" alt="" />
                    </div> */}
                    <div className="text-truncate userDropdownInHeaderDetailUser">
                        <div>کد کاربر</div>
                        <div>{user.id}</div>
                    </div>
                    <img src="/assets/images/chevron-down.svg" alt="" />
                </div>
                <div className={`headerModalItemsMainBox userDropdownInHeaderMenu${showPopup ? " show" : ""}`} >

                    <div className="userWelcomeBox">
                        <div>{user.first_name} {user.last_name}</div>
                        <div className="text-primaryColor">
                            خوش آمدید
                        </div>
                    </div>

                    <div className="userDropdownInHeaderMenuBody">
                        {ACCOUNT_POPUP_MENU.map((menu, index) =>
                            <>
                                <Link to={menu.link}>
                                    <div className="userDropdownInHeaderMenuCard">
                                        <div className="userDropdownInHeaderMenuCardImage">
                                            <img src={menu.image} alt="" />
                                        </div>
                                        <div className="userDropdownInHeaderMenuCardTitle">
                                            {menu.title}
                                        </div>
                                    </div>
                                </Link>

                            </>
                        )}

                    </div>

                    <div className="headerModalItemsFooterBox mt-3">
                        <div className="siteTempBtn"
                            onClick={setShowLogoutModal}
                        >
                            <img src="/assets/images/logout.svg" alt="" />
                            <div>خروج از حساب کاربری</div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AccountPopupComponent;