import Select, { components } from 'react-select';


export const customSelectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
        borderRadius: 8,
        fontSize: 13,
        color: "#7E8692",
        boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
        padding: "10px 15px",
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: "#7E8692",
        fontWeight: 500,
    }),

    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: "none",
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor:state.isSelected ? "#EFEFF1" : state.isFocused ? "#EFEFF1": "#fff",
        fontSize: 13,
        color: "#292D32",
    }),
}

export const customSelectStyle1 = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? '#E1E1E3' : '#E1E1E3',
        borderRadius: 8,
        fontSize: 13,
        color: "#7E8692",
        padding: "10px 15px",
    }),
    singleValue: (baseStyles, state) => ({
        ...baseStyles,
        color: "#7E8692",
        fontWeight: 500,
    }),

    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        display: "none",
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        padding: 0,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor:state.isSelected ? "#EFEFF1" : state.isFocused ? "#EFEFF1": "#fff",
        fontSize: 13,
        color: "#292D32",
    }),
}

export const indicatorContainerBg = (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: 8,
    backgroundColor: "#EFEFF1",
    padding: 3
})


export const perPageControl = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            <div>نمایش در هر صفحه</div>
            {children}
        </div>
    </components.Control>
)