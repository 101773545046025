import { setAuthToken } from "../service/axiosSetup";

export const setToken = (token) => {
    localStorage.setItem("ad_token" , token);
    setAuthToken(token);
}

export const getToken = () => {
    return localStorage.getItem("ad_token")
}

export const logoutUser = () => {
    localStorage.removeItem("ad_token");
    setAuthToken();
}

export const isAuthenticated = () => {
    const token = getToken();
    if(token){
        return true
    }
    return false
}