
const initialState = {
    currentPageTitle : "",
    stepInHeader : [],
    siteSettings : {}
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PAGE_TITLE': {
            return {
                ...state,
                currentPageTitle : action.payload
            }
        }

        case 'SET_STEP_IN_HEADER': {
            return {
                ...state,
                stepInHeader : action.payload
            }
        }

        case 'SET_SITE_SETTINGS': {
            return {
                ...state,
                siteSettings : action.payload
            }
        }
        
        default:
            return state
    }
}