import { useEffect, useState } from "react";
import VerificationInput from "react-verification-input";
import { checkCode, loginUser, sendCode } from "../../../service/auth";
import { setToken } from "../../../utilities/auth";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import LoadingButton from "../../../components/loadingButton";
import InputTemplate from "../../../components/formComponents/inputComponent";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "../../../store/action/user_action";

const ForgetPasswordPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();

    const [forgetPassordForm, setforgetPassordForm] = useState({
        emailOrPhone: "",
        verifyCode: "",
        password: "",
        confirmPassword: ""
    })

    const [LoadingAction, setLoadingAction] = useState(false)
    const [FormStep, setFormStep] = useState(1);

    const handleGoToNextStep = () => {
        if (FormStep === 1) {
            if (forgetPassordForm?.emailOrPhone?.trim() === "") {
                toast.error("ایمیل یا شماره خود را وارد کنید")
                return
            }
            handleSendCode();
        } else if (FormStep === 2) {
            handleSubmitForm();
        }
    }

    const handleSendCode = () => {
        if (forgetPassordForm?.emailOrPhone?.trim() === "") {
            toast.error("ایمیل یا شماره خود را وارد کنید")
            return
        }

        const sendData = {
            phoneOrEmail: forgetPassordForm?.emailOrPhone?.trim()
        }

        sendCode(sendData).then(({ data }) => {
            toast.success(data?.message || "لینک بازیابی رمز عبور برای شما ارسال شد.")
            setFormStep((oldValue) => oldValue + 1)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSubmitForm = () => {
        if (forgetPassordForm.emailOrPhone?.trim() === "") {
            toast.error("ایمیل یا شماره خود را وارد کنید")
            return
        }
        if (forgetPassordForm.verifyCode?.toString()?.trim() === "") {
            toast.error("کد ارسال شده را وارد کنید")
            return
        }
        if (forgetPassordForm.password?.trim() === "") {
            toast.error("رمز عبور نمیتواند خالی باشد")
            return
        }
        if (forgetPassordForm.confirmPassword?.trim() === "") {
            toast.error("تکرار رمز عبور نمیتواند خالی باشد")
            return
        }
        if (forgetPassordForm.confirmPassword !== forgetPassordForm.password) {
            toast.error("رمز عبور با تکرار رمز عبور برابر نیست!")
            return
        }

        setLoadingAction(true)

        let sendData = {
            phoneOrEmail: forgetPassordForm.emailOrPhone.trim(),
            verify_code: forgetPassordForm.verifyCode.trim(),
            password: forgetPassordForm.password,
        }

        // return;

        checkCode(sendData).then(({ data }) => {
            // setToken(data.token);
            toast.success("رمز عبور شما با موفقیت تغییر کرد!")
            // dispatch(getUserDataAction())
            navigate(routes.auth.login)
            setLoadingAction(false)
        }).catch((err) => {
            setLoadingAction(false)
            console.log(err)
        })

    }

    useEffect(() => {
        if (searchParams.get("verify_code")) {
            setforgetPassordForm({
                ...forgetPassordForm,
                verifyCode: searchParams.get("verify_code")
            });
            setFormStep(2)
        }
    }, [searchParams])


    return (
        <>
            <div className="authMainBox">

                <div className="authMainContactBox">
                    <div className="authMainContactBoxHeader">
                        <img src="/assets/images/calling.svg" alt="" />
                        <div>تماس با ما</div>
                    </div>
                    <div className="authMainContactBoxNumber">
                        09901104954
                    </div>
                </div>

                <div>

                    <div className="siteTempTitle authMainTitle">فراموشی رمز عبور</div>

                    {FormStep >= 1 &&
                        <>
                            <div className="authMainFormBox">
                                <InputTemplate
                                    key={"ایمیل یا شماره تلفن"}
                                    title={"ایمیل یا شماره تلفن"}
                                    parentClassName={"mb-3"}
                                    name={"email"}
                                    type="text"
                                    onChange={(e) => setforgetPassordForm({ ...forgetPassordForm, emailOrPhone: e.target.value })}
                                />

                            </div>
                        </>
                    }

                    {FormStep >= 2 &&
                        <>
                            <div className="authMainFormBox">
                                {/* <InputTemplate
                                    key={"ایمیل یا شماره تلفن"}
                                    title={"ایمیل یا شماره تلفن"}
                                    parentClassName={"mb-3"}
                                    type="text"
                                    onChange={(e) => setforgetPassordForm({ ...forgetPassordForm, emailOrPhone: e.target.value })}
                                /> */}

                                <InputTemplate
                                    key={"verifyCode"}
                                    title={"کد تایید"}
                                    parentClassName={"mb-3"}
                                    name={"verifyCode"}
                                    type="text"
                                    onChange={(e) => setforgetPassordForm({ ...forgetPassordForm, verifyCode: e.target.value })}
                                />

                                {/* <div className="d-flex justify-content-center">
                                    <VerificationInput
                                        length={5}
                                        validChars="0-9"
                                        classNames={{
                                            container: "verifyInputsBox_container",
                                            character: "verifyInputsBox_character",
                                            characterInactive: "verifyInputsBox-character--inactive",
                                            characterSelected: "verifyInputsBox-character--selected",
                                            characterFilled: "verifyInputsBox-character--filled",
                                        }}

                                    />
                                </div> */}

                                <InputTemplate
                                    key={"password"}
                                    title={"رمز عبور"}
                                    parentClassName={"mb-3"}
                                    type="password"
                                    onChange={(e) => setforgetPassordForm({ ...forgetPassordForm, password: e.target.value })}
                                />

                                <InputTemplate
                                    key={"confirmPassword"}
                                    title={"تکرار رمز عبور"}
                                    parentClassName={"mb-3"}
                                    type="password"
                                    onChange={(e) => setforgetPassordForm({ ...forgetPassordForm, confirmPassword: e.target.value })}
                                />

                            </div>
                        </>
                    }


                    <div className="authFooterMainBox">
                        <div className="d-flex gap-2 justify-content-end">
                            <LoadingButton
                                loading={LoadingAction}
                                onClick={handleGoToNextStep}
                                className={"justify-content-between"}
                                style={{
                                    width: 160
                                }}
                            >
                                <div>ارسال</div>
                                <img src="/assets/images/left-chevron.svg" alt="" />
                            </LoadingButton>
                        </div>


                    </div>
                </div>
                <div className="authFooterMainBoxText">
                    <span className="ps-2">بازگشت به </span>
                    <span>
                        <Link to={routes.auth.login}>
                            ورود به حساب کاربری
                        </Link>
                    </span>
                </div>

            </div>
        </>
    );
}

export default ForgetPasswordPage;