import { useEffect, useState } from "react";
import jMoment from "moment-jalaali";
import { useDispatch } from "react-redux";
import { getAllCampaign } from "../../../service/campaign";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import ReportStatus from "../../../components/reportStatus";
import SitePagination from "../../../components/pagination";
import { getUserReportage } from "../../../service/reportage";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import { perPageArr, reportageStatusArray } from "../../../utilities/config";
import { customSelectStyle, perPageControl } from "../../../components/reactSelectComponens";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import BacklinkListTable from "../../../components/backlinkListTable";
import { getUserBacklink } from "../../../service/backlink";
import SiteModal from "../../../components/siteModal";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
        </div>
    </components.Control>
);

const MyBackLinkListPage = () => {

    const dispatch = useDispatch();

    const SHOW_EDIT_REPORTAGE = ["در انتظار انتشار", "رد شده"];

    const [ReportageData, setReportageData] = useState({
        loading: true,
        data: {}
    })
    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: "all",
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(12)
    const [ReasonModal, setReasonModal] = useState({
        show : false,
        data : {},
    })

    const handleGetCampaign = () => {
        setShowMoreOption(-1);
        getUserBacklink(CurrentPage, PerPage).then(({ data }) => {
            setReportageData({

                loading: false,
                data

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleShowMoreButton = (index) => {
        if (index === ShowMoreOption) {
            setShowMoreOption(-1)
        } else {
            setShowMoreOption(index)
        }
    }

    const handleShowReasonModal = (backlink) => {
        setReasonModal({
            show : true,
            data : backlink
        })
    }

    const handleCloseResonModal = () => {
        setReasonModal({
            show : false,
            data : {}
        })
    }

    useEffect(() => {
        handleGetCampaign();
        dispatch(setCurrentPageTitle_action("بک لینک های من"));
    }, [])


    return (
        <>
            <Helmet>
                <title>
                    بک لینک های من
                </title>
            </Helmet>

            <SiteModal
                modalDesign={1}
                // title={"رپورتاژ خبر"}
                showModal={ReasonModal.show}
                modalClass="reasonModalContentBox"
                closeModal={() => handleCloseResonModal()}
            >

                <div className="reasonModalHeader">
                    <img src="/assets/images/warning-2.svg" alt="" />
                    <div className="reasonModalHeaderTitle">علت رد بک لینک</div>
                </div>

                <div className="reasonModalText">
                    {ReasonModal.data?.reason}
                </div>


            </SiteModal>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2">
                    <div className="reportageSearchBox inMyReportagePage">
                        <input
                            type="text"
                            className="siteTempInput"
                            placeholder="جستجو براساس نام رسانه، کد و عنوان رپورتاژ"
                            onChange={e => setActiveFilters({ ...ActiveFilters, searchText: e.target.value })}
                        />
                        <div className="reportageSearchImage"
                        // onClick={handleGetReportages}
                        >
                            <img src="/assets/images/search.svg" alt="" />
                        </div>
                    </div>

                    <Select
                        defaultValue={reportageStatusArray[0]}
                        options={reportageStatusArray}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                </div>

                <div className="d-flex gap-2">
                    <div className="siteTempBtn blueBg noHover">
                        <img src="/assets/images/excel.svg" alt="" />
                        <div>
                            خروجی اکسل
                        </div>
                    </div>
                    <Link to={routes.backLink.campaigns} className="d-flex">
                        <div className="siteTempBtn primary">
                            لیست کمپین ها
                        </div>
                    </Link>
                </div>
            </div>
            <div className="customBox fullHeightTableInPage">
                <BacklinkListTable
                    loading={ReportageData.loading}
                    tableData={ReportageData.data}
                    currentPage={CurrentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={PerPage}
                    setPerPage={setPerPage}
                    handleShowReason={handleShowReasonModal}
                />

            </div>
        </>
    );
}

export default MyBackLinkListPage;