import { getSiteSettings } from "../../service/siteData";

export const setCurrentPageTitle_action = (title) => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type: "SET_PAGE_TITLE", payload: title});
        }, 100);
    }
}

export const setStepItemInHeader = (stepArr) => {
    return dispatch => {
        dispatch({ type: "SET_STEP_IN_HEADER", payload: stepArr});
    }
}

export const getSiteSettingsAction =  (stepArr) => {
    return async dispatch => {
        const {data} = await getSiteSettings();
        dispatch({ type: "SET_SITE_SETTINGS", payload: data});
    }
}