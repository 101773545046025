import { useState } from "react"
import { toast } from "react-toastify"
import { registerUser } from "../../../service/auth"
import { setToken } from "../../../utilities/auth"
import InputTemplate from "../../../components/formComponents/inputComponent"
import { Link, useNavigate } from "react-router-dom"
import { routes } from "../../../routes"
import LoadingButton from "../../../components/loadingButton"
import { getUserDataAction } from "../../../store/action/user_action"
import { useDispatch } from "react-redux"
import { getUserCartAction } from "../../../store/action/cart_action"
import { getUserBacklinkCartAction } from "../../../store/action/backlink_cart_action"
import { getUserForeignReportageCartAction } from "../../../store/action/foreign_reportage_cart_action"
import VerifuEmailComponent from "../login/verify_email"

const RegisterPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [NeedToVerify, setNeedToVerify] = useState(false)
    const [LoginRes, setLoginRes] = useState({})

    const emailReg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const phoneReg = /^(\+98|0)?9\d{9}$/;

    const [RegisterForm, setRegisterForm] = useState({
        name: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        confirmPassword: "",
    })

    const [LoadingAction, setLoadingAction] = useState(false)

    const handleRegister = () => {
        if (RegisterForm.name.trim() === "") {
            toast.error("نام را وارد کنید")
            return
        }
        if (RegisterForm.lastName.trim() === "") {
            toast.error("نام خانوادگی را وارد کنید")
            return
        }
        if (RegisterForm.phoneNumber.trim() === "") {
            toast.error("شماره همراه را وارد کنید")
            return
        }
        if (!phoneReg.test(RegisterForm.phoneNumber.trim())) {
            toast.error("شماره همراه وارد شده معتبر نیست")
            return
        }
        if (RegisterForm.email.trim() === "") {
            toast.error("ایمیل را وارد کنید")
            return
        }
        // if (!emailReg.test(RegisterForm.email.trim())) {
        //     toast.error("ایمیل وارد شده معتبر نیست")
        //     return
        // }
        if (RegisterForm.password.trim() === "") {
            toast.error("رمز عبور را وارد کنید")
            return
        }
        if (RegisterForm.password.trim() !== RegisterForm.confirmPassword.trim()) {
            toast.error("رمز عبور و تکرار رمز عبور با هم برابر نیست!")
            return
        }

        setLoadingAction(true)

        let sendData = {
            first_name: RegisterForm.name.trim(),
            last_name: RegisterForm.lastName.trim(),
            email: RegisterForm.email.toLocaleLowerCase().trim(),
            phone: RegisterForm.phoneNumber.trim(),
            password: RegisterForm.password.trim(),
            re_try_password: RegisterForm.confirmPassword.trim(),
        }

        registerUser(sendData).then(({ data }) => {
            setLoadingAction(false)
            console.log({data})
            // if (!data?.need_verify) {
            //     setToken(data.token);
            //     toast.success("اکانت شما با موفقیت ساخته شد!")
            //     dispatch(getUserDataAction())
            //     dispatch(getUserCartAction())
            //     dispatch(getUserBacklinkCartAction())
            //     dispatch(getUserForeignReportageCartAction())
            //     navigate(routes.dashboard)
            // } else {
            // }
            setNeedToVerify(true)
            setLoginRes(data)
        }).catch((err) => {
            setLoadingAction(false)
            console.log(err)
        })

    }

    return (
        <>
            <div className="authMainBox">
                {NeedToVerify ?
                    <>
                        <VerifuEmailComponent emailOrPhone={RegisterForm?.email} LoginRes={LoginRes} />
                    </>
                    :
                    <>
                        <div className="authMainContactBox">
                            <div className="authMainContactBoxHeader">
                                <img src="/assets/images/calling.svg" alt="" />
                                <div>تماس با ما</div>
                            </div>
                            <div className="authMainContactBoxNumber">
                                09901104954
                            </div>
                        </div>
                        <div>

                            <div className="siteTempTitle authMainTitle">ساخت حساب جدید</div>

                            <div className="authMainFormBox">
                                <div className="d-flex align-items-center gap-3 mb-3">

                                    <InputTemplate
                                        key={"نام"}
                                        title={"نام"}
                                        value={RegisterForm.name}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, name: e.target.value })}
                                    />

                                    <InputTemplate
                                        key={"نام خانوادگی"}
                                        title={"نام خانوادگی"}
                                        value={RegisterForm.lastName}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, lastName: e.target.value })}
                                    />

                                </div>
                                <div className="d-flex align-items-center gap-3 mb-3">

                                    <InputTemplate
                                        key={"شماره همراه"}
                                        title={"شماره همراه"}
                                        value={RegisterForm.phoneNumber}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, phoneNumber: e.target.value.replace(/\D+/g, '') })}
                                    />

                                    <InputTemplate
                                        key={"ایمیل"}
                                        title={"ایمیل"}
                                        type="email"
                                        value={RegisterForm.email}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, email: e.target.value })}
                                    />

                                </div>
                                <div className="d-flex align-items-center gap-3 mb-3">

                                    <InputTemplate
                                        key={"رمزعبور"}
                                        title={"رمزعبور"}
                                        type="password"
                                        value={RegisterForm.password}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, password: e.target.value })}
                                    />

                                    <InputTemplate
                                        key={"تکرار رمز عبور"}
                                        title={"تکرار رمز عبور"}
                                        type="password"
                                        value={RegisterForm.confirmPassword}
                                        onChange={(e) => setRegisterForm({ ...RegisterForm, confirmPassword: e.target.value })}
                                    />

                                </div>
                            </div>

                            <div className="authFooterMainBox">
                                <div className="d-flex gap-2 justify-content-end">
                                    {/* <div className="siteTempBtn design1">
                                <div>ورود با گــوگل</div>
                                <img src="/assets/images/google-logo.svg" alt="" />
                            </div> */}
                                    <LoadingButton
                                        loading={LoadingAction}
                                        onClick={handleRegister}
                                        className={"justify-content-between"}
                                        style={{
                                            width: 160
                                        }}
                                    >
                                        <div>ساخت حساب</div>
                                        <img src="/assets/images/left-chevron.svg" alt="" />
                                    </LoadingButton>
                                </div>


                            </div>
                        </div>
                        <div className="authFooterMainBoxText">
                            <span className="ps-2">حساب کاربری ندارید ؟</span>
                            <span>
                                <Link to={routes.auth.login}>
                                    ورود به حساب کاربری
                                </Link>
                            </span>
                        </div>
                    </>
                }

            </div>
        </>
    );
}

export default RegisterPage;