import { customAxios } from "./axiosSetup";

export const getUserCart = (type) => customAxios.get(`/cart?type=${type}`);

export const addToCart = (data) => customAxios.post(`/cart` , data);

export const deleteFromCart = (type , id , data) => customAxios.put("/cart/" + id + `?type=${type}`, data);

export const deleteCart = (type , id ) => customAxios.delete("/cart/" + id + `?type=${type}` );

export const getPaymentMethods = () => customAxios.get("/order");

export const payOrderApi = (data) => customAxios.post("/order" , data , {
    headers: { "Content-Type": "multipart/form-data" }
});

export const discountOrder = (data) => customAxios.post("/discount_code" , data , {showToast : false});

export const verifyOrder = (transactionId) => customAxios.put("/order/" + transactionId);