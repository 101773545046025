const SiteFileUploader = ({ title, showPaper = false ,setFile = () => null , file = null}) => {
    return (
        <>
            <div className="siteTempFileUploaderMainBox">
                <div className="siteTempLabelTitle">{title}</div>

                {/* {file &&
                    <>
                        <div className="siteTempFileUploaderImagePreviewBox">
                            <img src={} alt="" />
                        </div>
                    </>
                } */}

                <label>
                    <div className="siteTempFileUploaderBox">
                        <div className="siteTempFileUploaderTitle">
                            {file ?
                                <>
                                    {file?.name}
                                </>
                            :
                                <>
                                    فایل خود را انتخاب کنید
                                </>
                            }
                        </div>

                        <div className="siteTempFileUploaderButton">
                            <div>انتخاب فایل ها</div>
                            {showPaper && (
                                <>
                                    <img src="/assets/images/paper-upload.svg" alt="paper" />
                                </>
                            )}
                        </div>

                        <input type="file" 
                            onChange={setFile}
                            hidden 
                        />
                    </div>
                </label>
            </div>
        </>
    );
};

export default SiteFileUploader;
