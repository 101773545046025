export const apiUrl = process.env.REACT_APP_API_URL || "https://api.vitrinad.ir/api";
export const storageUrl = process.env.REACT_APP_STORAGE_URL || "https://api.vitrinad.ir/storage/";

export const imageThumbUrl = "/assets/images/image-thumbnail.png";

export const appStage = process.env.REACT_APP_APP_STAGE || "production";

export const NUM_REGEX = /\D/g;

export const reportageStatusArray = [
    {
        label : "منتشر شده",
        value : "منتشر شده",
    },
    // {
    //     label : "",
    //     value : "",
    // },
]

export const perPageArr = [
    {
        label : 10,
        value : 10,
    },
    {
        label : 20,
        value : 20,
    },
]