const SiteAlert = ({children , status , showImage = true , className}) => {
    
    const statusArr = {
        "success": {
            color: "#09A363",
            image : "/assets/images/info.svg",
            statusClass : ""
        },
        "info": {
            color: "#1370AF",
            image : "/assets/images/info.svg",
            statusClass : ""
        },
        "error": {
            color: "#8C2230",
            image : "/assets/images/info-octagon.svg",
            statusClass : ""
        },
    }


    return ( 
        <>
            <div 
                className={
                    "siteTempAlertBox" + 
                    (className ? " " + className : "") +
                    (statusArr[status].statusClass ? " " + statusArr[status].statusClass : "")
                }
                style={{
                    // color: statusArr[status].color,
                    backgroundColor : statusArr[status].color + "20"
                }}
            >
                {showImage &&
                    <img src={statusArr[status].image} alt="" />
                }
                <div>
                    {children}
                </div>
            </div>
        </>
    );
}
 
export default SiteAlert;