import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false , errorMessage: ''};
    }

    componentDidCatch(error, info) {
        // Update state to indicate an error has occurred
        this.setState({ hasError: true, errorMessage: error.message });
        // You can also log the error to an error reporting service
        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            // Render custom error message
            return (
                <div className='text-center mt-5'>
                    <h4 className='mb-2'>مشکلی برای سایت پیش آمده است</h4>
                    <h5 className='mb-2'>لطفا این مشکل را به تیم پشتیبانی اطلاع دهید</h5>
                    <p>{this.state.errorMessage}</p>
                </div>
            );
        }
        // If no error occurred, render children as usual
        return this.props.children;
    }
}

export default ErrorBoundary;
