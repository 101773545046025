import { useState } from "react";
import { loginUser } from "../../../service/auth";
import { setToken } from "../../../utilities/auth";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import LoadingButton from "../../../components/loadingButton";
import InputTemplate from "../../../components/formComponents/inputComponent";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "../../../store/action/user_action";
import { getUserCartAction } from "../../../store/action/cart_action";
import { getUserBacklinkCartAction } from "../../../store/action/backlink_cart_action";
import { getUserForeignReportageCartAction } from "../../../store/action/foreign_reportage_cart_action";
import CheckboxTemplate from "../../../components/formComponents/checkboxComponent";
import VerifuEmailComponent from "./verify_email";

const LoginPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [LoginForm, setLoginForm] = useState({
        emailOrPhone: "",
        password: ""
    })

    const [NeedToVerify, setNeedToVerify] = useState(false)
    const [LoginRes, setLoginRes] = useState({})

    const [LoadingAction, setLoadingAction] = useState(false)

    const handleLogin = () => {
        if (LoginForm.emailOrPhone.trim() === "") {
            toast.error("ایمیل یا شماره خود را وارد کنید")
            return
        }
        if (LoginForm.password.trim() === "") {
            toast.error("پسورد را وارد کنید")
            return
        }

        setLoadingAction(true)

        let sendData = {
            phoneOrEmail: LoginForm.emailOrPhone.trim(),
            password: LoginForm.password.trim(),
        }

        loginUser(sendData).then(({ data }) => {
            setLoadingAction(false)
            if (!data?.need_verify) {
                setToken(data.token);
                toast.success("با موفقیت وارد شدید!")
                dispatch(getUserDataAction())
                dispatch(getUserCartAction())
                dispatch(getUserBacklinkCartAction())
                dispatch(getUserForeignReportageCartAction())
                navigate(routes.dashboard)
                console.log({data : data?.token})
            } else {
                setNeedToVerify(true)
                setLoginRes(data)
            }
        }).catch((err) => {
            setLoadingAction(false)
            console.log(err)
        })

    }


    return (
        <>
            <div className="authMainBox">



                {NeedToVerify ?
                    <>
                        <VerifuEmailComponent emailOrPhone={LoginForm?.emailOrPhone} LoginRes={LoginRes} />
                    </>
                    :
                    <>

                        <div className="authMainContactBox">
                            <div className="authMainContactBoxHeader">
                                <img src="/assets/images/calling.svg" alt="" />
                                <div>تماس با ما</div>
                            </div>
                            <div className="authMainContactBoxNumber">
                                09901104954
                            </div>
                        </div>

                        <div className="authMainFormMainBox">

                            <div className="siteTempTitle authMainTitle">ورود به حساب</div>

                            <div className="authMainFormBox">
                                <InputTemplate
                                    key={"ایمیل یا شماره تلفن"}
                                    title={"ایمیل یا شماره تلفن"}
                                    parentClassName={"mb-3"}
                                    type="text"
                                    onChange={(e) => setLoginForm({ ...LoginForm, emailOrPhone: e.target.value })}
                                />
                                <InputTemplate
                                    key={"رمــــز عبــــور"}
                                    title={"رمــــز عبــــور"}
                                    type="password"
                                    onChange={(e) => setLoginForm({ ...LoginForm, password: e.target.value })}
                                />

                            </div>

                            <div className="d-flex justify-content-end">
                                <Link to={routes.auth.forgetPassword} className="forgetPasswordLink">
                                    رمز عبور خود را فراموش کردید؟
                                </Link>
                            </div>

                            <div className="authFooterMainBox">
                                <div className="d-flex gap-2 justify-content-between align-items-center">
                                    {/* <div className="siteTempBtn design1">
                                        <div>ورود با گــوگل</div>
                                        <img src="/assets/images/google-logo.svg" alt="" />
                                    </div> */}
                                    <div className="loginRemindMeBox">
                                        <CheckboxTemplate
                                            title={"مرا به خاطر بسپار"}
                                        // onChange={e => setAcceptRoles(e.target.checked)}
                                        />
                                    </div>
                                    <LoadingButton
                                        loading={LoadingAction}
                                        onClick={handleLogin}
                                        className={"justify-content-between"}
                                        style={{
                                            width: 160
                                        }}
                                    >
                                        <div>ورود به حساب</div>
                                        <img src="/assets/images/left-chevron.svg" alt="" />
                                    </LoadingButton>
                                </div>


                            </div>
                        </div>
                        <div className="authFooterMainBoxText">
                            <span className="ps-2">حساب کاربری ندارید ؟</span>
                            <span>
                                <Link to={routes.auth.register}>
                                    ساخت حساب کاربری
                                </Link>
                            </span>
                        </div>
                    </>
                }



            </div>
        </>
    );
}

export default LoginPage;