import { Interweave } from "interweave";
import LoadingButton from "../../loadingButton";
import { handleGenerateLink, separateNumberWithComma } from "../../../utilities";
import { imageThumbUrl } from "../../../utilities/config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ReportageDetailModal = ({
    reportageData = {},
    LoadingAddToCart = false,
    handleAddItemToCart = () => null,
    handleDeleteItemFromCart = () => null,
    justLaw = false,
    isForeign = false,
    backlink = false,
    hidePrice = false,
    hideReportageHeader = false,
}) => {

    const userCart = useSelector(store => {
        if (isForeign) {
            return store.foreign_reportage_cart
        } else if (backlink) {
            return store.backlink_cart
        } else {
            return store.cart
        }
    });

    const REPORTAGE_FEATURE = [
        {
            key: "is_ad_tag",
            title: "بدون تگ تبلیغات",
        },
        {
            key: "is_link_to_other",
            title: "لینک به دامنه متفاوت",
        },
        {
            key: "is_main_page",
            title: "نمایش در صفحه اصلی",
        },
        {
            key: "is_nofollow",
            title: "درج لینک نوفالو",
        },
        {
            key: "is_share_to_social",
            title: "باز نشر در شبکه های اجتماعی",
        },
        {
            key: "is_video",
            title: "درج محتوای ویدیویی",
        },
    ]

    const [BackLinkSelectedPeriod, setBackLinkSelectedPeriod] = useState(1)

    useEffect(() => {
        if (reportageData?.price?.[0]?.is_default) {
            setBackLinkSelectedPeriod(
                reportageData?.price?.find(item => item?.is_default === 1)?.is_default || 1
            )
        }
    }, [reportageData])

    return (
        <>

            <div className="reportageFullHeaderBox">
                <div className='reportageFullHeaderBody'>
                    {!hideReportageHeader &&
                        <>
                            <div className="reportageCardHeader">
                                <div className="reportageCardHeaderImage">
                                    <img src={reportageData?.news?.image || "/assets/images/reportageImage.png"} alt="" />
                                </div>
                                <div className="reportageCardHeaderDetail">
                                    <div>
                                        <div className="siteTempTitle reportageCardHeaderTitle text-truncate">{reportageData?.title}</div>
                                        <a href={reportageData?.news?.site} target="_blank" rel="noopener noreferrer">
                                            <div className="siteTempSubtitle reportageCardHeaderCategory text-truncate">{handleGenerateLink(reportageData?.news?.site) || ""}</div>
                                        </a>
                                    </div>
                                    {(isForeign && reportageData?.info?.country) &&
                                        <div className="reportageCardHeaderDetailCountry">
                                            <img src={reportageData?.info?.country?.logo || imageThumbUrl} alt="" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }

                    <div className="reportageCardDetailBox">
                        {reportageData?.type?.length > 0 &&
                            <div className="reportageCardDetailCard">
                                <div>موضوع :</div>
                                <div>
                                    {reportageData?.type?.map((item, index) =>
                                        <>
                                            <span>{item?.title}{reportageData?.type?.length !== index + 1 && " ، "}</span>
                                        </>
                                    )}
                                </div>
                            </div>
                        }
                        {(reportageData?.info?.place_to_show && reportageData?.info?.place_to_show !== "-") &&
                            <>
                                <div className="reportageCardDetailCard">
                                    <div>محل انتشار :</div>
                                    <div>{reportageData?.info?.place_to_show || reportageData?.release_place?.title}</div>
                                </div>
                            </>
                        }
                        {backlink &&
                            <>
                                <div className="reportageCardDetailCard">
                                    <div>محل درج :</div>
                                    <div>{reportageData?.insert_place?.title}</div>
                                </div>
                            </>
                        }
                        <div className="reportageCardDetailCard">
                            <div>موضوع رسانه :</div>
                            <div>
                                {reportageData?.news?.category?.map((category, index) =>
                                    <>
                                        {category?.title} {reportageData?.news?.category?.length !== (index + 1) && " - "}
                                    </>
                                )}

                                {reportageData?.news?.category?.length === 0 && "بدون موضوع"}

                            </div>
                        </div>
                        {isForeign &&
                            <div className="reportageCardDetailCard">
                                <div>زبان رسانه :</div>
                                <div>{reportageData?.info?.language?.title}</div>
                            </div>
                        }
                    </div>
                </div>

                <div
                    className={
                        'reportageDetailMainBox' +
                        (backlink ? " d-block" : "")
                    }
                >

                    {!backlink &&
                        <>
                            <div className="reportageDetailBox">
                                <div className="reportageDetailCard">
                                    <div className="reportageDetailCardHeader">
                                        <img src="/assets/images/image.svg" alt="" />
                                        <div>تصاویر</div>
                                    </div>
                                    <div className='reportageDetailCardValue'>{reportageData?.info?.image_count}</div>
                                </div>
                                <div className="reportageDetailCard">
                                    <div className="reportageDetailCardHeader">
                                        <img src="/assets/images/editWithBox.svg" alt="" />
                                        <div>کلمات</div>
                                    </div>
                                    <div className='reportageDetailCardValue'>{reportageData?.info?.max_word}</div>
                                </div>
                                <div className="reportageDetailCard">
                                    <div className="reportageDetailCardHeader">
                                        <img src="/assets/images/followLink.svg" alt="" />
                                        <div>لینک فالو</div>
                                    </div>
                                    <div className='reportageDetailCardValue'>{reportageData?.info?.follow_link_count}</div>
                                </div>
                                <div className="reportageDetailCard">
                                    <div className="reportageDetailCardHeader">
                                        <img src="/assets/images/noFollow.svg" alt="" />
                                        <div>لینک نوفالو</div>
                                    </div>
                                    <div className='reportageDetailCardValue'>{reportageData?.info?.nofollow_link_count}</div>
                                </div>

                            </div>
                        </>
                    }

                    {backlink &&
                        <>
                            <div className="backlinkLinkTypeBox">
                                <span>لینک : </span>
                                <span>{reportageData?.is_follow === 1 ? "فالو" : "نو فالو"}</span>
                            </div>
                        </>
                    }


                    <div className="reportageDetailBox">
                        <div className="reportageDetailCard">
                            <div className="reportageDetailCardHeader">
                                <div className='reportageDetailCardHeaderShortName'>DA</div>
                                <div>قدرت دامنه</div>
                            </div>
                            <div className='reportageDetailCardValue'>{reportageData?.news?.da}</div>
                        </div>
                        <div className="reportageDetailCard">
                            <div className="reportageDetailCardHeader">
                                <div className='reportageDetailCardHeaderShortName'>DR</div>
                                <div>رتبه دامنه</div>
                            </div>
                            <div className='reportageDetailCardValue'>{reportageData?.news?.pa}</div>
                        </div>
                        {/* <div className="reportageDetailCard">
                            <div className="reportageDetailCardHeader">
                                <div className='reportageDetailCardHeaderShortName'>SW</div>
                                <div>سیمیلار وب</div>
                            </div>
                            <div className='reportageDetailCardValue'>{reportageData?.news?.da}</div>
                        </div> */}
                        <div className="reportageDetailCard">
                            <div className="reportageDetailCardHeader">
                                <div className='reportageDetailCardHeaderShortName'>SS</div>
                                <div>اسپم اسکور</div>
                            </div>
                            <div className='reportageDetailCardValue'>{reportageData?.news?.ss}</div>
                        </div>
                    </div>

                </div>


            </div>

            {!justLaw &&
                <>
                    {!hidePrice &&
                        <>
                            <div className="reportageModalBuyBox">
                                <div className="reportageModalBuyAddToCardBox">
                                    <div className="reportageModalBuyAddToCard">
                                        {backlink ?
                                            <>
                                                {userCart?.carts[reportageData?.id]?.[BackLinkSelectedPeriod] ?
                                                    <>
                                                        <div className="reportageCardCount m-2">
                                                            <div className="reportageCardCountOpt"
                                                                onClick={() => handleAddItemToCart(BackLinkSelectedPeriod || -1)}
                                                            >
                                                                <img src="/assets/images/plus.svg" alt="" />

                                                            </div>
                                                            <div className="reportageCardCountNum">{userCart?.carts[reportageData?.id]?.[BackLinkSelectedPeriod]?.length}</div>
                                                            <div className="reportageCardCountOpt minus"
                                                                onClick={() => handleDeleteItemFromCart(userCart?.carts[reportageData?.id]?.[BackLinkSelectedPeriod]?.[0]?.id, reportageData?.id, userCart?.carts[reportageData?.id]?.[BackLinkSelectedPeriod]?.[0])}
                                                            >
                                                                <img src="/assets/images/minus.svg" alt="" />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <LoadingButton
                                                            className={"half-radius justify-content-center"}
                                                            loading={LoadingAddToCart}
                                                            onClick={() => handleAddItemToCart(BackLinkSelectedPeriod || -1)}
                                                        >
                                                            اضافه به سبد خرید
                                                        </LoadingButton>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {userCart?.carts[reportageData?.id] ?
                                                    <>
                                                        <div className="reportageCardCount m-2">
                                                            <div className="reportageCardCountOpt"
                                                                onClick={() => handleAddItemToCart(BackLinkSelectedPeriod || -1)}
                                                            >
                                                                <img src="/assets/images/plus.svg" alt="" />

                                                            </div>
                                                            <div className="reportageCardCountNum">{userCart?.carts[reportageData?.id]?.length}</div>
                                                            <div className="reportageCardCountOpt minus"
                                                                onClick={() => handleDeleteItemFromCart(userCart?.carts[reportageData?.id]?.[0]?.id, reportageData?.id, userCart?.carts[reportageData?.id]?.[0])}
                                                            >
                                                                <img src="/assets/images/minus.svg" alt="" />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <LoadingButton
                                                            className={"half-radius justify-content-center"}
                                                            loading={LoadingAddToCart}
                                                            onClick={() => handleAddItemToCart(BackLinkSelectedPeriod || -1)}
                                                        >
                                                            اضافه به سبد خرید
                                                        </LoadingButton>
                                                    </>
                                                }
                                            </>
                                        }

                                    </div>
                                    <div className="reportageModalBuyPriceBox">
                                        <div className="reportageCardprice ">
                                            {(parseFloat(reportageData?.discount || 0) > 0) &&
                                                <div className="reportageCardRealPrice">{separateNumberWithComma((reportageData?.price?.is_default && reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || reportageData?.price || 0)}</div>
                                            }
                                            {(backlink && parseFloat(reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent || 0) > 0) &&
                                                <div className="reportageCardRealPrice">{separateNumberWithComma((reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || 0)}</div>
                                            }
                                            <div className="reportageCardCurrentPrice">{separateNumberWithComma(reportageData?.final_price || reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.final_price || 0)}</div>
                                            <div className="reportageCardUnit">تومان</div>
                                        </div>
                                    </div>
                                </div>
                                {reportageData?.discount > 0 &&

                                    <div className="siteTempBtn half-radius reportageModalBuyDiscountBox">
                                        <div> {reportageData?.discount}% تخفیف ویژه </div>
                                        <img src="/assets/images/star.svg" alt="" />
                                    </div>

                                }
                                {backlink && parseFloat(reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent || 0) > 0 &&

                                    <div className="siteTempBtn half-radius reportageModalBuyDiscountBox">
                                        <div> {parseFloat(reportageData?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent || 0)}% تخفیف ویژه </div>
                                        <img src="/assets/images/star.svg" alt="" />
                                    </div>

                                }
                            </div>

                            {backlink &&
                                <>
                                    <div className="reportageModalBacklinkPeriodBox">
                                        {reportageData?.price?.map((price, index) =>
                                            <>
                                                <div
                                                    className={
                                                        "reportageModalBacklinkPeriodCard" +
                                                        ((BackLinkSelectedPeriod === price?.month) ? " active" : "")
                                                    }
                                                    onClick={() => setBackLinkSelectedPeriod(price?.month)}
                                                >
                                                    <div>{price?.month} ماهه</div>
                                                    <div>
                                                        <span>{separateNumberWithComma(price?.price)} </span>
                                                        <span className="currency">تومان</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            }
                        </>
                    }

                    {!backlink &&
                        <>
                            <div className="reportageFullMoreDetailBox">
                                <div className="siteTempTitle reportageFullMoreDetailTitle">
                                    ویژگی ها رسانه
                                </div>
                                <div className="reportageFullFeaturesBox">
                                    {REPORTAGE_FEATURE.map((item, index) =>
                                        <>
                                            <div className={"reportageFullFeaturesCard" + (reportageData?.info?.[item.key] === 0 ? " disableFeature" : "")}>
                                                <img src={reportageData?.info?.[item.key] === 0 ? "/assets/images/times-square.svg" : "/assets/images/tick-square.svg"} alt="" />
                                                <div>{item.title}</div>
                                            </div>
                                        </>
                                    )}
                                    {/* <div className="reportageFullFeaturesCard disableFeature">
                                                    <img src="/assets/images/times-square.svg" alt="" />
                                                    <div>لینک به دامنه متفاوت</div>
                                                </div>
                                                <div className="reportageFullFeaturesCard disableFeature">
                                                    <img src="/assets/images/times-square.svg" alt="" />
                                                    <div>درج لینک نوفالو</div>
                                                </div>
                                                <div className="reportageFullFeaturesCard">
                                                    <img src="/assets/images/tick-square.svg" alt="" />
                                                    <div>درج محتوای ویدیویی</div>
                                                </div>
                                                <div className="reportageFullFeaturesCard disableFeature">
                                                    <img src="/assets/images/times-square.svg" alt="" />
                                                    <div>باز نشر در شبکه های اجتماعی</div>
                                                </div>
                                                <div className="reportageFullFeaturesCard">
                                                    <img src="/assets/images/tick-square.svg" alt="" />
                                                    <div>نمایش در صفحه اصلی</div>
                                                </div> */}
                                </div>
                                {reportageData?.info?.example_title ?
                                    <>
                                        <div className="siteTempTitle reportageFullMoreDetailTitle">
                                            نمونه رپورتاژ ها
                                        </div>
                                        <div className="reportageFullSampleBox">
                                            {/* <div className="reportageFullSampleTitle">
                                                            نمونه رپورتاژ ها
                                                        </div> */}
                                            <div className="reportageFullSampleBody">
                                                <div className="reportageFullSampleCard">
                                                    <a href={reportageData?.info?.example_link} target='_blank' rel="noreferrer">
                                                        {reportageData?.info?.example_title}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </>
                    }

                </>
            }


            <div className="reportageFullRolesMainBox">
                <div className="reportageFullRolesHeaderBox">
                    <div className='siteTempTitle'>قوانین</div>
                    <hr />
                </div>
                <div className="reportageFullRolesCard">
                    <div className="reportageFullRolesCardTitle text-danger">
                        موضوعات ممنوعه {!backlink ? "رپورتاژ" : "بک لینک"}
                    </div>
                    <div className="reportageFullRolesCardBody text-danger">
                        {(() => {
                            let arr = JSON.parse(reportageData?.info?.forbid_title || reportageData?.forbid_title || "[]");
                            let text = "";
                            if (Array.isArray(arr)) {

                                text = arr.map((title, index) =>
                                    <>
                                        {title} {(arr.length) - 1 !== index && "/"}
                                    </>
                                )
                                if (arr.length === 0) {
                                    // text = "موضوع ممنوعی برای این رسانه وجود ندارد"
                                    text = ""
                                }
                            }

                            return text || ""
                        })()}
                    </div>
                </div>
                <div className="reportageFullRolesCard">
                    <div className="reportageFullRolesCardTitle">
                        موضوعات نیازمند تایید رسانه
                    </div>
                    <div className="reportageFullRolesCardBody boldDetail">
                        {(() => {
                            let arr = JSON.parse(reportageData?.info?.need_confirm_title || reportageData?.need_confirm_title || "[]");
                            let text = "";
                            if (Array.isArray(arr)) {

                                text = arr.map((title, index) =>
                                    <>
                                        {title} {(arr.length) - 1 !== index && " - "}
                                    </>
                                )

                                if (arr.length === 0) {
                                    // text = "هیچ موضوعی نیاز مند تایید نیست"
                                    text = ""
                                }
                            }
                            return text || ""
                        })()}
                    </div>
                </div>
                {!backlink &&
                    <>
                        {/* <div className="reportageFullRolesCard">
                            <div className="reportageFullRolesCardTitle">
                                مهلت انتشار
                            </div>
                            <div className="reportageFullRolesCardBody text-danger boldDetail">
                                <div>45 روز</div>
                            </div>
                        </div> */}
                    </>
                }
                <div className="reportageFullRolesCard">
                    <div className="reportageFullRolesCardTitle">
                        قوانین خاص رسانه
                    </div>
                </div>
                <div className="reportageFullRolesCardBody">
                    <Interweave
                        content={reportageData?.info?.law}
                    />
                    {/* <div className='text-success mb-1'>قوانین ناشر : </div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div>
                                <div className='text-success mb-1'>قوانین ناشر : </div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div>
                                <div className='mb-1'>بعد از انتشار رپورتاژ تا سه روز امکان ویرایش وجود دارد</div> */}
                </div>
            </div>

        </>
    );
}

export default ReportageDetailModal;