import { useState } from "react";
import { separateNumberWithComma } from "../../utilities";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import TitleWithCircle from "../title";
import StepPattern from "../stepPattern";
import { logoutUser } from "../../utilities/auth";
import SiteModal from "../siteModal";
import AccountPopupComponent from "./accountPopup";
import NotificationPopupComponent from "./notificationPopup";
import { emptyCartAction } from "../../store/action/cart_action";
import Sidebar from "../sidebar";

const Header = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userCart = useSelector(store => store.cart)
    const user = useSelector(store => store.user)
    const app = useSelector(store => store.app)

    const ACCOUNT_POPUP_MENU = [
        {
            title: "اطلاعات کاربری",
            image: "/assets/images/user.svg",
            link: "",
        },
        {
            title: "ارسال تیکت جدید",
            image: "/assets/images/chat.svg",
            link: "",
        },
        {
            title: "اطلاعات حساب مالی",
            image: "/assets/images/work.svg",
            link: "",
        },
        {
            title: "قوانین ویترین اد",
            image: "/assets/images/document1.svg",
            link: "",
        },
    ]

    const [ShowNotification, setShowNotification] = useState(false);
    const [ShowAccountPopUp, setShowAccountPopUp] = useState(false);
    const [ShowLogoutModal, setShowLogoutModal] = useState(false);


    const handleLogoutUser = () => {
        dispatch(emptyCartAction());
        logoutUser();
        navigate(routes.auth.login)
    }

    return (
        <>

            <SiteModal
                showModal={ShowLogoutModal}
                modalDesign={3}
                closeModal={() => setShowLogoutModal(false)}
            >

                <div className="logoutModalImage">
                    <img src="/assets/images/logout.svg" alt="" />
                </div>

                <div className="siteTempTitle logoutModalTitle">
                    آیا می خواهید از حساب کاربری خود خارج شوید ؟
                </div>

                <div className="logoutModalFooter">
                    <div className="siteTempBtn primary"
                        onClick={handleLogoutUser}
                    >
                        بله خارج می شوم
                    </div>
                    <div className="siteTempBtn design4"
                        onClick={() => setShowLogoutModal(false)}
                    >
                        انصراف
                    </div>
                </div>

            </SiteModal>

            <div className="sideBarMenuResMainBox">
                <div className="sideBarMenuResBox">
                    <Sidebar />
                </div>
            </div>

            <header className="headerMainBox">

                <div className="resMenuIconInHeader">
                    <img src="/assets/images/hamberMenu.svg" alt="" />
                </div>

                <TitleWithCircle
                    className={"pageTitleInHeader"}
                    title={app.currentPageTitle}
                />

                {/* <Link to={routes.cart.list}>

                <div className="headerCartBox">
                    <img src="/assets/images/cart.svg" alt="" />
                    <div>سبدخرید</div>
                    <div className="headerCartBoxCount">{userCart?.carts?.length || 0}</div>
                </div>
                </Link> */}

                <div className="d-flex align-items-center gap-3">

                    {app.stepInHeader?.length > 0 &&
                        <StepPattern stepArray={app.stepInHeader} />
                    }

                    <div className="d-flex align-items-center gap-3 walletMainBoxInHeader">
                        <div className="walletMainBoxInHeaderTitle">
                            <img src="/assets/images/wallet.svg" alt="" />
                            <div>موجودی</div>
                        </div>
                        <div className="walletMainBoxInHeaderBalance">
                            <div>{separateNumberWithComma(parseInt(user?.wallet) || 0)}</div>
                            <div className="unit">تومان</div>
                        </div>
                        <Link to={routes.financial.onlinePayment}>
                            <div className="walletMainBoxInHeaderDeposit">
                                <div>شارژ حساب</div>
                                <img src="/assets/images/plus.svg" alt="" />
                            </div>
                        </Link>
                    </div>

                    <NotificationPopupComponent
                        showPopup={ShowNotification}
                        setShowPopup={setShowNotification}
                    />

                    <AccountPopupComponent
                        showPopup={ShowAccountPopUp}
                        setShowPopup={setShowAccountPopUp}
                        setShowLogoutModal={() => setShowLogoutModal(true)}
                    />

                </div>


            </header>
        </>
    );
}

export default Header;