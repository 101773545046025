import jMoment from "moment-jalaali";
import ReportStatus from "../../components/reportStatus";
import TableComponent from "../../components/table";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useEffect, useRef, useState } from "react";
import { getAllTicket } from "../../service/ticket";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../store/action/app_action";
import { Helmet } from "react-helmet-async";
import { LoadingData } from "../../components/loading";
import NoDataComponent from "../../components/noData";


const TicketsList = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const orderByBox = useRef(null)

    const SORT_ARR = [
        {
            label: "جدیدترین",
            value: "DESC",
        },
        {
            label: "قدیمی ترین",
            value: "ASC",
        },
    ]

    const ORDER_BY = [
        {
            label: "همه",
            value: -1,
        },
    ]

    const STATUS_ARR = [
        {
            label: "همه",
            value: -1,
        },
        {
            label: "در جریان",
            value: 1,
        },
        {
            label: "پاسخ داده شده",
            value: 2,
        },
        {
            label: "بسته شده",
            value: 3,
        },
    ]

    const [AllTickets, setAllTickets] = useState({
        loading: false,
        data: {}
    })
    const [CurrentPage, setCurrentPage] = useState(1);
    const [SelectedOrderBy, setSelectedOrderBy] = useState(ORDER_BY[0])
    const [SelectedStatus, setSelectedStatus] = useState(STATUS_ARR[0])
    const [SelectedSort, setSelectedSort] = useState(SORT_ARR[0]);
    const [ShowOrderByBox, setShowOrderByBox] = useState(false)

    const TableHead = [
        {
            title: "شماره تیکت",
            id: "number",
            className: "",
        },
        {
            title: "عنوان",
            id: "title",
            className: "",
        },
        {
            title: "وضعیت",
            id: "status",
            className: "",
        },
        {
            title: "تاریخ درخواست",
            id: "createdAt",
            className: "text-center",
        },
    ]

    const handleGetAllTickets = () => {

        if (AllTickets.loading) return

        setAllTickets({
            ...AllTickets,
            loading: true,
        })
        getAllTicket({
            page: CurrentPage,
            perPage: 12,
            orderBy: SelectedOrderBy?.value != "-1" ? SelectedOrderBy?.value : undefined,
            status: SelectedStatus?.value != "-1" ? SelectedStatus?.value : undefined,
            sort: SelectedSort?.value,
        }).then(({ data }) => {
            setAllTickets({
                loading: false,
                data
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (orderByBox.current && !orderByBox.current.contains(event.target)) {
                setShowOrderByBox(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowOrderByBox]);

    useEffect(() => {
        handleGetAllTickets();
    }, [CurrentPage , SelectedOrderBy?.value , SelectedSort?.value , SelectedStatus?.value])

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("تیکت ها"))
    }, [])



    return (
        <>
            <Helmet>
                <title>
                    تیکت ها
                </title>
            </Helmet>

            <div className="customBox mt-3">
                <div className="d-flex align-items-center justify-content-end gap-2">
                    <Link to={routes.ticket.add}>
                        <div className="siteTempBtn">
                            <div>ثبت تیکت جدید</div>
                            <img src="/assets/images/edit.svg" alt="" />
                        </div>
                    </Link>
                    <div className="position-relative" ref={orderByBox}>
                        <div className="siteTempBtn design2" onClick={() => setShowOrderByBox(!ShowOrderByBox)}>
                            <div>مرتب سازی بر اساس</div>
                            <img src="/assets/images/filter.svg" alt="" />
                        </div>
                        <div className={"ticketOrderByMoreBox " + (ShowOrderByBox ? "show" : "")}>
                            {SORT_ARR?.map((item , index) => 
                                <div className={"ticketOrderByMoreCard " + (SelectedSort?.value === item?.value ? " active" : "")} onClick={() => setSelectedSort(item)}>{item?.label}</div>
                            )}
                            <hr />
                            {STATUS_ARR?.map((item , index) => 
                                <div className={"ticketOrderByMoreCard " + (SelectedStatus?.value === item?.value ? " active" : "")} onClick={() => setSelectedStatus(item)}>{item?.label}</div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="mt-3 ticketListMainBox">
                    <TableComponent
                        tableHead={TableHead}
                        perPage={12}
                        totalItems={AllTickets.data?.total}
                        page={1}
                        setpage={setCurrentPage}
                    >
                        {AllTickets.data?.data?.map((ticket, index) =>
                            <tr
                                style={{
                                    cursor: "pointer"
                                }}
                                onClick={() => navigate(routes.ticket.show(ticket?.id))}
                            >
                                <td className="ticketNumTd">
                                    <div className="ticketNumberBox">{ticket?.id}</div>
                                </td>
                                <td>
                                    <div className="ticketTitleInTable">
                                        {ticket?.title?.title}
                                    </div>
                                </td>
                                <td>
                                    <ReportStatus reportStatus={ticket?.status_name} />
                                </td>
                                <td className="text-center text-gray">
                                    {jMoment(new Date(ticket?.created_at)).format("HH:ss - jD jMMMM jYYYY")}
                                </td>
                            </tr>
                        )}
                    </TableComponent>

                    {AllTickets.loading ?

                        <>
                            <div className="d-flex align-items-center justify-content-center">
                                <LoadingData />
                            </div>
                        </>

                        :
                        <>

                            {AllTickets.data?.data?.length === 0 &&

                                <>
                                    <NoDataComponent
                                        text={`تیکتی یافت نشد!`}
                                        className={"siteTempTitle text-gray4"}
                                        withImage
                                    />
                                </>

                            }
                        </>
                    }

                </div>

            </div>
        </>
    );
}

export default TicketsList;