import { useEffect, useRef, useState } from "react";
import jMoment from "moment-jalaali";
import { getAllUserNotifications } from "../../service/auth";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useSelector } from "react-redux";
import { LoadingData } from "../loading";

const NotificationPopupComponent = ({
    showPopup = false,
    setShowPopup = () => null,
}) => {
    const popupRef = useRef(null);
    const location = useLocation();

    const userData = useSelector(store => store.user);

    const [NotificationData, setNotificationData] = useState({
        loading: true,
        data: {}
    })


    const handleGetAllNotification = (withLoading = true) => {
        getAllUserNotifications().then(({ data }) => {
            setNotificationData({
                loading: false,
                data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowPopup]);

    useEffect(() => {
        handleGetAllNotification();
    }, [location.pathname])

    return (
        <>
            <div className="position-relative notificationMainBoxInHeader" ref={popupRef}>
                <div className="notificationMainBoxInHeaderIcon" onClick={() => setShowPopup(!showPopup)}>
                    {userData?.unread > 0 &&
                        <>
                            <div className="notificationMainBoxInHeaderIconUnreadBox"></div>
                        </>
                    }
                    <img src="/assets/images/notification.svg" alt="" />
                </div>
                <div className={`headerModalItemsMainBox notificationMainBoxInHeaderBody${showPopup ? " show" : ""}`}>
                    <div className="notificationMainBoxInHeaderBodyBox">
                        <div className="title">اعلان ها</div>
                        {userData?.unread > 0 &&
                            <>
                                <div className="siteTempBtn">خواندن همه</div>
                            </>
                        }
                    </div>

                    {NotificationData?.loading ?
                        <>
                            <div className="d-flex justify-content-center align-items-center my-5">
                                <LoadingData />
                            </div>
                        </>
                    :
                        <>

                            {NotificationData?.data?.data?.map((item, index) =>

                                <>
                                    <div className={`notificationMainBoxInHeaderCard${item?.seen ? " seen" : ""}`}>
                                        <div className="notificationMainBoxInHeaderCardHeader">
                                            <div className="notificationMainBoxInHeaderCardTitle">اعلان جدید</div>
                                            <div className="notificationMainBoxInHeaderCardDate">
                                                {item?.created_at && jMoment.duration(jMoment(new Date()).diff(jMoment(new Date(item?.created_at)))).asHours()}
                                            </div>
                                        </div>
                                        <div className="notificationMainBoxInHeaderCardBody">
                                            <div className="text-truncate notificationMainBoxInHeaderCardText">
                                                {item?.description}
                                            </div>
                                            {!item?.seen &&
                                                <>
                                                    <div className="notificationMainBoxInHeaderCardUnRead"></div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>

                            )}

                            {NotificationData?.data?.meta?.total === 0 &&
                                <>
                                    <div className="d-flex justify-content-center align-items-center flex-column notificationMainBoxInHeaderBodyEmptyBox">
                                        <img src="/assets/images/notification-bing.svg" alt="" />
                                        <div className="mt-2">
                                            اعلان جدیدی ندارید
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }


                    <div className="headerModalItemsFooterBox">
                        <Link to={routes.ticket.list} className="siteTempBtn">
                            مشاهده همه تیکت ها
                        </Link>
                    </div>

                </div>
            </div>
        </>
    );
}

export default NotificationPopupComponent;