import Select from 'react-select';

import SiteAlert from "../../components/alert";
import { customSelectStyle1 } from '../../components/reactSelectComponens';
import SiteFileUploader from '../../components/formComponents/fileUploader';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import LoadingButton from '../../components/loadingButton';
import { createTicket, getTicketType } from '../../service/ticket';
import { setCurrentPageTitle_action } from '../../store/action/app_action';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';

const AddTicketPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [AddTicketData, setAddTicketData] = useState({
        content: "",
        ticketType: { label: "عنوان درخواست را انتخاب بفرمایید", value: -1 },
        ticketUnit: { label: "موضوع درخواست را انتخاب بفرمایید", value: -1 },
    })

    const [AttachFile, setAttachFile] = useState(null);

    const [TicketTypesOptions, setTicketTypesOptions] = useState({
        fullData: [],
        ticketType: [{ label: "عنوان درخواست را انتخاب بفرمایید", value: -1 }],
        ticketUnit: [{ label: "موضوع درخواست را انتخاب بفرمایید", value: -1 }]
    })

    const [AddTicketLoading, setAddTicketLoading] = useState(false)

    const handleGetTicketTypes = () => {
        getTicketType().then(({ data }) => {

            let helpTicketType = [];
            let helpTicketUnit = [];

            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                helpTicketUnit.push({
                    label: element?.title,
                    value: element?.id,
                })

                for (let j = 0; j < element?.titles?.length; j++) {
                    const elementTitles = element.titles[j];
                    helpTicketType.push({
                        label: elementTitles?.title,
                        value: elementTitles?.id,
                    })
                }
            }

            setTicketTypesOptions({
                fullData: data,
                // ticketType: helpTicketType,
                ticketType: [],
                ticketUnit: helpTicketUnit,
            })


        }).catch((err) => {
            console.log(err)
        })
    }

    const handleAddTicket = () => {
        if (AddTicketData.content.trim() === "") {
            toast.error(
                "متن درخواست نمیتواند خالی باشد!"
            )
            return;
        }
        if (AddTicketData.ticketType.value === -1 || AddTicketData.ticketUnit.value === -1) {
            toast.error(
                "لطفا همه موارد را وارد کنید!"
            )
            return;
        }


        const sendData = new FormData();

        sendData.append("ticket_type_id", AddTicketData.ticketUnit.value)
        sendData.append("ticket_title_id", AddTicketData.ticketType.value)
        sendData.append("message", AddTicketData.content)

        if (AttachFile) {
            sendData.append("file", AttachFile)
        }

        setAddTicketLoading(true);
        createTicket(sendData).then(({ data }) => {
            toast.success("تیکت شما با موفقیت ثبت شد !");
            navigate(routes.ticket.list)
            setAddTicketLoading(false);

        }).catch((err) => {
            setAddTicketLoading(false);
            console.log(err)
        })
    }

    const handleSyncTicketSubject = () => {
        let helpTicketType = [];


        let selectedUnit = TicketTypesOptions.fullData?.filter(item => item?.id === AddTicketData?.ticketUnit?.value)[0];


        
        for (let j = 0; j < selectedUnit?.titles?.length; j++) {
            const elementTitles = selectedUnit.titles[j];
            helpTicketType.push({
                label: elementTitles?.title,
                value: elementTitles?.id,
            })
        }

        setAddTicketData({ ...AddTicketData, ticketType: { label: "عنوان درخواست را انتخاب بفرمایید", value: -1 } })
        
        setTicketTypesOptions({
            ...TicketTypesOptions,
            ticketType: helpTicketType,
        })

    }

    useEffect(() => {
        handleGetTicketTypes();
        dispatch(setCurrentPageTitle_action("تیکت جدید"))
    }, [])

    useEffect(() => {
        handleSyncTicketSubject();
    }, [AddTicketData?.ticketUnit])


    return (
        <>
            <Helmet>
                <title>
                    تیکت جدید
                </title>
            </Helmet>
            <div className="customBox">
                <SiteAlert status={"info"}>
                    کاربر گرامی شما میتوانید از طریق شماره تلفن‌های    91071040   و    91094554545   نیز با ما در تماس باشید .
                </SiteAlert>

                <div className="d-flex align-items-center gap-3 mt-4 flex-column flex-sm-row">
                    <div className="w-33">
                        <div className="siteTempLabelTitle">
                            موضوع درخواست
                        </div>
                        <Select
                            defaultValue={{ label: "موضوع درخواست را انتخاب بفرمایید", value: -1 }}
                            options={TicketTypesOptions.ticketUnit}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            // components={{ Control }}
                            styles={customSelectStyle1}
                            onChange={(newValue) => setAddTicketData({ ...AddTicketData, ticketUnit: newValue })}
                        />
                    </div>
                    <div className="w-66">
                        <div className="siteTempLabelTitle">
                            عنوان درخواست
                        </div>
                        <Select
                            defaultValue={{ label: "عنوان درخواست را انتخاب بفرمایید", value: -1 }}
                            options={TicketTypesOptions.ticketType}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            // components={{ Control }}
                            value={AddTicketData?.ticketType}
                            styles={customSelectStyle1}
                            onChange={(newValue) => setAddTicketData({ ...AddTicketData, ticketType: newValue })}
                        />
                    </div>
                </div>

                <div className='mt-4'>
                    <div className="siteTempLabelTitle">
                        متن درخواست
                    </div>

                    <textarea
                        className='siteTempTextarea'
                        rows="10"
                        placeholder='متن درخواست خود را وارد نمایید'
                        onChange={(e) => setAddTicketData({ ...AddTicketData, content: e.target.value })}
                    />
                </div>

                <div className="d-flex align-items-end justify-content-between mt-4 flex-column flex-sm-column gap-2 gap-sm-0">
                    <SiteFileUploader title={"فایل ضمیمه"} setFile={e => setAttachFile(e.target.files[0])} file={AttachFile} />
                    <div className="d-flex align-items-center gap-2">
                        <div className="siteTempBtn half-radius px-4 design3" onClick={() => navigate(routes.ticket.list)}>
                            <div>بازگشت</div>
                        </div>
                        <LoadingButton
                            loading={AddTicketLoading}
                            onClick={handleAddTicket}
                        >
                            <div>ارسال درخواست</div>
                            <img src="/assets/images/arrow-left-square.svg" alt="" style={{
                                width: 18
                            }} />
                        </LoadingButton>
                    </div>
                </div>

            </div>
        </>
    );
}

export default AddTicketPage;