import $ from 'jquery';


const SiteAccordion = ({title , design = 1 ,  children}) => {

    const handleOpenAccordion = (e) => {

        if($(e.target).closest(".siteTempAccordionBox").children(".siteTempAccordionBody").length > 0){
            
            if($(e.target).closest(".siteTempAccordionBox").hasClass("active")){
                $(e.target).closest(".siteTempAccordionBox").removeClass("active");
                $(e.target).closest(".siteTempAccordionBox").children(".siteTempAccordionBody").slideUp(400);
            }else{
                $(".siteTempAccordionBody").slideUp(400);
        
                $(".siteTempAccordionBox").removeClass("active");
    
    
                $(e.target).closest(".siteTempAccordionBox").addClass("active");
                $(e.target).closest(".siteTempAccordionBox").children(".siteTempAccordionBody").slideToggle(400);
            }

        }
        


    }

    return ( 
        <>
            <div
                className={"siteTempAccordionBox" + (design === 2 ? " simpleStyle" : "")}
            >
                <div className="siteTempAccordionHeader" onClick={handleOpenAccordion}>
                    <div style={{flexGrow : 1}}>{title}</div>
                    <div className="siteTempAccordionHeaderIcon">
                        <img src="/assets/images/chevron-down.svg" alt="" />
                    </div>
                </div>
                <div className="siteTempAccordionBody">{children}</div>
            </div>
        </>
    );
}
 
export default SiteAccordion;