import { applyMiddleware, compose, createStore } from "redux";
import { rootReducer } from "./reducer";
import {thunk} from 'redux-thunk'
import { detectedSiteTheme } from "./action/theme_action";
import { getCategoriesNewsAction, getUserCartAction, gettagNewsAction } from "./action/cart_action";
import { showLoading } from "./action/loading_action";
import { isAuthenticated } from "../utilities/auth";
import { getUserDataAction } from "./action/user_action";
import { getCampaignStatusAction } from "./action/status_action";
import { getUserBacklinkCartAction } from "./action/backlink_cart_action";
import { getUserForeignReportageCartAction } from "./action/foreign_reportage_cart_action";
import { getSiteSettingsAction } from "./action/app_action";

export const store = createStore(rootReducer, compose(applyMiddleware(thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ &&
  // window.__REDUX_DEVTOOLS_EXTENSION__()
))

// Start Site

const dispatchSeries = async () => {
  try {
    // Dispatch actions in the desired order, without waiting for each to complete
    const promises = [
      store.dispatch(showLoading()),
      store.dispatch(getSiteSettingsAction()),
    ];

    if(isAuthenticated()){
      promises.push(store.dispatch(getUserCartAction()))
      promises.push(store.dispatch(getUserDataAction()))
      promises.push(store.dispatch(getUserBacklinkCartAction()))
      promises.push(store.dispatch(getUserForeignReportageCartAction()))
      // promises.push(store.dispatch(getCampaignStatusAction()))
    }

    await Promise.all(promises);


  } catch (error) {

    console.error('An error occurred in dispatchSeries:', error);
  }
};

dispatchSeries();

// subscribe
store.subscribe(() => console.log(store.getState()))