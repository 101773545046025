import { Grid, Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import ReportStatus from "../../components/reportStatus";
import ReportageCard from "../../components/reportage";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setCurrentPageTitle_action } from "../../store/action/app_action";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import { getAllTicket } from "../../service/ticket";
import { getAllCampaign } from "../../service/campaign";
import { getAllReportage, getReportageData, getUserReportage } from "../../service/reportage";
import jMoment from "moment-jalaali";
import { LoadingData } from "../../components/loading";
import NoDataComponent from "../../components/noData";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { separateNumberWithComma } from "../../utilities";
import ReportageListTable from "../../components/reportage/reportageListTable";
import { getDashboardInfo, getDashboardLiveDiscount } from "../../service/dashboard";
import CampaignListTable from "../../components/campaignListTable";
import ReportageDetailModal from "../../components/reportage/detailModal";
import { addToCartAction } from "../../store/action/cart_action";
import { addToCart } from "../../service/cart";
import SiteModal from "../../components/siteModal";
import BacklinkListTable from "../../components/backlinkListTable";
import { getUserBacklink } from "../../service/backlink";
import PayModalComponent from "../../components/payModal";

const DashboardPage = () => {

    const dispatch = useDispatch();

    const [PublishReportTabIndex, setPublishReportTabIndex] = useState(0);
    const [CampaignReportTabIndex, setCampaignReportTabIndex] = useState(0);
    const [DiscountTabIndex, setDiscountTabIndex] = useState(0);

    const [DashboardData, SetDashboardData] = useState({
        loading: true,
        data: {}
    });

    const [MyBacklinkData, setMyBacklinkData] = useState({
        loading: true,
        data: {}
    });

    const [Tickets, setTickets] = useState({
        loading: true,
        data: {}
    })

    const [CanpaignData, setCanpaignData] = useState({
        loading: true,
        data: {}
    })

    const [BacklinkCampaignData, setBacklinkCampaignData] = useState({
        loading: true,
        data: {}
    })

    const [DashboardInfo, setDashboardInfo] = useState({
        loading: true,
        data: {}
    })

    const [LiveDiscount, setLiveDiscount] = useState({
        loading: true,
        data: {},
        noData: false,
    })
    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        selectedId: -1,
    });
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })
    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);


    const handleGetDashboardInfo = () => {
        getDashboardInfo().then(({ data }) => {
            setDashboardInfo({

                loading: false,
                data

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetLiveDiscount = () => {
        getDashboardLiveDiscount().then(({ data }) => {

            let totalItem = 0;

            Object.keys(data).map(function (key) {
                var item = data[key];
                totalItem += item?.length
            });

            setLiveDiscount({

                loading: false,
                data,
                noData: totalItem === 0,
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetOneReportage = (reportageId) => {

        console.log(ShowReportageModal.show)

        if (reportageId === -1 || ReportageSingleData.loading || !ShowReportageModal.show) return

        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getReportageData(reportageId).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleGetTickets = () => {
        getAllTicket({
            page: 1,
            perPage: 4
        }).then(({ data }) => {
            setTickets({

                loading: false,
                data

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetCampaign = () => {
        getAllCampaign({
            perPage: 6,
            page: 1,
            type: "reportage"
        }).then(({ data }) => {
            setCanpaignData({

                loading: false,
                data

            })

        }).catch((err) => {
            console.log(err)
        })
        getAllCampaign({
            perPage: 6,
            page: 1,
            type: "backlink"
        }).then(({ data }) => {
            setBacklinkCampaignData({

                loading: false,
                data

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetReportage = () => {
        getUserReportage(1, 6).then(({ data }) => {
            SetDashboardData({
                loading: false,
                data
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetBcklink = () => {
        getUserBacklink(1, 6).then(({ data }) => {
            setMyBacklinkData({
                loading: false,
                data
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleFetchData = async () => {
        try {
            const promises = [
                handleGetDashboardInfo(),
                handleGetLiveDiscount(),
                handleGetTickets(),
                handleGetCampaign(),
                handleGetReportage(),
                handleGetBcklink(),
            ];

            await Promise.all(promises);


        } catch (error) {

            console.error('An error occurred in Dashboard:', error);
        }
    }

    const handleAddItemToCart = () => {
        setLoadingAddToCart(true);

        let sendData = {
            reportage_id: ReportageSingleData.data?.id,
            type: "reportage",
        }

        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            dispatch(addToCartAction(data));
            setShowReportageModal({ ...ShowReportageModal, show: false })

        }).catch((err) => {
            setLoadingAddToCart(false);
            console.log(err)
        })

    }

    useEffect(() => {
        handleGetOneReportage(ShowReportageModal.selectedId);
    }, [ShowReportageModal.show])

    useEffect(() => {
        handleFetchData();
        setTimeout(() => {
            dispatch(setCurrentPageTitle_action("داشبورد"));
        }, 100)
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    داشبورد
                </title>
            </Helmet>

            <SiteModal
                modalDesign={2}
                title={"رپورتاژ خبر"}
                showModal={ShowReportageModal.show}
                closeModal={() => setShowReportageModal({ ...ShowReportageModal, show: false })}
            >

                {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>
                        <ReportageDetailModal
                            reportageData={ReportageSingleData.data}
                            LoadingAddToCart={LoadingAddToCart}
                            handleAddItemToCart={handleAddItemToCart}
                        />
                    </>
                }


            </SiteModal>

            <PayModalComponent />

            <div className="row gap-2 gap-sm-0 mt-4">

                <div className="col-md-4">
                    <div className="customBox dashboardquickDataBox">
                        <div className="dashboardquickDataBoxBody">
                            <div className="dashboardquickDataBoxCard">
                                <div>در انتظار محتوا</div>
                                <div>{DashboardInfo.data?.reportage?.content_waiting || 0}</div>
                            </div>
                            <div className="dashboardquickDataBoxCard">
                                <div>در انتظار انتشار</div>
                                <div>{DashboardInfo.data?.reportage?.publish_waiting || 0}</div>
                            </div>
                            <div className="dashboardquickDataBoxCard">
                                <div>منتشر شده</div>
                                <div>{DashboardInfo.data?.reportage?.published || 0}</div>
                            </div>
                        </div>
                        <img src="/assets/images/document.png" alt="" />

                    </div>
                </div>

                <div className="col-md-4">
                    <div className="customBox dashboardquickDataBox">

                        <div className="dashboardquickDataBoxBody">
                            <div className="dashboardquickDataBoxCard">
                                <div>در حال انقضا</div>
                                <div>{DashboardInfo.data?.backlink?.expire || 0}</div>
                            </div>
                            <div className="dashboardquickDataBoxCard">
                                <div>فعال</div>
                                <div>{DashboardInfo.data?.backlink?.active || 0}</div>
                            </div>
                            <div className="dashboardquickDataBoxCard">
                                <div>خریداری شده</div>
                                <div>{DashboardInfo.data?.backlink?.bought || 0}</div>
                            </div>
                        </div>
                        <img src="/assets/images/discovery.png" alt="" />

                    </div>
                </div>

                <div className="col-md-4">
                    <div className="customBox h-100 dashboardquickDataBox">

                        <div className="dashboardquickDataBoxBody">
                            <div className="dashboardquickDataBoxTitle">
                                به جهت سفارش تولید محتوا<br />
                                با ما در ارتباط باشید
                            </div>
                            <Link to={routes.createContent.root} className="d-flex">
                                <div className="siteTempBtn radius-10">
                                    سفارش تولید محتوا
                                </div>
                            </Link>
                        </div>
                        <img src="/assets/images/document-edit-1.png" alt="" />

                    </div>
                </div>

            </div>

            <div className="row mt-3">
                <div className={
                    (LiveDiscount.noData ? "d-none" : "col-md-5")
                }>
                    <div className="customBox dashboardLiveDiscountMainBox">
                        <Tabs onSelect={(index, last, event) => setDiscountTabIndex(index)}>
                            <div className="customFlexBox justify-content-between dashboardLiveDiscountHeader">
                                <div className="dashboardLiveDiscountTitle">تخفیف لحظه ای</div>
                                <div className="dashboardLiveDiscountTime">
                                    <div>22 : 15 : 10</div>
                                    <img src="/assets/images/timer.svg" alt="" />
                                </div>
                            </div>

                            {LiveDiscount?.loading &&
                                <>
                                    <div className="d-flex align-items-center justify-content-center mt-4">
                                        <LoadingData />
                                    </div>
                                </>
                            }

                            <div className="d-flex align-items-center gap-2 mt-4 justify-content-center dashboardLiveDiscountCategory">
                                <TabList className={"siteTempTabList d-flex align-items-center gap-2"}>
                                    {/* {Object.keys(LiveDiscount.data)?.map((key, index) => 
                                        <>

                                        </>
                                    )} */}
                                    {LiveDiscount?.data?.reportage?.length > 0 &&
                                        <Tab value={"reportage"}>
                                            <div className={"siteTempBtn designSelectBtn" + (DiscountTabIndex === 0 ? " active" : "")}>رپورتاژ آگهی ها</div>
                                        </Tab>
                                    }
                                    {LiveDiscount?.data?.backlink?.length > 0 &&
                                        <Tab value={"backlink"}>
                                            <div className={"siteTempBtn designSelectBtn" + (DiscountTabIndex === 1 ? " active" : "")}>بک لینک ها</div>
                                        </Tab>
                                    }
                                </TabList>
                            </div>

                            <div className="mt-4 px-3">
                                <Swiper
                                    navigation={{
                                        nextEl: ".newsTopSliderNav0.next",
                                        prevEl: ".newsTopSliderNav0.prev",
                                        disabledClass: "disabled"
                                    }}
                                    pagination={{
                                        clickable: true,
                                        el: ".newsTopSliderPagination"
                                    }}
                                    modules={[Navigation, Pagination, Autoplay]}
                                    loop={true}
                                    autoplay={{
                                        delay: 7000,
                                    }}
                                    className=""
                                    spaceBetween={50}
                                    slidesPerView={1}
                                >

                                    {LiveDiscount?.data?.[DiscountTabIndex === 0 ? "reportage" : "backlink"]?.map((item, index) =>

                                        <SwiperSlide>
                                            <ReportageCard
                                                reportage={item}
                                                setShowDetailModal={setShowReportageModal}
                                                backLink={DiscountTabIndex === 1 ? true : false}
                                                isForeign={item?.info?.is_foreign === 1 ? true : false}
                                            />
                                        </SwiperSlide>

                                    )}

                                </Swiper>
                            </div>
                        </Tabs>



                    </div>
                </div>
                <div
                    className={
                        (LiveDiscount.noData ? "col-md-12" : "col-md-7")
                    }

                >
                    <div className="customBox h-100">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="siteTempTitle">تیکت ها</div>
                            <Link to={routes.ticket.list}>
                                <div className="siteTempMoreTitle">
                                    <div>همه تیکت ها</div>
                                    <img src="/assets/images/chevron-down.svg" alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="siteTempTableBox">
                            <table className="siteTempTable">
                                <tr>
                                    <th></th>
                                    <th>عنوان تیکت</th>
                                    <th className="text-center">تاریخ</th>
                                    <th></th>
                                </tr>
                                {Tickets?.data?.data?.map(item =>
                                    <>
                                        <tr>
                                            <td><div className="tempDot active"></div></td>
                                            <td>
                                                {item?.title?.title}
                                            </td>
                                            <td className="text-center">
                                                {jMoment(item?.created_at).format("jD jMMMM jYYYY")}
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-end">
                                                    <Link to={routes.ticket.show(item?.id)} >
                                                        <div className="siteTempBtn design1">مشاهده</div>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )}

                            </table>
                            {Tickets?.data?.data?.length === 0 &&
                                <>
                                    <NoDataComponent text={"تیکتی برای نمایش وجود ندارد"} />
                                </>
                            }
                            {Tickets.loading &&
                                <>
                                    <div className="d-flex align-items-center justify-content-center my-3">
                                        <LoadingData />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <Tabs onSelect={(index) => setPublishReportTabIndex(index)}>
                        <div className="customBox">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center gap-2">
                                    <div className="siteTempTitle">گزارش انتشارات</div>
                                    <div className="align-items-center gap-2 d-none d-sm-flex">
                                        <TabList className={"siteTempTabList d-flex align-items-center gap-2"}>
                                            <Tab>
                                                <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 0 ? " active" : "")}>رپورتاژ آگهی ها</div>
                                            </Tab>
                                            <Tab>
                                                <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 1 ? " active" : "")}>بک لینک ها</div>
                                            </Tab>
                                            <Tab>
                                                <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 2 ? " active" : "")}>تولید محتوا</div>
                                            </Tab>
                                        </TabList>
                                    </div>
                                </div>
                                <Link
                                    to={
                                        PublishReportTabIndex === 0 ? routes.reportage.myReportage :
                                            PublishReportTabIndex === 1 ? routes.backLink.myBackLink :
                                                PublishReportTabIndex === 2 ? routes.createContent.root : "#"
                                    }
                                >
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="text-primaryColor"
                                            style={{
                                                fontSize: 13,
                                                fontWeight: 700
                                            }}
                                        >
                                            {PublishReportTabIndex === 0 ?

                                                <>
                                                    مشاهده همه رپورتاژ ها
                                                </>

                                                : PublishReportTabIndex === 1 ?
                                                    <>
                                                        مشاهده همه بک لینک ها
                                                    </>
                                                    :
                                                    <>
                                                        مشاهده صفحه تولید محتوا
                                                    </>
                                            }
                                        </div>
                                        <img src="/assets/images/arrow-left-circle.svg" alt="" />
                                    </div>
                                </Link>
                            </div>

                            <div className="align-items-center gap-2 d-flex d-sm-none mb-2">
                                <TabList className={"siteTempTabList d-flex align-items-center gap-2"}>
                                    <Tab>
                                        <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 0 ? " active" : "")}>رپورتاژ آگهی ها</div>
                                    </Tab>
                                    <Tab>
                                        <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 1 ? " active" : "")}>بک لینک ها</div>
                                    </Tab>
                                    <Tab>
                                        <div className={"siteTempBtn designSelectBtn" + (PublishReportTabIndex === 2 ? " active" : "")}>تولید محتوا</div>
                                    </Tab>
                                </TabList>
                            </div>

                            <TabPanel>

                                <ReportageListTable
                                    loading={DashboardData.loading}
                                    tableData={DashboardData.data}
                                    showFooter={false}
                                />
                            </TabPanel>

                            <TabPanel>
                                <BacklinkListTable
                                    loading={MyBacklinkData.loading}
                                    tableData={MyBacklinkData.data}
                                    showFooter={false}
                                />
                            </TabPanel>

                            <TabPanel>
                                <div className="siteTempTableBox">
                                    <table className="siteTempTable">
                                        <tr>
                                            <th>کد رپورتاژ</th>
                                            <th>عنوان رپورتاژ</th>
                                            <th className="text-center">رسانه</th>
                                            <th className="text-center">تاریخ پیشنهادی انتشار</th>
                                            <th className="text-center">وضعیت</th>
                                            <th className="text-center">اقدامات</th>
                                        </tr>

                                    </table>
                                    <NoDataComponent />
                                </div>
                            </TabPanel>

                        </div>
                    </Tabs>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <Tabs onSelect={(index) => setCampaignReportTabIndex(index)}>
                        <div className="customBox">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center gap-2">
                                    <div className="siteTempTitle">گزارش کمپین های شما</div>
                                    <div className="d-none d-sm-flex align-items-center gap-2">
                                        <TabList className={"siteTempTabList d-flex align-items-center gap-2"}>
                                            <Tab>
                                                <div className={"siteTempBtn designSelectBtn" + (CampaignReportTabIndex === 0 ? " active" : "")}>رپورتاژ آگهی ها</div>
                                            </Tab>
                                            <Tab>
                                                <div className={"siteTempBtn designSelectBtn" + (CampaignReportTabIndex === 1 ? " active" : "")}>بک لینک ها</div>
                                            </Tab>
                                        </TabList>
                                    </div>
                                </div>
                                <Link
                                    to={
                                        CampaignReportTabIndex === 0 ? routes.reportage.campaigns :
                                            CampaignReportTabIndex === 1 ? routes.backLink.campaigns : "#"
                                    }
                                >
                                    <div className="d-flex align-items-center gap-1">
                                        <div className="text-primaryColor"
                                            style={{
                                                fontSize: 13,
                                                fontWeight: 700
                                            }}
                                        >
                                            مشاهده همه کمپین ها
                                        </div>
                                        <img src="/assets/images/arrow-left-circle.svg" alt="" />
                                    </div>
                                </Link>
                            </div>

                            <div className="align-items-center gap-2 d-flex d-sm-none mb-2">
                                <TabList className={"siteTempTabList d-flex align-items-center gap-2"}>
                                    <Tab>
                                        <div className={"siteTempBtn designSelectBtn" + (CampaignReportTabIndex === 0 ? " active" : "")}>رپورتاژ آگهی ها</div>
                                    </Tab>
                                    <Tab>
                                        <div className={"siteTempBtn designSelectBtn" + (CampaignReportTabIndex === 1 ? " active" : "")}>بک لینک ها</div>
                                    </Tab>
                                </TabList>
                            </div>

                            <TabPanel>
                                <CampaignListTable
                                    tableData={CanpaignData.data}
                                    loading={CanpaignData.loading}
                                    showFooter={false}
                                />
                                {/* <div className="siteTempTableBox"> */}
                                {/* <table className="siteTempTable">
                                        <tr>
                                            <th>کد کمپین</th>
                                            <th>عنوان کمپین</th>
                                            <th className="text-center">وبسایت ها</th>
                                            <th className="text-center">باقی مانده</th>
                                            <th className="text-center">مبلغ</th>
                                            <th className="text-center">وضعیت</th>
                                            <th className="text-center">تاریخ ایجاد</th>
                                            <th className="text-center">اقدامات</th>
                                        </tr>
                                        {CanpaignData.data?.data?.map((campaign, index) =>

                                            <>
                                                <tr>
                                                    <td>
                                                        <div className="reportageCodeBox">
                                                            <span>{campaign?.id}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <div>{campaign?.name || "بدون عنوان"}</div>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="campaignSitesMainBox">
                                                            {Array.isArray(campaign?.websites) &&
                                                                <>
                                                                    {campaign?.websites?.map((site, index) =>
                                                                        <>
                                                                            {site}{index + 1 !== campaign?.websites?.length && " , "}
                                                                        </>
                                                                    )}
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        {campaign?.left_over}
                                                    </td>
                                                    <td className="text-center">
                                                        <span>
                                                            {(() => {
                                                                let sum = 0;
                                                                for (let i = 0; i < campaign?.reportage.length; i++) {
                                                                    const element = campaign?.reportage[i]?.reportage;
                                                                    sum += element?.final_price
                                                                }
                                                                return separateNumberWithComma(sum)
                                                            })()}
                                                        </span>
                                                        <span> تومان</span>
                                                    </td>
                                                    <td className="text-center">
                                                        <ReportStatus reportStatus={campaign?.status_name} withBox={true} />
                                                    </td>
                                                    <td className="text-center">
                                                        {jMoment(campaign?.created_at).format("jD jMMMM jYYYY")}
                                                    </td>
                                                    <td style={{
                                                        width: 100,
                                                        paddingLeft: 20
                                                    }}>
                                                        <div className={
                                                            "customFlexBox align-items-stretch gap-2" +
                                                            (["لغو شده"].indexOf(campaign?.status_name) > -1 ? " justify-content-end" : " justify-content-center")
                                                        }>
                                                            {(campaign?.status_name === "در انتظار ایجاد") &&
                                                                <>
                                                                    <Link to={routes.reportage.create(campaign?.id)}>
                                                                        <div className="siteTempBtn tableBtn">
                                                                            <img src="/assets/images/document-upload.svg" alt="" />
                                                                            <div>آپلود محتوا</div>
                                                                        </div>
                                                                    </Link>

                                                                </>
                                                            }
                                                            {["منتشر شده", "رد شده", "در حال بررسی"].indexOf(campaign?.status_name) > -1 &&

                                                                <>
                                                                    <div className="siteTempBtn tableBtn blueBg outline">
                                                                        <img src="/assets/images/eye.svg" alt="" />
                                                                        <div>رپورتاژ ها</div>
                                                                    </div>
                                                                </>

                                                            }
                                                            {["در انتظار پرداخت"].indexOf(campaign?.status_name) > -1 &&

                                                                <>
                                                                    <div className="siteTempBtn tableBtn greenBg">
                                                                        <img src="/assets/images/money-send.svg" alt="" />
                                                                        <div>پرداخت</div>
                                                                    </div>
                                                                </>

                                                            }
                                                            <div className="siteTempBtn design1 _3dotBtn width-fit">
                                                                <img src="/assets/images/3dot.svg" alt="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>

                                        )}
                                    </table> */}
                                {/* {CanpaignData.data?.data?.length === 0 &&
                                        <>
                                            <NoDataComponent text={"تیکتی یافت نشد!"} />
                                        </>
                                    }
                                    {CanpaignData.loading &&
                                        <>
                                            <div className="d-flex align-items-center justify-content-center my-3">
                                                <LoadingData />
                                            </div>
                                        </>
                                    } */}
                                {/* </div> */}
                            </TabPanel>

                            <TabPanel>
                                <CampaignListTable
                                    tableData={BacklinkCampaignData.data}
                                    loading={BacklinkCampaignData.loading}
                                    showFooter={false}
                                />
                            </TabPanel>

                        </div>
                    </Tabs>
                </div>
            </div>


        </>
    );
}

export default DashboardPage;