const NoDataComponent = ({
    text,
    withImage = false ,
    imageIcon ,
    className
}) => {
    return ( 
        <>
            <div className="text-center my-3 font-bold">

                {withImage &&
                    <>
                        <div className="noDataImageBox">
                            <img src="/assets/images/noDataBox.svg" alt="" />
                        </div>
                    </>
                }

                {text ?
                    <>
                        <div className={(withImage ? " mt-3" : "") + (className ? " "+className : "")}>
                            {text}
                        </div>
                    </>
                :
                    <>
                        دیتایی برای نمایش وجود ندارد
                    </>
                }
            </div>
        </>
    );
}
 
export default NoDataComponent;