const SwitchBoxComponent = (
    {
        title,
        checked = false,
        onChange = () => null,
        value,
        hasChecked = false,
    }
) => {
    return (
        <>
            <div className="switchTempBox">
                <label class="switchTemp">
                    <div>
                        {title}
                    </div>
                    {hasChecked ?

                        <input type="checkbox" onChange={onChange} checked={checked} hidden />
                        :
                        <input type="checkbox" onChange={onChange} hidden />

                    }
                    <span class="switchSliderTemp round"></span>
                </label>
            </div>
        </>
    );
}

export default SwitchBoxComponent;