
const initialState = "dark" ; //light , dark

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SITE_THEME': {
            
            return action.payload
            
        }
        
        default:
            return state
    }
}