import SitePagination from "../../../components/pagination";
import { perPageArr } from "../../../utilities/config";
import { customSelectStyle, indicatorContainerBg, perPageControl } from "../../../components/reactSelectComponens";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import $ from "jquery";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import ReportStatus from "../../../components/reportStatus";
import { separateNumberWithComma } from "../../../utilities";
import jMoment from "moment-jalaali";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { LoadingData } from "../../../components/loading";
import NoDataComponent from "../../../components/noData";
import { getAllReceiptReport } from "../../../service/financial";
import SiteModal from "../../../components/siteModal";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            <div style={{ fontSize: 11 }}>براساس</div>
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {/* <div className="itemSelectCount">126</div> */}
            {children}
        </div>
    </components.Control>
);

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
            <div className="itemSelectCountInSelectBox"></div>
        </div>
    </components.SingleValue>
);


const ReceiptSubmissionReportList = () => {

    const dispatch = useDispatch();

    const FAKE_DATA = [
        {
            id: 1,
            amount: 12000000,
            created_at: "2024-01-12T19:11:25",
            status_name: "تایید شده",
        },
        {
            id: 2,
            amount: 12000000,
            created_at: "2024-01-12T19:11:25",
            status_name: "شارژ حساب",
        },
        {
            id: 3,
            amount: 12000000,
            created_at: "2024-01-12T19:11:25",
            status_name: "در انتظار بررسی",
        },
        {
            id: 4,
            amount: 12000000,
            created_at: "2024-01-12T19:11:25",
            status_name: "رد شده",
        },
    ]

    const SHOW_REPORT_IMAGE = ["پرداخت کمپین1"];

    const ORDER_BY = [
        {
            label: "پیش فرض",
            value: "پیش فرض",
        },
        // {
        //     label: "در انتظار پرداخت",
        //     value: "در انتظار پرداخت",
        // },
        // {
        //     label: "در انتظار ایجاد ",
        //     value: "در انتظار ایجاد ",
        // },
        // {
        //     label: "درحال بررسی ",
        //     value: "درحال بررسی ",
        // },
        // {
        //     label: "منتشرشده",
        //     value: "منتشرشده",
        // },
        // {
        //     label: "رد شده",
        //     value: "رد شده",
        // },
    ]

    const FILTTER_BY = [
        {
            label: "همه",
            value: -1,
        },
        {
            label: "شارژ حساب",
            value: 1,
        },
    ]

    const [ReportData, setReportData] = useState({
        loading: true,
        data: {}
    })
    // const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: "all",
        filterBy: FILTTER_BY[0]
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(12)

    const [ShowReceiptModal, setShowReceiptModal] = useState({
        show: false,
        data: {}
    })

    const handleCloseModal = () => {
        setShowReceiptModal({
            show: false,
            data: {}
        })
    }

    const handleGetReportData = () => {
        setReportData({ ...ReportData, loading: true })
        getAllReceiptReport({
            page: CurrentPage,
            perPage: PerPage,
            wallet: ActiveFilters?.filterBy?.value != "-1" ? ActiveFilters?.filterBy?.value : undefined
        }).then(({ data }) => {

            let helpData = data;
            let helpArr = []

            // for (const key in helpData?.data) {
            //     if (Object.hasOwnProperty.call(helpData?.data, key)) {
            //         const element = helpData?.data[key];
            //         helpArr.push({
            //             ...element,

            //             reportType: element?.reportage ? "reportage" : element?.backlink ? "backlink" : "wallet"
            //         })
            //     }
            // }

            // helpData.data = helpData.data?.map((item) =>{
            //     return {
            //         ...item,
            //         reportType : item?.reportage ? "reportage" : item?.backlink ? "backlink" : "wallet"
            //     }
            // })


            // helpData.data = helpArr;

            $(".itemSelectCountInSelectBox").text(data?.total || 0)

            setReportData({

                loading: false,
                data: data

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetReportData();
    }, [CurrentPage, ActiveFilters])

    useEffect(() => {
        // handleGetCampaign();
        dispatch(setCurrentPageTitle_action("فیش های واریزی"));
    }, [])


    return (
        <>
            <Helmet>
                <title>
                    فیش های واریزی
                </title>
            </Helmet>

            <SiteModal
                modalDesign={1}
                // title={"رپورتاژ خبر"}
                showModal={ShowReceiptModal.show}
                modalClass="reasonModalContentBox"
                closeModal={handleCloseModal}
                withCloseLogo
            >

                <div className="reasonModalHeader">
                    <img src="/assets/images/warning-2.svg" alt="" />
                    <div className="reasonModalHeaderTitle">نمایش رسید</div>
                </div>

                <div className="showReceiptImageBox">
                    <img src={ShowReceiptModal.data?.receipt} alt="" />
                </div>


            </SiteModal>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2">

                    <Select
                        defaultValue={ORDER_BY[0]}
                        options={ORDER_BY}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control, SingleValue }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#292D32",
                                fontWeight: 700,
                            }),
                            dropdownIndicator: indicatorContainerBg,
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                    <Select
                        defaultValue={FILTTER_BY[0]}
                        options={FILTTER_BY}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control, SingleValue }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#292D32",
                                fontWeight: 700,
                            }),
                            dropdownIndicator: indicatorContainerBg,
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, filterBy: newValue })}
                    />

                </div>

                <div className="d-flex gap-2">
                    <Link to={routes.financial.receiptSubmissionAdd} className="d-flex">
                        <div className="siteTempBtn primary radius-10">
                            <div>
                                ثبت درخواست جدید
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="customBox fullHeightTableInPage">
                <div className="siteTempTableBox1">
                    <table className="siteTempTable">
                        <tr>
                            <th>ردیف</th>
                            <th>مبلغ شارژ (تومان)</th>
                            <th className="text-center">تاریخ </th>
                            <th className="text-center">وضعیت</th>
                            <th className="text-center">اقدامات</th>
                        </tr>
                        {!ReportData?.loading &&
                            <>
                                {/* {ReportageData.data?.data?.map((reportage, index) => */}
                                {ReportData?.data?.data?.map((report, index) =>

                                    <>
                                        <tr className="position-relative">
                                            <td>
                                                <div className="reportageCodeBox">
                                                    {report?.id}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center gap-2">
                                                    {/* <div className={"financeReportArrow" + (SHOW_REPORT_IMAGE.indexOf(report?.status_name) > -1 ? " decrease" : " increase")}>
                                                        <img src="/assets/images/arrow-top.svg" alt="" />
                                                    </div> */}
                                                    <div>
                                                        {separateNumberWithComma(parseInt(report?.paid_price || report?.amount || 0))}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {jMoment(new Date(report?.created_at)).format("jD jMMMM jYYYY")}
                                            </td>
                                            <td className="text-center">
                                                <ReportStatus reportStatus={(report?.status === 0 || report?.status === "rejected") ? "رد شده" : (report?.status === 1 || report?.status === "accepted") ? "تایید شده" : "در انتظار تایید"} withBox={true} />
                                            </td>
                                            <td>
                                                <div className="customFlexBox justify-content-end gap-2">

                                                    <div className="siteTempBtn tableBtn blueBg noHover outline" style={{
                                                        minWidth: "none"
                                                    }}
                                                        onClick={() => setShowReceiptModal({
                                                            show : true,
                                                            data : report
                                                        })}
                                                    >
                                                        <div>فیش واریزی</div>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    </>

                                )}
                            </>
                        }



                    </table>

                    {ReportData?.loading &&
                        <>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <LoadingData />
                            </div>
                        </>
                    }

                    {(ReportData?.data?.length === 0 && ReportData?.loading === false) &&
                        <>
                            <NoDataComponent />
                        </>
                    }

                    <div className="siteTempTableFooter mt-3">


                        <Select
                            defaultValue={perPageArr[0]}
                            options={perPageArr}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            components={{
                                Control: perPageControl
                            }}
                            styles={{
                                ...customSelectStyle,
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                    borderRadius: 8,
                                    fontSize: 13,
                                    color: "#7E8692",
                                    boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                    padding: "7px 12px",
                                })
                            }}
                        // onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                        />

                        <SitePagination
                            currentPage={CurrentPage}
                            setCurrentPage={setCurrentPage}
                            totalItems={ReportData?.data?.meta?.total || 0}
                            perPage={PerPage}
                            showNav={false}
                        />
                    </div>
                </div>

            </div>
        </>
    );
}

export default ReceiptSubmissionReportList;