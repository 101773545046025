import { customAxios } from "./axiosSetup";

export const getAllBacklink = ({
    searchText,
    order_by,//new -  cheap -  expensive - similar_web - domain_power
    is_foreign = 0,

    nofollow_link,
    is_share_to_social,
    is_link_to_other,
    is_video,
    is_ad_tag,
    main_page,

    discount,

    min_price,
    max_price,
    defaultMaxPrice,

    min_spam,
    max_spam,
    defaultMaxSpam,

    min_similar_web,
    max_similar_web,
    defaultSimilarWeb,

    min_domain_a,
    max_domain_a,
    defaultDomainA,

    categoryArr,// array of ids
    page = 1,
    perPage = 12,
}) => {

    let link = `/backlink?page=${page}&perPage=${perPage}`;

    if(searchText && searchText.trim() !== ""){
        link += `&search=${searchText}`
    }

    // console.log({order_by})

    if(order_by && order_by.trim() !== ""){
        link += `&order_by=${order_by}`
    }

    if(min_price && min_price !== ""){
        link += `&min_price=${min_price}`
    }

    if(max_price && max_price !== defaultMaxPrice){
        link += `&max_price=${max_price}`
    }

    if(min_spam && min_spam !== ""){
        link += `&min_spam=${min_spam}`
    }

    if(max_spam && max_spam !== defaultMaxSpam){
        link += `&max_spam=${max_spam}`
    }

    if(min_similar_web && min_similar_web !== ""){
        link += `&min_similar_web=${min_similar_web}`
    }

    if(max_similar_web && max_similar_web !== defaultSimilarWeb){
        link += `&max_similar_web=${max_similar_web}`
    }

    if(min_domain_a && min_domain_a !== ""){
        link += `&min_da=${min_domain_a}`
    }

    if(max_domain_a && max_domain_a !== defaultDomainA){
        link += `&max_da=${max_domain_a}`
    }

    if(nofollow_link && nofollow_link === true){
        link += `&nofollow_link=${nofollow_link ? 1 : 0}`
    }

    if(is_share_to_social && is_share_to_social === true){
        link += `&is_share_to_social=${is_share_to_social ? 1 : 0}`
    }

    if(is_link_to_other && is_link_to_other === true){
        link += `&is_link_to_other=${is_link_to_other ? 1 : 0}`
    }

    if(is_video && is_video === true){
        link += `&is_video=${is_video ? 1 : 0}`
    }

    if(is_ad_tag && is_ad_tag === true){
        link += `&is_ad_tag=${is_ad_tag ? 1 : 0}`
    }

    if(main_page && main_page === true){
        link += `&main_page=${main_page ? 1 : 0}`
    }

    if(discount && discount === true){
        link += `&discount=${discount}`
    }

    if(categoryArr && categoryArr.length > 0){
        // console.log(categoryArr);
        for (let i = 0; i < categoryArr.length; i++) {
            const element = categoryArr[i];
            link += `&category_ids[]=${element}`
        }
    }


    return customAxios.get(link)

}

export const getBacklinkData = (id) => customAxios.get(`/backlink/${id}`);

export const getBacklinkCategory = () => customAxios.get(`/news_agency/category`);

export const getUserBacklink = (page = 1 , perPage = 12) => customAxios.get(`/client/backlink?page=${page}&perPage=${perPage}`);

export const getUserBacklinkDetail = (id) => customAxios.get(`/client/backlink/${id}}`);

export const createUserBacklink = (data) => customAxios.post(`/client/backlink` , data , {
    headers:{ "Content-Type": "multipart/form-data" }
});

export const updateUserBacklink = (id , data) => customAxios.post(`/client/backlink/${id}` , data , {
    headers:{ "Content-Type": "multipart/form-data" }
});

export const uploadBacklinkFile = (data) => customAxios.post(`/backlink` , data , {
    headers:{ "Content-Type": "multipart/form-data" }
});