import { useEffect, useState } from "react";
import LoadingButton from "../loadingButton";
import { toast } from "react-toastify";
import { separateNumberWithComma } from "../../utilities";
import { addToCart, deleteFromCart } from "../../service/cart";
import { addToCartAction, addToCartBeforApiAction, getUserCartAction, removeFromCartAction, removeFromCartBeforeApiAction } from "../../store/action/cart_action";
import { useDispatch, useSelector } from "react-redux";
import { imageThumbUrl } from "../../utilities/config";
import { addToForeignReportageCartAction, addToForeignReportageCartBeforApiAction, removeFromForeignReportageCartAction, removeFromForeignReportageCartBeforeApiAction } from "../../store/action/foreign_reportage_cart_action";
import { addToBacklinkCartAction, addToBacklinkCartBeforApiAction, removeFromBacklinkCartAction, removeFromBacklinkCartBeforeApiAction } from "../../store/action/backlink_cart_action";

const ReportageCard = ({
    reportage,
    parentClassName,
    fullDetail = false,
    setShowDetailModal,
    backLink = false,
    isForeign = false,
}) => {

    const dispatch = useDispatch();

    const userCart = useSelector(store => {
        if (isForeign) {
            return store.foreign_reportage_cart
        } else if (backLink) {
            return store.backlink_cart
        } else {
            return store.cart
        }
    });

    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);
    const [BackLinkSelectedPeriod, setBackLinkSelectedPeriod] = useState(1)

    const handleAddItemToCart = () => {
        setLoadingAddToCart(true);

        let sendType = "reportage";

        let sendData = {
            reportage_id: reportage?.id,
            type: "reportage"
        }

        if (backLink) {
            sendType = "backlink";
            sendData.type = "backlink";
            delete sendData.reportage_id;
            sendData.backlink_id = reportage?.id;
            sendData.backlink_price_id = reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.id;
            dispatch(addToBacklinkCartBeforApiAction(
                {
                    backlink: reportage,
                    backlink_price: reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)
                }
            )
            );
        } else if (isForeign) {
            sendType = "foreign_reportage";
            sendData.type = "foreign_reportage";

            dispatch(addToForeignReportageCartBeforApiAction({ reportage }));
        } else {

            dispatch(addToCartBeforApiAction({ reportage }));
        }

        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            if (backLink) {
                dispatch(addToBacklinkCartAction(data));
            } else if (isForeign) {
                dispatch(addToForeignReportageCartAction(data));
            } else {
                dispatch(addToCartAction(data));
            }
            // toast.success("با موفقیت به سبد خرید اضافه شد!")

        }).catch((err) => {
            setLoadingAddToCart(false);
            if (backLink) {
                dispatch(removeFromBacklinkCartBeforeApiAction({ reportage }));
            } else if (isForeign) {
                dispatch(removeFromForeignReportageCartBeforeApiAction({ reportage }));
            } else {
                dispatch(removeFromCartBeforeApiAction({ reportage }));
            }
            console.log(err)
        })

    }

    const handleDeleteItemFromCart = (itemId, reportageId, cart) => {

        const sendData = {
            reportage_id: reportageId
        }

        let sendType = "reportage";


        if (backLink) {
            sendType = "backlink"
            delete sendData.reportage_id;
            sendData.backlink_id = reportageId;
            dispatch(removeFromBacklinkCartAction(cart));
        } else if (isForeign) {
            sendType = "foreign_reportage"
            dispatch(removeFromForeignReportageCartAction(cart));
        } else {
            dispatch(removeFromCartAction(cart));
        }

        deleteFromCart(sendType, itemId, sendData).then(({ data }) => {
            // setLoadingAddToCart(false);
            // handleCalculateCart();
            // toast.success("با موفقیت به سبد خرید اضافه شد!")

        }).catch((err) => {
            console.log(err)
            if (backLink) {
                dispatch(addToBacklinkCartAction(cart));
            } else if (isForeign) {
                dispatch(addToForeignReportageCartAction(cart));
            } else {
                dispatch(addToCartAction(cart));
            }

        })
    }

    const handleGenerateLink = (link) => {

        // reportage?.news?.site || new URL(reportage?.news?.site || "").hostname?.split("www.")[1] || new URL(reportage?.news?.site).hostname || ""

        if (link) {
            return new URL(link || "").hostname?.split("www.")[1] || new URL(link).hostname
        }

        return ""

    }

    useEffect(() => {
        if (reportage?.price?.[0]?.id) {
            setBackLinkSelectedPeriod(
                reportage?.price?.find(item => item?.is_default === 1)?.month || 1
            )
        }
    }, [reportage])

    return (
        <>
            <div className={"reportageCardBox" + (fullDetail ? " fullDetail" : "") + (parentClassName ? " " + parentClassName : "")}>

                {/* {(parseFloat(reportage?.discount || 0) > 0) && */}
                {(
                    parseFloat(reportage?.discount || 0) > 0 &&
                    (reportage?.final_price || reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.final_price || 0) !== ((reportage?.price?.is_default && reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || reportage?.price || 0)

                ) &&
                    <div className="reportageCardDiscountBadge">
                        <img src="/assets/images/star.svg" alt="" />
                    </div>
                }

                {fullDetail &&
                    <>
                        {/* {(reportage?.news?.da || reportage?.news?.pa || reportage?.news?.ss) ?
                            <>

                            </>
                        :null} */}
                        <div className="reportageCardShortDetailBox">
                            <div className="reportageCardShortDetailCard">
                                <div className="reportageCardShortDetailCardTitle">DA</div>
                                <div className="reportageCardShortDetailCardBody">{reportage?.news?.da || "-"}</div>
                            </div>
                            
                            <div className="reportageCardShortDetailCard">
                                <div className="reportageCardShortDetailCardTitle">DR</div>
                                <div className="reportageCardShortDetailCardBody">{reportage?.news?.pa || "-"}</div>
                            </div>
                            <div className="reportageCardShortDetailCard">
                                <div className="reportageCardShortDetailCardTitle">SS</div>
                                <div className="reportageCardShortDetailCardBody">{reportage?.news?.ss || "-"}</div>
                            </div>
                            
                        </div>

                        {(isForeign && reportage?.info?.country) &&
                            <>
                                <div className="reportageCardCountryImage">
                                    <img src={reportage?.info?.country?.logo || imageThumbUrl} alt="" />
                                </div>
                            </>
                        }
                    </>
                }

                <div className="reportageCardHeader">
                    <div className="reportageCardHeaderImage">
                        <img src={reportage?.news?.image || "/assets/images/reportageImage.png"} alt="" />
                    </div>
                    <div className="reportageCardHeaderDetail">
                        <div>
                            {/* <div className="siteTempTitle reportageCardHeaderTitle text-truncate">{reportage?.news?.name}</div> */}
                            <div className="siteTempTitle reportageCardHeaderTitle text-truncate">{reportage?.title || "بدون نام"}</div>
                            <a href={reportage?.news?.site} target="_blank" rel="noopener noreferrer">
                                <div className="siteTempSubtitle reportageCardHeaderCategory text-truncate">{handleGenerateLink(reportage?.news?.site || "") || "بدون سایت"}</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="reportageCardBody">

                    {fullDetail &&

                        <>
                            <div className={"reportageCardDetailBox" + (backLink ? " backLinkDetail" : "")}>
                                {reportage?.type?.length > 0 &&

                                    <div className="reportageCardDetailCard">
                                        <div>موضوع :</div>
                                        <div>
                                            {reportage?.type?.map((item, index) =>
                                                <>
                                                    <span>{item?.title}{reportage?.type?.length !== index + 1 && " ، "}</span>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                }
                                {((reportage?.info?.place_to_show && reportage?.info?.place_to_show !== "-") || reportage?.release_place?.title) &&
                                    <>
                                        <div className="reportageCardDetailCard">
                                            <div style={{ minWidth: 70 }}>محل نمایش :</div>
                                            <div className="text-truncate"
                                                title={reportage?.info?.place_to_show || reportage?.release_place?.title}
                                            >{reportage?.info?.place_to_show || reportage?.release_place?.title}</div>
                                        </div>
                                    </>
                                }
                                {(reportage?.info?.publish_category && reportage?.info?.publish_category !== "-") &&
                                    <>
                                        <div className="reportageCardDetailCard">
                                            <div style={{ minWidth: 70 }}>دسته بندی :</div>
                                            <div className="text-truncate"
                                                title={reportage?.info?.publish_category || ""}
                                            >{reportage?.info?.publish_category}</div>
                                        </div>
                                    </>
                                }
                                {(reportage?.insert_place?.title && backLink) &&
                                    <>
                                        <div className="reportageCardDetailCard">
                                            <div style={{ minWidth: 55 }}>محل درج :</div>
                                            <div className="text-truncate"
                                                title={reportage?.insert_place?.title}
                                            >{reportage?.insert_place?.title}</div>
                                        </div>
                                    </>
                                }
                                {(isForeign && reportage?.info?.language) &&
                                    <>
                                        <div className="reportageCardDetailCard">
                                            <div style={{ minWidth: 70 }}>زبان رسانه :</div>
                                            <div className="text-truncate"
                                                title={reportage?.info?.language?.title}
                                            >{reportage?.info?.language?.title}</div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>

                    }

                    {backLink &&
                        <>
                            <div className="backLinkPeriodMainBox">
                                {reportage?.price?.sort((a, b) => b?.month - a?.month)?.map((price, index) =>
                                    <>
                                        {/* || price?.month?.is_default */}
                                        <div className={"backLinkPeriodCard" + ((BackLinkSelectedPeriod === price?.month) ? " active" : "")} onClick={() => setBackLinkSelectedPeriod(price?.month)}>
                                            <span>{price?.month}</span> ماهه
                                        </div>
                                    </>
                                )}
                                {/* <div className={"backLinkPeriodCard" + (BackLinkSelectedPeriod === 3 ? " active" : "")} onClick={() => setBackLinkSelectedPeriod(3)}>
                                    <span>3</span> ماهه
                                </div>
                                <div className={"backLinkPeriodCard" + (BackLinkSelectedPeriod === 6 ? " active" : "")} onClick={() => setBackLinkSelectedPeriod(6)}>
                                    <span>6</span> ماهه
                                </div>
                                <div className={"backLinkPeriodCard" + (BackLinkSelectedPeriod === 12 ? " active" : "")} onClick={() => setBackLinkSelectedPeriod(12)}>
                                    <span>12</span> ماهه
                                </div> */}
                            </div>
                        </>
                    }

                    <div className="reportageCardPriceBox">
                        {
                            (
                                parseFloat(reportage?.discount || 0) > 0 &&
                                (reportage?.final_price || reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.final_price || 0) !== ((reportage?.price?.is_default && reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || reportage?.price || 0)

                            ) &&
                            <div className="reportageCardDiscount">{reportage?.discount}%</div>
                        }
                        {(backLink && parseFloat(reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent || 0) > 0) &&
                            <div className="reportageCardDiscount">{reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent}%</div>
                        }
                        <div className="reportageCardprice me-auto">
                            {/* {(parseFloat(reportage?.discount || 0) > 0) && */}
                            {(
                                parseFloat(reportage?.discount || 0) > 0 &&
                                (reportage?.final_price || reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.final_price || 0) !== ((reportage?.price?.is_default && reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || reportage?.price || 0)

                            ) &&
                                <div className="reportageCardRealPrice">{separateNumberWithComma((reportage?.price?.is_default && reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price) || reportage?.price || 0)}</div>
                            }
                            {(backLink && parseFloat(reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.discount_percent || 0) > 0) &&
                                <div className="reportageCardRealPrice">{reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.price}</div>
                            }
                            <div className="reportageCardCurrentPrice">{separateNumberWithComma(reportage?.final_price || reportage?.price?.find(item => item?.month === BackLinkSelectedPeriod)?.final_price || 0)}</div>
                            <div className="reportageCardUnit">تومان</div>
                        </div>
                    </div>
                </div>
                <div className="reportageCardFooter">

                    {backLink ?
                        <>
                            {userCart?.carts[reportage?.id]?.[BackLinkSelectedPeriod]?.length > 0 ?
                                <>
                                    <div className="reportageCardCount">
                                        <div className="reportageCardCountOpt"
                                            onClick={handleAddItemToCart}
                                        >
                                            <img src="/assets/images/plus.svg" alt="" />

                                        </div>
                                        <div className="reportageCardCountNum">{userCart?.carts[reportage?.id][BackLinkSelectedPeriod]?.length}</div>
                                        <div className="reportageCardCountOpt minus"
                                            onClick={() => handleDeleteItemFromCart(userCart?.carts[reportage?.id]?.[BackLinkSelectedPeriod]?.[0]?.id, reportage?.id, userCart?.carts[reportage?.id]?.[BackLinkSelectedPeriod]?.[0])}
                                        >
                                            <img src="/assets/images/minus.svg" alt="" />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <LoadingButton
                                        className={"w-66 justify-content-center"}
                                        loading={LoadingAddToCart}
                                        onClick={handleAddItemToCart}
                                    >
                                        اضافه به سبد خرید
                                    </LoadingButton>
                                </>
                            }
                        </>
                    :
                        <>
                            {userCart?.carts[reportage?.id] ?
                                <>
                                    <div className="reportageCardCount">
                                        <div className="reportageCardCountOpt"
                                            onClick={handleAddItemToCart}
                                        >
                                            <img src="/assets/images/plus.svg" alt="" />

                                        </div>
                                        <div className="reportageCardCountNum">{userCart?.carts[reportage?.id]?.length}</div>
                                        <div className="reportageCardCountOpt minus"
                                            onClick={() => handleDeleteItemFromCart(userCart?.carts[reportage?.id]?.[0]?.id, reportage?.id, userCart?.carts[reportage?.id]?.[0])}
                                        >
                                            <img src="/assets/images/minus.svg" alt="" />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <LoadingButton
                                        className={"w-66 justify-content-center"}
                                        loading={LoadingAddToCart}
                                        onClick={handleAddItemToCart}
                                    >
                                        اضافه به سبد خرید
                                    </LoadingButton>
                                </>
                            }
                        </>
                    }

                    {/* <div className="w-66 siteTempBtn half-radius justify-content-center"></div> */}
                    <div className="w-33 siteTempBtn design4 justify-content-center" onClick={() => setShowDetailModal({
                        show: true,
                        selectedId: reportage?.id
                    })}>جزئیات</div>
                </div>
            </div>
        </>
    );
}

export default ReportageCard;