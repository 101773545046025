import VerificationInput from "react-verification-input";
import LoadingButton from "../../../components/loadingButton";
import { useState } from "react";
import { toast } from "react-toastify";
import { setToken } from "../../../utilities/auth";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "../../../store/action/user_action";
import { getUserCartAction } from "../../../store/action/cart_action";
import { getUserBacklinkCartAction } from "../../../store/action/backlink_cart_action";
import { getUserForeignReportageCartAction } from "../../../store/action/foreign_reportage_cart_action";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import { verifyUser } from "../../../service/auth";

const VerifuEmailComponent = ({
    emailOrPhone = "",
    LoginRes = {}
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [LoginForm, setLoginForm] = useState({
        emailOrPhone: emailOrPhone,
        verify_code: ""
    })

    const [LoadingAction, setLoadingAction] = useState(false)


    const handleLogin = () => {
        if (LoginForm.emailOrPhone.trim() === "") {
            toast.error("ایمیل یا شماره خود را وارد کنید")
            return
        }
        if (LoginForm.verify_code.trim() === "") {
            toast.error("کد تایید را وارد کنید")
            return
        }

        setLoadingAction(true)

        let sendData = {
            phoneOrEmail: LoginForm.emailOrPhone.trim(),
            verify_code: LoginForm.verify_code.trim(),
        }

        verifyUser(sendData).then(({ data }) => {
            setLoadingAction(false)
            setToken(data.token);
            toast.success("با موفقیت وارد شدید!")
            dispatch(getUserDataAction())
            dispatch(getUserCartAction())
            dispatch(getUserBacklinkCartAction())
            dispatch(getUserForeignReportageCartAction())
            navigate(routes.dashboard)
        }).catch((err) => {
            setLoadingAction(false)
            console.log(err)
        })

    }
    return (
        <>
            <div className="authMainContactBox">
                <div className="authMainContactBoxHeader">
                    <img src="/assets/images/calling.svg" alt="" />
                    <div>تماس با ما</div>
                </div>
                <div className="authMainContactBoxNumber">
                    09901104954
                </div>
            </div>
            <div>

                <div className="siteTempTitle authMainTitle">تایید حساب کاربری</div>

                <div className="verifyPageTitle">
                    کد تایید برای شما ایمیل شد
                </div>
                <div className="verifyPageSubTitle mt-2">
                    کد ارسال شده را در بخش زیر وارد کنید
                </div>

                <div className="authMainFormBox mt-4">
                    <div className="d-flex justify-content-center">
                        <VerificationInput
                            length={5}
                            validChars="0-9"
                            classNames={{
                                container: "verifyInputsBox_container",
                                character: "verifyInputsBox_character",
                                characterInactive: "verifyInputsBox-character--inactive",
                                characterSelected: "verifyInputsBox-character--selected",
                                characterFilled: "verifyInputsBox-character--filled",
                            }}

                            placeholder=" "

                            onChange={value => setLoginForm({ ...LoginForm, verify_code: value })}

                        />
                    </div>
                </div>

                <div className="authFooterMainBox">
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                        <LoadingButton
                            loading={LoadingAction}
                            onClick={handleLogin}
                            className={"justify-content-between"}
                            style={{
                                width: 100
                            }}
                        >
                            <div>تایید</div>
                            <img src="/assets/images/left-chevron.svg" alt="" />
                        </LoadingButton>
                    </div>


                </div>
            </div>

            <div className="authFooterMainBoxText">
                <span className="ps-2">حساب کاربری ندارید ؟</span>
                <span>
                    <Link to={routes.auth.login}>
                        ورود به حساب کاربری
                    </Link>
                </span>
            </div>
        </>
    );
}

export default VerifuEmailComponent;