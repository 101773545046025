import { getUserCart } from "../../service/cart";
import { groupBy } from "../../utilities";

const groupCartAction = (cartArr) =>{
    // let result = Object.groupBy(cartArr, ({ reportage }) => reportage?.id);
    let result = {};
    let newPackageRes = {};
    let packageResultArr = cartArr?.filter(item => item?.reportage_package && item?.package?.id)
    let packageResult = groupBy(packageResultArr, (item) => item?.package?.id);
    for (const key in packageResult) {
        if (Object.hasOwnProperty.call(packageResult, key)) {
            const element = packageResult[key];
            newPackageRes[`${key}_p`] = [element[0]];
        }
    }

    let reportageResult = groupBy(cartArr, ({ reportage , reportage_package }) => !reportage_package && reportage?.id);

    // return cartArr.reduce((acc, item) => {
    //     const key = ({ reportage }) => reportage?.id;
    //     acc[key] = acc[key] || [];
    //     acc[key].push(item);
    //     return acc;
    //   }, {});

    result = {
        ...newPackageRes,
        ...reportageResult,
    }
    delete result[false]
console.log({rrrr : result})
    return result;
}

export const emptyCartAction = () =>{
    return dispatch =>{
        dispatch({
            type : "EMPTY_CART"
        })
    }
} 

export const setDiscountCartAction = (data) =>{
    return dispatch =>{
        dispatch({
            type : "SET_DISCOUNT",
            payload : data
        })
    }
} 

export const removeDiscountCartAction = () =>{
    return dispatch =>{
        dispatch({
            type : "REMOVE_DISCOUNT"
        })
    }
} 

export const getUserCartAction = () => {
    return async dispath => {
        const {data} = await getUserCart("reportage");

        let newCart = {
            ...data,
            carts:groupCartAction(data?.carts || [])
        }

        dispath({
            type : "INIT_CART",
            // payload : data
            payload : newCart
        })
    }
}

export const addToCartBeforApiAction = (data) => {
    return (dispath , getStore) => {
        const {cart} = getStore();

        let helpObj = cart?.carts || {};

        const cartItemId = data?.type === "package" ? `${data?.package?.id}_p` : data?.reportage?.id
        console.log({data})

        if(helpObj[cartItemId]){
            helpObj[cartItemId].unshift(data);
        }else{
            helpObj[cartItemId]=[data]
        }

        // helpArr.push(data)

        dispath({
            type : "SET_CART",
            payload : helpObj
        })
    }
}

export const addToCartAction = (data) => {
    return (dispath , getStore) => {
        const {cart} = getStore();

        let helpObj = cart?.carts || {};

        const cartItemId = data?.type === "package" ? `${data?.package?.id}_p` : data?.reportage?.id

        console.log({data})
        
        if(helpObj[cartItemId]){
            helpObj[cartItemId].shift();
            helpObj[cartItemId].unshift(data);
        }else{
            helpObj[cartItemId]=[data]
        }

        // helpArr.push(data)
        console.log({helpObj})

        dispath({
            type : "SET_CART",
            payload : helpObj
        })
    }
}

export const removeFromCartBeforeApiAction = (cartItem) => {
    return (dispath , getStore) => {
        const {cart} = getStore();

        let helpObj = cart?.carts || {};

        const cartItemId = cartItem?.type === "package" ? `${cartItem?.id}_p` : cartItem?.reportage?.id

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)

        if(helpObj[cartItemId]){
            if(helpObj[cartItemId]?.length > 1){
                helpObj[cartItemId].shift();
            }else{
                delete helpObj[cartItemId];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_CART",
            payload : helpObj
        })
    }
}

export const removeFromCartAction = (cartItem) => {
    return (dispath , getStore) => {
        const {cart} = getStore();

        let helpObj = cart?.carts || {};

        console.log({cartItem})

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)
        const cartItemId = cartItem?.type === "package" ? `${cartItem?.package?.id}_p` : cartItem?.reportage?.id


        if(helpObj[cartItemId]){
            if(helpObj[cartItemId]?.length > 1){
                helpObj[cartItemId]?.shift();
            }else{
                delete helpObj[cartItemId];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_CART",
            payload : helpObj
        })
    }
}
