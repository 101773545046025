export const hideLoading = () => {

    return (dispatch) => {
        dispatch({type : "HIDE_LOADING" , payload : false})
    }
}

export const showLoading = () => {

    return (dispatch) => {
        dispatch({type : "SHOW_LOADING" , payload : true})
    }
}