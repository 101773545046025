import { useEffect, useState } from "react";
import SiteAccordion from "../../components/accordion";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../store/action/app_action";

const FAQListPage = () => {

    const dispatch = useDispatch();

    const [FaqData, setFaqData] = useState([
        {
            title : "قیمت‌گذاری و تعرفه‌ها",
            data : [
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
            ]
        },
        {
            title : "قیمت‌گذاری و تعرفه‌ها",
            data : [
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
            ]
        },
        {
            title : "قیمت‌گذاری و تعرفه‌ها",
            data : [
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
                {
                    title : "قیمت گذاری رسانه ها به چه صورت انجام می شود",
                    content : "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد"
                },
            ]
        },
    ])

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("سوالات متداول"))
    })

    return ( 
        <>
            <Helmet>
                <title>
                    سوالات متداول
                </title>
            </Helmet>
            <div className="customBox">

                {FaqData.map((faqItem , index) => 
                    <>
                        <div className="faqCategoryMainTitle">{faqItem.title}</div>
                        {faqItem.data.map((faqAcc , index1) => 
                            <>
                                <SiteAccordion 
                                    title={faqAcc.title}
                                    design={2}
                                >
                                    {faqAcc.content}
                                </SiteAccordion>
                            </>
                        )}
                    </>
                )}

            </div>
        </>
    );
}
 
export default FAQListPage;