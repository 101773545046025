const CheckboxTemplate = ({
    title,
    checked = false,
    onChange = () => null,
    value,
    children
}) => {
    return (
        <>
            <div className="checkboxTempBox">
                <label class="checkboxTempLabel">
                    {title || children}
                    {checked ?
                        <input type="checkbox" value={value} onChange={onChange} checked={checked} hidden/>
                    :
                        <input type="checkbox" value={value} onChange={onChange} hidden/>
                    }
                    <span class="checkmark"></span>
                </label>
            </div>
        </>
    );
}

export default CheckboxTemplate;