
const initialState = {
    id: -1,
    carts: {},
    discount: {
        code: "",
        amount: 0
    },
    status: "",
    loading: false,
};

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'INIT_CART': {
            return {
                ...action.payload
            }
        }

        case 'EMPTY_CART': {
            return {
                ...state,
                carts: {}
            }
        }

        case 'SET_DISCOUNT': {
            return {
                ...state,
                discount: { ...action.payload }
            }
        }

        case 'REMOVE_DISCOUNT': {
            return {
                ...state,
                discount: {
                    code: "",
                    amount: 0
                }
            }
        }

        case 'SET_CART': {
            return {
                ...state,
                carts: { ...action.payload },
                loading: false,
            }
        }

        case 'SET_LOADING': {
            return {
                ...state,
                loading: action.payload,
            }
        }

        default:
            return state
    }
}