import { getUserCart } from "../../service/cart";
import { groupBy } from "../../utilities";

const groupCartAction = (cartArr) =>{
    // let result = Object.groupBy(cartArr, ({ reportage }) => reportage?.id);
    let result = groupBy(cartArr, ({ reportage }) => reportage?.id);

    // return cartArr.reduce((acc, item) => {
    //     const key = ({ reportage }) => reportage?.id;
    //     acc[key] = acc[key] || [];
    //     acc[key].push(item);
    //     return acc;
    //   }, {});

    return result;
}

export const emptyForeignReportageCartAction = () =>{
    return dispatch =>{
        dispatch({
            type : "EMPTY_FOREIGN_REPORTAGE_CART"
        })
    }
} 

export const getUserForeignReportageCartAction = () => {
    return async dispath => {
        const {data} = await getUserCart("foreign_reportage");

        let newCart = {
            ...data,
            carts:groupCartAction(data?.carts || [])
        }

        dispath({
            type : "INIT_FOREIGN_REPORTAGE_CART",
            // payload : data
            payload : newCart
        })
    }
}

export const addToForeignReportageCartBeforApiAction = (data) => {
    return (dispath , getStore) => {
        const {foreign_reportage_cart} = getStore();

        let helpObj = foreign_reportage_cart?.carts || {};

        if(helpObj[data?.reportage?.id]){
            helpObj[data?.reportage?.id].unshift(data);
        }else{
            helpObj[data?.reportage?.id]=[data]
        }

        // helpArr.push(data)

        dispath({
            type : "SET_FOREIGN_REPORTAGE_CART",
            payload : helpObj
        })
    }
}

export const addToForeignReportageCartAction = (data) => {
    return (dispath , getStore) => {
        const {foreign_reportage_cart} = getStore();

        let helpObj = foreign_reportage_cart?.carts || {};

        
        if(helpObj[data?.reportage?.id]){
            helpObj[data?.reportage?.id].shift();
            helpObj[data?.reportage?.id].unshift(data);
        }else{
            helpObj[data?.reportage?.id]=[data]
        }

        // helpArr.push(data)

        dispath({
            type : "SET_FOREIGN_REPORTAGE_CART",
            payload : helpObj
        })
    }
}

export const removeFromForeignReportageCartBeforeApiAction = (cartItem) => {
    return (dispath , getStore) => {
        const {foreign_reportage_cart} = getStore();

        let helpObj = foreign_reportage_cart?.carts || {};

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)

        if(helpObj[cartItem?.reportage?.id]){
            if(helpObj[cartItem?.reportage?.id]?.length > 1){
                helpObj[cartItem?.reportage?.id].shift();
            }else{
                delete helpObj[cartItem?.reportage?.id];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_FOREIGN_REPORTAGE_CART",
            payload : helpObj
        })
    }
}

export const removeFromForeignReportageCartAction = (cartItem) => {
    return (dispath , getStore) => {
        const {foreign_reportage_cart} = getStore();

        let helpObj = foreign_reportage_cart?.carts || {};

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)

        if(helpObj[cartItem?.reportage?.id]){
            if(helpObj[cartItem?.reportage?.id]?.length > 1){
                helpObj[cartItem?.reportage?.id]?.shift();
            }else{
                delete helpObj[cartItem?.reportage?.id];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_FOREIGN_REPORTAGE_CART",
            payload : helpObj
        })
    }
}
