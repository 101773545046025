import { useDispatch, useSelector } from "react-redux";
import { addToCart, deleteCart, deleteFromCart } from "../../service/cart";
import { separateNumberWithComma } from "../../utilities";
import LoadingButton from "../loadingButton";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { addToCartAction, addToCartBeforApiAction, emptyCartAction, getUserCartAction, removeFromCartAction, removeFromCartBeforeApiAction } from "../../store/action/cart_action";
import { LoadingData } from "../loading";

const CartCardItem = ({
    cartItem,
    cartGroupId
}) => {
    const dispatch = useDispatch();

    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);


    const handleAddItemToCart = (reportage) => {
        if (LoadingAddToCart) return;
        if (reportage?.package) return
        //         console.log({reportage})
        // return
        setLoadingAddToCart(true);

        let sendData = {
            type: "reportage"
        }

        if (reportage?.package) {
            sendData.package_id = reportage?.package?.id
        } else {
            sendData.reportage_id = reportage?.id
        }

        dispatch(addToCartBeforApiAction({ reportage , package: reportage, type:reportage?.package ? "package" : undefined }));
        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            dispatch(addToCartAction({...data ,  type:reportage?.package ? "package" : undefined}));
            // setShowReportageModal({ ...ShowReportageModal, show: false })

        }).catch((err) => {
            setLoadingAddToCart(false);
            dispatch(removeFromCartBeforeApiAction({ reportage }));
            console.log(err)
        })

    }

    const handleDeleteItemFromCart = (itemId, reportageId, cart) => {

        const sendData = {}

        const sendItemId = cart?.package ? cart?.package?.id : itemId

        if (cart?.package) {
            sendData.package_id = cart?.package?.id
        } else {
            sendData.reportage_id = reportageId
        }

        dispatch(removeFromCartAction({ ...cart, type: cart?.package ? "package" : undefined }));
        deleteFromCart("reportage", sendItemId, sendData).then(({ data }) => {
            // setLoadingAddToCart(false);
            // handleCalculateCart();
            // toast.success("با موفقیت به سبد خرید اضافه شد!")

        }).catch((err) => {
            console.log(err)
            dispatch(addToCartAction({ ...cart, type: cart?.package ? "package" : undefined }));

        })
    }

    return (
        <>
            <div className="miniCartCardItem">
                <div className="text-truncate miniCartCardItemTitle">
                    {cartItem?.[0]?.package ? cartItem?.[0]?.package?.name : cartItem?.[0]?.reportage?.title || `آیتم ${cartGroupId}`}
                    {cartItem?.[0]?.package ?
                        <>
                            <div className="miniCartPackageTitle">
                                <img src="/assets/images/3dcubePackage.svg" alt="" />
                            </div>
                        </>
                        : null}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="miniCartCardItemCount">

                        {/* {!LoadingAddToCart ?
                            <>

                                <LoadingData />
                            </>
                            :
                            <>
                            </>
                        } */}
                        <div className={"miniCartCardItemCountOpt " + (cartItem?.[0]?.package ? "disabled" : "")}
                            onClick={() => handleAddItemToCart(cartItem?.[0]?.package ? cartItem?.[0] : cartItem?.[0]?.reportage)}
                        >
                            <img src="/assets/images/plus.svg" alt="" />

                        </div>
                        <div>
                            {cartItem?.[0]?.package ? 1
                                // {
                                //     for (const key in object) {
                                //         if (Object.hasOwnProperty.call(object, key)) {
                                //             const element = object[key];

                                //         }
                                //     }
                                // }
                                :
                                cartItem?.length
                            }
                        </div>
                        <div className="miniCartCardItemCountOpt"
                            onClick={() => handleDeleteItemFromCart(cartItem?.[0]?.id, cartItem?.[0]?.reportage?.id, cartItem?.[0])}
                        >
                            <img src="/assets/images/minus.svg" alt="" />
                        </div>
                    </div>
                    <div className="miniCartCardItemPrice">
                        {cartItem?.[0]?.package ?
                            <div>
                                {separateNumberWithComma(cartItem?.[0]?.package?.price)}
                            </div>
                            :
                            <div>{separateNumberWithComma(cartItem?.[0]?.reportage?.price)}</div>
                        }
                        <div className="unit">تومان</div>
                    </div>
                </div>
            </div >
        </>
    )
}

const MiniCartCompoment = ({ className, payOrderFunction = () => { }, loading = false, cartStep = 1 }) => {

    const userCart = useSelector(store => store.cart)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [CartFinalSumItems, setCartFinalSumItems] = useState({
        discounts: 0,
        finalPrice: 0,
        price: 0,
        count: 0,
    })


    const handleCalculateCart = () => {
        let discounts = 0;
        let finalPrice = 0;
        let price = 0;
        let count = 0;

        // for (let i = 0; i < userCart?.carts.length; i++) {
        //     const element = userCart?.carts[i];

        // }
        Object.keys(userCart?.carts).map((key) => {
            for (let i = 0; i < userCart?.carts[key].length; i++) {
                const element = userCart?.carts[key]?.[i];

                if (element?.package) {
                    discounts += (element?.package?.price - element?.package?.final_price)
                    finalPrice += element?.package?.final_price
                    price += element?.package?.price;
                    count++;
                } else {
                    discounts += (element?.reportage?.price - element?.reportage?.vip_price)
                    finalPrice += element?.reportage?.vip_price
                    price += element?.reportage?.price;
                    count++;
                }


            }
        });
        // discounts += parseInt(userCart?.discount?.amount || 0);
        finalPrice -= parseInt(userCart?.discount?.amount || 0);
        setCartFinalSumItems({
            discounts: discounts,
            finalPrice: finalPrice,
            price: price,
            count: count,
        })
    }

    // const handleDeleteAllCart = async () => {
    //     for (let i = 0; i < userCart.length; i++) {
    //         const element = userCart[i];
    //         await handleDeleteItemFromCart(element?.id, element?.reportage?.id);
    //     }

    // }

    const handleDeleteCart = () => {
        dispatch(emptyCartAction());
        deleteCart("reportage", 1).then(({ data }) => {
            console.log(data);
        }).catch((err) => {
            dispatch(getUserCartAction());
            console.log(err)
        })
    }

    const handleGoToNextStep = () => {
        if (CartFinalSumItems.count > 0) {
            navigate(routes.cart.list)
        }
    }


    useEffect(() => {
        handleCalculateCart();
    }, [userCart])

    return (
        <>
            <div className="showMiniCartMainBox">
                <div
                    className={"miniCartHeader" + (CartFinalSumItems.count > 0 ? " hasData" : "")}
                >
                    <div className="miniCartTitle">
                        <div className="miniCartTitleCount">{CartFinalSumItems.count}</div>
                        <div>سبد خرید</div>
                    </div>
                    <div className="miniCartSumItems">
                        <div>{separateNumberWithComma(CartFinalSumItems.finalPrice)}</div>
                        <div className="unit">تومان</div>
                    </div>
                </div>
                <div className="miniCartBody">
                    <div className="miniCartItemsBox">

                        {Object.keys(userCart?.carts).map((key, index) =>
                            <>
                                <CartCardItem
                                    cartItem={userCart?.carts[key]}
                                    cartGroupId={key}
                                    key={`cartItem-${key}-${index}`}
                                />
                            </>
                        )}

                        {/* {userCart?.carts?.map((cart, index) =>


                        )} */}

                        {(CartFinalSumItems.count === 0 || !userCart?.carts) &&

                            <>
                                <div className="emptyMiniCartBox">
                                    <img src="/assets/images/emptyCart.svg" alt="" />
                                    <div className="mt-3">
                                        سبد خرید خالی است
                                    </div>
                                </div>
                            </>

                        }

                    </div>

                    <div className="miniCartBodyFooterBox">
                        <div className="miniCartBodyFooterSumBox">
                            <div className="d-flex align-items justify-content-between text-gray3 miniCartBodyFooterSumCard">
                                <div>مجموع سبد خرید</div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.price)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                            {userCart?.discount?.amount ?
                                <>
                                    <div className="d-flex align-items justify-content-between text-accentColor text-greenColor miniCartBodyFooterSumCard">
                                        <div>کد تخفیف </div>
                                        <div className="price">
                                            <div>
                                                {separateNumberWithComma(userCart?.discount?.amount || 0)}
                                            </div>
                                            <div className="unit">تومان</div>
                                        </div>
                                    </div>
                                </>
                            :null}
                            <div className="d-flex align-items justify-content-between text-accentColor miniCartBodyFooterSumCard">
                                <div>مجموع تخفیف ها </div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.discounts)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                            <div className="d-flex align-items justify-content-between miniCartBodyFooterSumCard">
                                <div>مبلغ نهایی</div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.finalPrice)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                        </div>

                        {cartStep === 1 &&

                            <div className={"miniCartBodyFooterBtnBox miniCartStep1" + (CartFinalSumItems.count > 0 ? " hasData" : "")}>


                                <div className="siteTempBtn design4"
                                    onClick={handleDeleteCart}
                                >
                                    <div>حذف سبد</div>
                                    <img src="/assets/images/delete.svg" alt="" />
                                </div>



                                <div
                                    className={"siteTempBtn primary justify-content-between"}
                                    onClick={handleGoToNextStep}
                                >
                                    <div>ادامه خرید</div>
                                    <img src="/assets/images/left-chevron.svg" alt="" />
                                </div>
                            </div>

                        }

                        {cartStep === 2 &&

                            <div className="miniCartBodyFooterBtnBox">


                                <Link to={routes.reportage.list}>
                                    <div className="siteTempBtn design4"
                                        style={{ width: "100%" }}
                                    >
                                        مرحله قبل
                                    </div>
                                </Link>



                                <LoadingButton
                                    className={"justify-content-between"}
                                    onClick={payOrderFunction}
                                    loading={loading}
                                >
                                    <div>پرداخت</div>
                                    <img src="/assets/images/left-chevron.svg" alt="" />
                                </LoadingButton>
                            </div>

                        }



                    </div>


                </div>
                {cartStep !== 1 &&
                    <>
                        <LoadingButton
                            className="reverse justify-content-center mt-3"
                            loading={loading}
                            onClick={() => payOrderFunction({ payLater: true })}
                        >
                            بعدا پرداخت میکنم !
                        </LoadingButton>
                        <div className="text-gray4 text-center mt-3"
                            style={{
                                fontSize: 11.5,
                                fontWeight: 500
                            }}
                        >
                            انتشار رپورتاژ تنها بعد از پرداخت امکان پذیر است.
                        </div>

                    </>
                }

            </div>
        </>
    );
}

export default MiniCartCompoment;