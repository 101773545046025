import SitePagination from "../../components/pagination";
import { perPageArr } from "../../utilities/config";
import { customSelectStyle, indicatorContainerBg, perPageControl } from "../../components/reactSelectComponens";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setCurrentPageTitle_action } from "../../store/action/app_action";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import ReportStatus from "../../components/reportStatus";
import { separateNumberWithComma } from "../../utilities";
import jMoment from "moment-jalaali";
import { getAllFinancialReport } from "../../service/financial";
import $ from "jquery";
import { LoadingData } from "../../components/loading";
import NoDataComponent from "../../components/noData";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            <div style={{ fontSize: 11 }}>براساس</div>
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {/* <div className="itemSelectCount">126</div> */}
            {children}
        </div>
    </components.Control>
);

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
            <div className="itemSelectCountInSelectBox"></div>
        </div>
    </components.SingleValue>
);


const FinancialReportList = () => {

    const dispatch = useDispatch();

    const FAKE_DATA = [
        {
            id: 1,
            amount: +10000000,
            created_at: "2024-01-12T19:11:25",
            description: "شارژ حساب کاربری",
            status_name: "شارژ حساب",
        },
        {
            id: 1,
            amount: +12000000,
            created_at: "2024-01-12T19:11:25",
            description: "شارژ حساب کاربری",
            status_name: "شارژ حساب",
        },
        {
            id: 1,
            amount: -12000000,
            created_at: "2024-01-12T19:11:25",
            description: "پرداخت کمپین بک لینک با کد 3245664",
            status_name: "پرداخت کمپین",
        },
        {
            id: 1,
            amount: +10000000,
            created_at: "2024-01-12T19:11:25",
            description: "شارژ حساب کاربری",
            status_name: "شارژ حساب",
        },
        {
            id: 1,
            amount: +12000000,
            created_at: "2024-01-12T19:11:25",
            description: "شارژ حساب کاربری",
            status_name: "شارژ حساب",
        },
        {
            id: 1,
            amount: -12000000,
            created_at: "2024-01-12T19:11:25",
            description: "پرداخت کمپین بک لینک با کد 3245664",
            status_name: "پرداخت کمپین",
        },
    ]

    const SHOW_REPORT_IMAGE = ["پرداخت کمپین 11"];

    const ORDER_BY = [
        {
            label: "پیش فرض",
            value: "پیش فرض",
        },
        {
            label: "در انتظار پرداخت",
            value: "در انتظار پرداخت",
        },
        {
            label: "در انتظار ایجاد ",
            value: "در انتظار ایجاد ",
        },
        {
            label: "درحال بررسی ",
            value: "درحال بررسی ",
        },
        {
            label: "منتشرشده",
            value: "منتشرشده",
        },
        {
            label: "رد شده",
            value: "رد شده",
        },
    ]

    const FILTTER_BY = [
        {
            label: "رپورتاژ",
            value: "رپورتاژ",
        },
    ]

    const [ReportData, setReportData] = useState({
        loading: true,
        data: {}
    })
    // const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: "all",
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(10)

    const handleGetReportData = () => {
        setReportData({ ...ReportData, loading: true })
        getAllFinancialReport({
            page: CurrentPage,
            perPage: PerPage,
        }).then(({ data }) => {

            let helpData = data;
            let helpArr = []

            for (const key in helpData?.data) {
                if (Object.hasOwnProperty.call(helpData?.data, key)) {
                    const element = helpData?.data[key];
                    helpArr.push({
                        ...element,

                        reportType: element?.reportage ? "reportage" : element?.backlink ? "backlink" : "wallet"
                    })
                }
            }

            // helpData.data = helpData.data?.map((item) =>{
            //     return {
            //         ...item,
            //         reportType : item?.reportage ? "reportage" : item?.backlink ? "backlink" : "wallet"
            //     }
            // })


            helpData.data = helpArr;

            $(".itemSelectCountInSelectBox").text(helpData?.meta?.total || 0)

            setReportData({

                loading: false,
                data: helpData

            })

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetReportData();
    }, [CurrentPage])

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("لیست تراکنش ها"));
    }, [])


    return (
        <>
            <Helmet>
                <title>
                    لیست تراکنش ها
                </title>
            </Helmet>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2">

                    <Select
                        defaultValue={ORDER_BY[0]}
                        options={ORDER_BY}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control, SingleValue }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#292D32",
                                fontWeight: 700,
                            }),
                            dropdownIndicator: indicatorContainerBg,
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                    <Select
                        defaultValue={FILTTER_BY[0]}
                        options={FILTTER_BY}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control, SingleValue }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            }),
                            singleValue: (baseStyles, state) => ({
                                ...baseStyles,
                                color: "#292D32",
                                fontWeight: 700,
                            }),
                            dropdownIndicator: indicatorContainerBg,
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                </div>

                <div className="d-flex gap-2">
                    <div className="siteTempBtn blueBg radius-10 noHover">
                        <img src="/assets/images/excel.svg" alt="" />
                        <div>
                            خروجی اکسل
                        </div>
                    </div>
                </div>
            </div>
            <div className="customBox">
                <table className="siteTempTable">
                    <tr>
                        <th>ردیف</th>
                        <th>مبلغ شارژ (تومان)</th>
                        <th className="text-center">تاریخ </th>
                        <th className="text-center">توضیحات</th>
                        <th className="text-center">بابت</th>
                        <th className="text-center">اقدامات</th>
                    </tr>
                    {/* {ReportageData.data?.data?.map((reportage, index) => */}

                    {!ReportData?.loading &&
                        <>
                            {ReportData?.data?.data?.map((report, index) =>

                                <>
                                    <tr className="position-relative">
                                        <td>
                                            <div className="reportageCodeBox">
                                                {report?.id}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className={"financeReportArrow" + (!report?.is_wallet ? " decrease" : " increase")}>
                                                    {/* <img src="/assets/images/arrow-top.svg" alt="" /> */}
                                                    {!report?.is_wallet ? "کسر" : "واریز"}
                                                </div>
                                                <div>
                                                    {separateNumberWithComma(report?.amount || report?.price)}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {jMoment(new Date(report?.created_at)).format("jD jMMMM jYYYY")}
                                        </td>
                                        <td className="text-center">
                                            {report?.is_wallet ? "شارژ حساب کاربری" : (report?.reportage) ? `پرداخت ${report?.reportage_client?.campaign?.name || "کمپین رپورتاژ"}` : `پرداخت ${report?.backlink_client?.campaign?.name || "کمپین بک لینک"}`}
                                        </td>
                                        <td className="text-center">
                                            <ReportStatus reportStatus={
                                                report?.is_wallet ? "شارژ حساب" : "پرداخت کمپین"
                                            } withBox={true} />
                                        </td>
                                        <td>
                                            <div className="customFlexBox justify-content-end gap-2">

                                                {SHOW_REPORT_IMAGE.indexOf(report?.status) > -1 &&

                                                    <>
                                                        <div className="siteTempBtn tableBtn blueBg noHover outline">
                                                            <div>مشاهده پیش فاکتور</div>
                                                        </div>
                                                    </>

                                                }

                                            </div>
                                        </td>
                                    </tr>
                                </>

                            )}
                        </>
                    }
                </table>

                {ReportData?.loading &&
                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                }

                {(ReportData?.data?.length === 0 && ReportData?.loading === false) &&
                    <>
                        <NoDataComponent />
                    </>
                }



                <div className="siteTempTableFooter mt-3">


                    <Select
                        defaultValue={perPageArr[0]}
                        options={perPageArr}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{
                            Control: perPageControl
                        }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            })
                        }}
                    // onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                    <SitePagination
                        currentPage={CurrentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={ReportData?.data?.meta?.total || 0}
                        perPage={PerPage}
                        showNav={false}
                    />
                </div>

            </div>
        </>
    );
}

export default FinancialReportList;