import { getUserData } from "../../service/auth";

export const getUserDataAction = () => {
    return async dispath => {
        const {data} = await getUserData();
        dispath({
            type : "SET_USER_DATA",
            payload : data
        })
    }
}