import axios from "axios";
import { apiUrl } from "../utilities/config";
import { getToken, logoutUser } from "../utilities/auth";
import { toast } from "react-toastify";
import { navigate } from "react-router-dom";
import { routes } from "../routes";

const customAxios = axios.create({
    baseURL: apiUrl
});

const setAuthToken = (token) => {
    if (token) {
        customAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete customAxios.defaults.headers.common['Authorization'];
    }
};

setAuthToken(localStorage.getItem("ad_token"));

customAxios.defaults.headers.post["Content-Type"] = "application/json";


customAxios.interceptors.response.use(null, (error) => {
    toast.dismiss();

    const config = error.config || {};
    const showToast = config.showToast !== false;  // Default to showing toast unless explicitly set to false


    if (error.response) {


        if (error.response.status === 401 || error.response.status === 403) {
            logoutUser();
            // navigate(routes.auth.login);
            window.location.href = routes.auth.login;
        }

        if(showToast){
            var myObject = error.response.data["errors"];
            if (myObject) {
                Object.keys(myObject).map(function (key) {
                    var text = myObject[key][0];
                    toast.error(text, {
                        closeOnClick: true,
                    });
                });
            } else {
                toast.error(error.response.data["message"] || error.response.data["error"], {
                    closeOnClick: true,
                });
            }
        }


    } else {
        toast.dismiss();
        toast.error("Server Error", {
            closeOnClick: true,
        });
    }
    const expectedErrors =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    if (!expectedErrors) {
        // setTimeout(()=>{toast.dismiss("loadingToast")},700)

        // localStorage.removeItem("token");
    }
    // return Promise.reject(error);
    if (error.response) {
        return Promise.reject(error.response);
    } else {
        return Promise.reject(error.message);
    }
});

export { customAxios, setAuthToken };