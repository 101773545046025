import jMoment from 'moment-jalaali';
import { routes } from '../../routes';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import { useEffect, useRef, useState } from 'react';



const Sidebar = () => {

    const location = useLocation();
    const miniMenu = useRef(null);

    const showMiniSidebar = [
        routes.cart.list,
        routes.reportage.list,
        routes.foreign_reportage.list,
        routes.backLink.list,
    ]

    const sidebarMenu = [
        {
            title: "داشبورد",
            icon: "/assets/images/dashboard.svg",
            link: routes.dashboard,
            children: [],
        },
        {
            title: "رپورتاژ خارجی",
            icon: "/assets/images/global.svg",
            link: routes.foreign_reportage.list,
            children: [],
        },
        {
            title: "رپورتاژ آگهی",
            icon: "/assets/images/reportage1.svg",
            link: routes.reportage.list,
            children: [
                {
                    title: "رپورتاژ های من",
                    link: routes.reportage.myReportage
                },
                {
                    title: "کمپین های رپورتاژ",
                    link: routes.reportage.campaigns
                },
                {
                    title: "ساخت کمپین رپورتاژ",
                    link: routes.reportage.list
                },

            ],
        },
        {
            title: "بک لینک اعتباری",
            icon: "/assets/images/link-square.svg",
            link: routes.backLink.list,
            children: [
                {
                    title: "بک لینک های من",
                    link: routes.backLink.myBackLink,
                },
                {
                    title: "کمپین های بک لینک",
                    link: routes.backLink.campaigns,
                },
                {
                    title: "ساخت کمپین بک لینک",
                    link: routes.backLink.list,
                },
            ],
        },
        {
            title: "تولید محتوا",
            icon: "/assets/images/edit1.svg",
            link: routes.createContent.root,
            children: [],
        },

        {
            title: "مدیریت مالی",
            icon: "/assets/images/finance.svg",
            link: "",
            children: [
                {
                    title: "گزارش مالی",
                    link: routes.financial.reportList,
                },
                {
                    title: "افزایش اعتبار آنلاین",
                    link: routes.financial.onlinePayment,
                },
                {
                    title: "فیش واریزی",
                    link: routes.financial.receiptSubmissionReportList,
                },
                {
                    title: "اطلاعات حساب مالی",
                    link: routes.profile.financial,
                },
                {
                    title: "فاکتورها",
                    link: "",
                },
            ],
        },
        {
            title: "پشتیبانی",
            icon: "/assets/images/messages.svg",
            link: routes.ticket.root,
            children: [
                {
                    title: "تیکت ها",
                    icon: "/assets/images/messages.svg",
                    link: routes.ticket.list,
                    // children: [],
                },
                {
                    title: "سوالات متداول",
                    icon: "/assets/images/messages.svg",
                    link: routes.faq.list,
                    // children: [],
                },

            ],
        },
    ]

    const [ShowSubmenuInMini, setShowSubmenuInMini] = useState({
        title: "",
        menu: []
    });

    const handleCheckMenu = (e) => {
        if ($(e.currentTarget).parent().next(".sidebarMenuItemSubmenuBox").length > 0) {
            if (showMiniSidebar.indexOf(location.pathname) === -1 && window?.outerWidth > 480) {
                e.preventDefault();
                // $(".sidebarMenuItemSubmenuBox").slideUp(400)

                if ($(e.currentTarget).hasClass("show")) {
                    $(e.currentTarget).removeClass("show")
                    $(e.currentTarget).parent().next(".sidebarMenuItemSubmenuBox").slideUp(400);
                    $(e.currentTarget).children(".sidebarMenuItemArrow").removeClass("rotate-180deg");

                } else {
                    $(".sidebarMenuItemSubmenuBox").slideUp(400);
                    $(".sidebarMenuItemArrow").removeClass("rotate-180deg");
                    $(e.currentTarget).addClass("show")
                    $(e.currentTarget).parent().next(".sidebarMenuItemSubmenuBox").slideDown(400);
                    $(e.currentTarget).children(".sidebarMenuItemArrow").addClass("rotate-180deg");
                }
            }
        }
    }

    const handleShowSubmenuInMini = (e, menuList) => {
        if (showMiniSidebar.indexOf(location.pathname) > -1 && window.outerWidth > 480) {
            $(".showSubmenuInMiniSidebar").css({
                top: $(e.currentTarget).offset().top,
                left: $(e.currentTarget).offset().left - 200,
            })
            // console.log({
            //     top: $(e.currentTarget).offset().top,
            //     left: $(e.currentTarget).offset().left,
            // })
            setShowSubmenuInMini({
                title: menuList?.title,
                menu: menuList?.children || [],
            })
        }
    }

    const handleHideSubmenuInMini = (e) => {
        // console.log({
        //     aa : miniMenu.current,
        //     ss : $(e.currentTarget).children(".sidebarMenuItemTitle").text() !== ShowSubmenuInMini.title,
        //     qq : $(e.currentTarget).children(".sidebarMenuItemTitle").text() ,
        //     ww : ShowSubmenuInMini.title,
        //     ee : $(e.target).is('.showSubmenuInMiniSidebar, .showSubmenuInMiniSidebar::before') ,
        //     rr : miniMenu.current.contains(e.target) ,
        //     dd : e.target,
        //     dd11 : e.relatedTarget
        // })
        // Delay hiding to allow transition from .sidebarMenuItem to .showSubmenuInMiniSidebar
        setTimeout(() => {
            if (
                miniMenu.current &&
                !miniMenu.current.contains(e.relatedTarget)
                // !miniMenu.current.contains(e.target)
                // $(e.currentTarget).children(".sidebarMenuItemTitle").text() !== ShowSubmenuInMini.title
            ) {
                setShowSubmenuInMini({
                    title: "",
                    menu: [],
                });
            }
        }, 0);
        return
        if (
            miniMenu.current &&
            !miniMenu.current.contains(e.target) &&
            $(e.currentTarget).children(".sidebarMenuItemTitle").text() !== ShowSubmenuInMini.title
        ) {
            console.log("vvvvvvv")
            // If the mouse enters an element outside miniMenu and the title does not match, update the state
            setShowSubmenuInMini({
                title: "",
                menu: [],
            });
        } 
        else if (
            !$(e.target).is('.showSubmenuInMiniSidebar, .showSubmenuInMiniSidebar::before , .sidebarMenuItem') &&
            !miniMenu.current.contains(e.target)
        ) {
            setShowSubmenuInMini({
                title: "",
                menu: [],
            });
        }
        // if($(e.target).hasClass("hover") && !$(e.target).hasClass("showSubmenuInMiniSidebar")){
        //     setShowSubmenuInMini({
        //         title: "",
        //         menu: [],
        //     })

        // } else if (
        //     miniMenu.current &&
        //     // !miniMenu.current.contains(e.target) && 
        //     $(e.currentTarget).children(".sidebarMenuItemTitle").text() !== ShowSubmenuInMini.title
        // ) {
        //     setShowSubmenuInMini({
        //         title: "",
        //         menu: [],
        //     })
        // // }else if($(e.target).hasClass("hover") && $(e.target).not(".showSubmenuInMiniSidebar")){
        // }
    }

    useEffect(() => {
        if (ShowSubmenuInMini.title !== "") {
            setShowSubmenuInMini({
                title: "",
                menu: [],
            })
        }
        if(window.outerWidth > 480){
            if (showMiniSidebar.indexOf(location.pathname) > -1) {
                let r = document.querySelector(':root');
                let rs = getComputedStyle(r);
                r.style.setProperty('--sideBarWidth', '100px')
                $(".sidebarMenuItemSubmenuBox").slideUp(400);
            } else {
                let r = document.querySelector(':root');
                let rs = getComputedStyle(r);
                r.style.setProperty('--sideBarWidth', '270px')
            }
        }

    }, [location.pathname])

    // useEffect(() => {
    //     $(".sidebarMenuCard").hover(function () {
    //         if($(this).children(".sidebarMenuItemSubmenuBox").length > 0){
    //             $(this).children(".sidebarMenuItemSubmenuBox").css({
    //                 top : $(this).offset().top,
    //                 left : $(this).offset().left,
    //             })
    //         }
    //     })
    // },[])

    return (
        <>
            <div
                className={
                    "showSubmenuInMiniSidebar" +
                    (ShowSubmenuInMini.title === "" && ShowSubmenuInMini.menu.length === 0 ? " d-none" : "") +
                    (ShowSubmenuInMini.menu.length === 0 ? " borderRadius-0" : "")
                }
                onMouseLeave={e => handleHideSubmenuInMini(e)}
                ref={miniMenu}
            >
                <div className="showSubmenuInMiniSidebarTitle">
                    {ShowSubmenuInMini.title}
                </div>
                <div className="showSubmenuInMiniSidebarBody">
                    {ShowSubmenuInMini.menu.map((submenu, index1) =>
                        <>
                            <Link to={submenu.link} key={`submenu-${index1}`}>
                                <div className={"sidebarMenuItem" + (location.pathname === submenu.link ? " active" : "")}>
                                    <div className="sidebarMenuItemIcon">
                                    </div>
                                    <div className="sidebarMenuItemTitle">{submenu.title}</div>
                                </div>

                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className={"sidebarMainBox" + ((showMiniSidebar.indexOf(location.pathname) > -1 && window.outerWidth > 480) ? " miniSidebar" : "")}>
                <div className="sidebarLogoBox">
                    <img src="/assets/images/logo-mini.png" alt="" />
                    <img className='hideInMiniSidebar' src="/assets/images/logo-text.png" alt="" />
                </div>

                <Link to={routes.campaign.create}>
                    <div className="siteTempBtn radius-10 my-3">
                        <img src="/assets/images/plus-withBg.svg" alt="" />
                        <div className='text-truncate hideInMiniSidebar'>ساخت کمپین جدید</div>
                    </div>
                </Link>




                <div className="sidebarMenuBox">
                    {sidebarMenu.map((menu, index) =>
                        <>
                            <div className="sidebarMenuCard" key={`menuCard-${index}`}
                                onMouseEnter={e => handleShowSubmenuInMini(e, menu || {})}
                                onMouseLeave={e => handleHideSubmenuInMini(e)}
                            >

                                <Link to={menu.link}>
                                    <div
                                        className={
                                            "sidebarMenuItem" +
                                            (location.pathname === menu.link ? " active" : "") +
                                            (ShowSubmenuInMini.title === menu.title ? " hover" : "")
                                        }
                                        
                                        onClick={e => handleCheckMenu(e)}
                                    >
                                        <div className="sidebarMenuItemIcon">
                                            <img src={menu.icon} alt="" />
                                        </div>
                                        <div className="sidebarMenuItemTitle hideInMiniSidebar">{menu.title}</div>

                                        {(menu.children && menu.children.length > 0) &&

                                            <>

                                                <div className="sidebarMenuItemArrow hideInMiniSidebar">
                                                    <img src="/assets/images/chevron-down.svg" alt="" />
                                                </div>

                                            </>

                                        }
                                    </div>

                                </Link>
                                {(menu.children && menu.children.length > 0) &&

                                    <>

                                        <div className="sidebarMenuItemSubmenuBox">
                                            {menu.children.map((submenu, index1) =>
                                                <>
                                                    <Link to={submenu.link} key={`submenu-${index1}`}>
                                                        <div className={"sidebarMenuItem" + (location.pathname === submenu.link ? " active" : "")}>
                                                            <div className="sidebarMenuItemIcon">
                                                                {/* <img src={submenu.icon} alt="" /> */}
                                                            </div>
                                                            <div className="sidebarMenuItemTitle">{submenu.title}</div>
                                                        </div>

                                                    </Link>
                                                </>
                                            )}
                                        </div>

                                    </>

                                }

                            </div>
                        </>
                    )}
                </div>
                <div className="sidebarTodayDateBox radius-10">
                    <img src="/assets/images/calendar.svg" alt="" />
                    <div className='text-truncate hideInMiniSidebar'>
                        {jMoment(new Date()).format("jD jMMMM jYYYY")}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;