import { Swiper, SwiperSlide } from "swiper/react";
import { handleGenerateLink, separateNumberWithComma } from "../../../utilities";
import LoadingButton from "../../loadingButton";
import { useEffect, useState } from "react";
import { getReportagePackageData, getReportagePackages } from "../../../service/reportage";
import SiteModal from "../../siteModal";
import { LoadingData } from "../../loading";
import ReportageDetailModal from "../detailModal";
import { useDispatch, useSelector } from "react-redux";
import { addToCartAction, addToCartBeforApiAction, removeFromCartBeforeApiAction } from "../../../store/action/cart_action";
import { addToCart } from "../../../service/cart";
import SiteAccordion from "../../accordion";
import { toast } from "react-toastify";
import { Navigation, Pagination } from "swiper/modules";

const ReportagePackages = ({
    parentClassName = "",
    navClassName = "",
}) => {
    const userCart = useSelector(store => store.cart)

    const dispatch = useDispatch();

    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        data: {},
        selectedId: -1
    })
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })
    const [ListData, setListData] = useState({})
    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);

    const handleGetReportagePackages = () => {
        getReportagePackages().then(({ data }) => {
            setListData(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetSingleReportagePackageData = () => {

        if (ReportageSingleData.loading || !ShowReportageModal.show) return
        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getReportagePackageData(ShowReportageModal?.data?.id).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleAddToCart = (reportagePackage) => {

        if (userCart?.carts[`${reportagePackage?.id}_p`]) {
            toast.info("این پکیج به سبد خرید شما اضافه شده است.")
            return
        }

        setLoadingAddToCart(true);

        let sendData = {
            package_id: reportagePackage?.id,
            type: "reportage"
        }
        dispatch(addToCartBeforApiAction({ package: reportagePackage, type: "package" }));
        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            dispatch(addToCartAction({ ...data, type: "package" }));
            // toast.success("با موفقیت به سبد خرید اضافه شد!")

        }).catch((err) => {
            setLoadingAddToCart(false);
            dispatch(removeFromCartBeforeApiAction({ ...reportagePackage }));
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetReportagePackages();
    }, [])

    useEffect(() => {
        if (ShowReportageModal?.selectedId !== -1) {
            handleGetSingleReportagePackageData();
        }
    }, [ShowReportageModal?.selectedId])

    return (
        <>

            <SiteModal
                modalDesign={2}
                title={
                    <>

                        <div className="specialReportageCardHeader showInModalTitle">
                            <div className="specialReportageCardImage">
                                {/* <img src="/assets/images/pomegranate.png" alt="" /> */}
                                <img src={ReportageSingleData?.data?.image} alt="" />
                            </div>
                            <div className="">{ReportageSingleData?.data?.name}</div>
                        </div>
                    </>
                }
                showModal={ShowReportageModal.show}
                closeModal={() => setShowReportageModal({ ...ShowReportageModal, show: false, selectedId: -1 })}
            >

                {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>

                        <div className="reportageModalBuyBox">
                            <div className="reportageModalBuyAddToCardBox">
                                <div className="reportageModalBuyAddToCard">
                                    <LoadingButton
                                        className={"half-radius justify-content-center"}
                                        loading={LoadingAddToCart}
                                        onClick={() => handleAddToCart(ReportageSingleData?.data)}
                                    >
                                        اضافه به سبد خرید
                                    </LoadingButton>
                                    {/* <div className="siteTempBtn half-radius justify-content-center">اضافه به سبد خرید</div> */}
                                </div>
                                <div className="reportageModalBuyPriceBox">
                                    <div className="reportageCardprice ">
                                        {(parseFloat(ReportageSingleData?.data?.discount || 0) > 0) &&
                                            <div className="reportageCardRealPrice">{separateNumberWithComma(ReportageSingleData?.data?.price || 0)}</div>
                                        }
                                        <div className="reportageCardCurrentPrice">{separateNumberWithComma(ReportageSingleData?.data?.final_price || 0)}</div>
                                        <div className="reportageCardUnit">تومان</div>
                                    </div>
                                </div>
                            </div>
                            {parseInt(ReportageSingleData?.data?.discount) > 0 &&

                                <div className="siteTempBtn half-radius reportageModalBuyDiscountBox">
                                    <div> {ReportageSingleData?.data?.discount}% تخفیف ویژه </div>
                                    <img src="/assets/images/star.svg" alt="" />
                                </div>

                            }
                        </div>

                        <div className="packageModalShowReportageBox">
                            {ReportageSingleData?.data?.reportages?.map((item, index) =>
                                <>
                                    <SiteAccordion
                                        title={
                                            <>
                                                <div className="d-flex align-items-center justify-content-between" style={{width:"100%"}}>

                                                    <div className="reportageCardHeader mb-0">
                                                        <div className="reportageCardHeaderImage">
                                                            <img src={item?.reportage?.news?.image || "/assets/images/reportageImage.png"} alt="" />
                                                        </div>
                                                        <div className="reportageCardHeaderDetail">
                                                            <div>
                                                                <div className="siteTempTitle reportageCardHeaderTitle text-truncate">{item?.reportage?.title}</div>
                                                                <a href={item?.reportage?.news?.site} target="_blank" rel="noopener noreferrer">
                                                                    <div className="siteTempSubtitle reportageCardHeaderCategory text-truncate">{handleGenerateLink(item?.reportage?.news?.site) || ""}</div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex align-items-center gap-1 ">
                                                        <div className="reportageCardCurrentPrice">{separateNumberWithComma(((item?.reportage?.price) * (100 - item?.discount) / 100))}</div>
                                                        <div className="reportageCardUnit">تومان</div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    >
                                        <ReportageDetailModal
                                            reportageData={item?.reportage}
                                            hidePrice
                                            hideReportageHeader
                                        />
                                    </SiteAccordion>
                                </>
                            )}
                        </div>


                    </>
                }


            </SiteModal>

            {(ListData?.meta?.total > 0) &&
                <>
                    <div className="specialReportageMainBox">
                        <Swiper
                            modules={[Navigation,Pagination]}
                            navigation={{
                                prevEl : ".packageSliderNavigation.prev",
                                nextEl : ".packageSliderNavigation.next"
                            }}
                            pagination={{
                                el : ".packageSliderPagination"
                            }}
                            spaceBetween={10}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1
                                },
                                1206: {
                                    slidesPerView: 2
                                },
                            }}
                            className='pt-3'
                        >
                            {ListData?.data?.map((item, index) =>
                                <SwiperSlide>
                                    <div className="specialReportageCard">
                                        <div className="specialReportageCardHeader">
                                            <div className="specialReportageCardImage">
                                                {/* <img src="/assets/images/pomegranate.png" alt="" /> */}
                                                <img src={item?.image} alt="" />
                                            </div>
                                            <div className="specialReportageCardAgencyCount">
                                                {item?.reportages?.length || 0} رسانه
                                            </div>
                                            <div className="specialReportageCardAgencyBox">
                                                {item?.reportages?.map((item, index) =>
                                                    <div className="specialReportageCardAgencyCard">
                                                        {/* <img src="/assets/images/onlineNews.png" alt="" /> */}
                                                        <img src={item?.reportage?.news?.image} alt="" />
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="specialReportageCardBody">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="specialReportageCardTitle">
                                                    {item?.name}
                                                </div>

                                                <div className="specialReportageCardDiscount">
                                                    {item?.discount}%
                                                </div>

                                            </div>

                                            <div className="d-flex align-items-center justify-content-between specialReportageCardFooter">
                                                <div className="d-flex align-items-center justify-content-between gap-2">
                                                    <LoadingButton
                                                        onClick={() => handleAddToCart(item)}
                                                        loading={LoadingAddToCart}
                                                    >
                                                        اضافه به سبد خرید
                                                    </LoadingButton>
                                                    <div className="siteTempBtn designSelectBtn"
                                                        onClick={() => setShowReportageModal({ show: true, data: item, selectedId: item?.id })}
                                                    >
                                                        جزئیات بسته
                                                    </div>
                                                </div>

                                                <div className="reportageCardPriceBox">
                                                    <div className="reportageCardprice me-auto">
                                                        <div className="reportageCardRealPrice">
                                                            {(() => {
                                                                // let basePrice = 0;
                                                                // for (let i = 0; i < item?.reportages?.length; i++) {
                                                                //     const element = item?.reportages[i];
                                                                //     basePrice += parseInt(element?.reportage?.price)
                                                                // }

                                                                return separateNumberWithComma(item?.price)
                                                            })()}
                                                        </div>

                                                        <div className="reportageCardCurrentPrice">
                                                            {(() => {
                                                                // let basePrice = 0;
                                                                // for (let i = 0; i < item?.reportages?.length; i++) {
                                                                //     const element = item?.reportages[i];
                                                                //     basePrice += element?.reportage?.price
                                                                // }

                                                                // return separateNumberWithComma(parseInt(((basePrice * parseFloat(100 - item?.discount)) || 0) / 100))
                                                                return separateNumberWithComma(item?.final_price)
                                                            })()}
                                                        </div>
                                                        <div className="reportageCardUnit">تومان</div>
                                                    </div>
                                                </div>


                                            </div>

                                        </div>

                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>

                    <div className={"d-flex align-items-center justify-content-between " + navClassName}>
                        <div className="packageSliderNavigation prev">
                            <img src="/assets/images/chevron-down.svg" alt="" />
                        </div>
                        <div className="packageSliderPagination"></div>
                        <div className="packageSliderNavigation next">
                            <img src="/assets/images/chevron-down.svg" alt="" />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ReportagePackages;