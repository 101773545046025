import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes";
import InputTemplate from "../../components/formComponents/inputComponent";
import LoadingButton from "../../components/loadingButton";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSingleTicket, sendNewMessageInTicket } from "../../service/ticket";
import { setCurrentPageTitle_action } from "../../store/action/app_action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import jMoment from "moment-jalaali";
import { getFileTypeByUrl } from "../../utilities";

const ShowTicketPage = () => {

    const { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [UserChatInput, setUserChatInput] = useState({
        text: "",
        file: null,
    })

    const [LoadingSendMessage, setLoadingSendMessage] = useState(false);

    const [Messages, setMessages] = useState({
        loading: true,
        data: {}
    })

    const handleSendMessage = () => {
        // if (UserChatInput.text.trim() === "" && UserChatInput.file === null) {
        if (UserChatInput.text.trim() === "") {
            return
        }

        const formData = new FormData();
        formData.append("message", UserChatInput.text)
        formData.append("_method", "put")

        let sendData = {
            message: UserChatInput.text
        }

        setLoadingSendMessage(true)

        sendNewMessageInTicket(id, formData).then(({ data }) => {
            setUserChatInput({
                text: "",
                file: null,
            })
            setLoadingSendMessage(false);

            let helpData = { ...Messages };

            helpData.data?.messages?.push({
                is_admin: 0,
                message: sendData.message,
                created_at: new Date(),
            })

            setMessages({
                loading: false,
                data: helpData.data
            })

            // setMessages({
            //     loading: false,
            //     data
            // })
        }).catch((err) => {
            setLoadingSendMessage(false);
            console.log(err)
        })

        // setTimeout(() => {
        //     setLoadingSendMessage(false);
        //     toast.success("پیام شما با موفقیت ثبت شد")
        // }, 2000);
    }

    const handleSendFile = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0])
        formData.append("message", "")
        formData.append("_method", "put")
        setLoadingSendMessage(true)

        sendNewMessageInTicket(id, formData).then(({ data }) => {
            setUserChatInput({
                text: "",
                file: null,
            })
            setLoadingSendMessage(false);

            let helpData = { ...Messages };

            // helpData.data?.messages?.push({
            //     is_admin: 0,
            //     message: formData.message,
            //     created_at: new Date(),
            // })

            // setMessages({
            //     loading: false,
            //     data: helpData.data
            // })

            handleGetMessages()

            // setMessages({
            //     loading: false,
            //     data
            // })
        }).catch((err) => {
            setLoadingSendMessage(false);
            console.log(err)
        })
    }

    const handleGetMessages = () => {
        getSingleTicket(id).then(({ data }) => {
            setMessages({
                loading: false,
                data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSendMessageByEnter = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    }


    useEffect(() => {
        handleGetMessages();
    }, [id])

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("نمایش تیکت"))
    }, [])


    return (
        <>

            <Helmet>
                <title>
                    نمایش تیکت
                </title>
            </Helmet>

            <div className="customBox showTicketMainBox">
                <div className="showTicketHeaderBox">
                    <div className="showTicketHeaderTitle">
                        <div>موضوع تیکت</div>
                        <div className="siteTempTitle">{Messages.data?.title?.title}</div>
                    </div>

                    <div
                        className="showTicketHeaderBackBox"
                        onClick={() => navigate(routes.ticket.list)}
                    >
                        <div className="siteTempTitle">بازگشت به لیست تیکت ها</div>
                        <img src="/assets/images/chevron-down.svg" alt="" />
                    </div>

                </div>
                <div className="showTicketChatMainBox">

                    {Messages.data?.messages?.map((message, index) =>

                        <>

                            <div className={"showTicketChatCard" + (message.is_admin === 1 ? " admin" : "")}>
                                <div className="showTicketChatCardImage">
                                    {message.is_admin === 1 ?

                                        <img src="/assets/images/logo.svg" alt="" />

                                        :
                                        <img src="/assets/images/user-octagon.svg" alt="" />
                                    }
                                </div>
                                <div className="showTicketChatCardBody">


                                    <div className="showTicketChatCardContent">
                                        {message?.file &&
                                            <>
                                                {getFileTypeByUrl(message?.file) === "image" ?
                                                    <>
                                                        <div className="showTicketChatCardContentImage">
                                                            <img src={message?.file} alt="" className="w-full" />
                                                        </div>
                                                    </>
                                                :
                                                    <>
                                                        <a href={message?.file} target="_blank" download className="showTicketChatCardContentDoc" rel="noreferrer">
                                                            <div className="showTicketChatCardContentDocIcon">
                                                                <img src="/assets/images/document-download.svg" alt="" />
                                                            </div>
                                                            <div className="showTicketChatCardContentDocTitle">
                                                                {message?.file?.split("/tickets/")[1]}
                                                            </div>
                                                            {/* <div className="showTicketChatCardContentDocDonwnload">
                                                                <img src="/assets/images/document-download.svg" alt="" />

                                                            </div> */}
                                                        </a>
                                                    </>
                                                }
                                            </>
                                        }
                                        <div className="showTicketChatCardContentText">
                                            {message?.message}
                                        </div>
                                    </div>
                                    <div className="showTicketChatCardFooter">
                                        {message.is_admin === 1 &&

                                            <div className="text-primaryColor" style={{
                                                marginInlineStart: 10
                                            }}>
                                                {Messages?.data?.type?.title}
                                            </div>

                                        }
                                        <div className="text-gray showTicketChatCardDate">
                                            {jMoment(message?.created_at).format("jD jMMMM | HH:mm")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>

                    )}


                    {/* <div className="showTicketChatCard admin">
                        <div className="showTicketChatCardImage">
                            <img src="/assets/images/logo.svg" alt="" />
                        </div>
                        <div className="showTicketChatCardBody">
                            <div className="showTicketChatCardContent">
                                با سلام و احترام<br />
                                وقت بخیر<br />
                                عذرخواهی ما را بابت تاخیر در روند بررسی رپورتاژ پذیرا باشید.<br />
                                مجدد از ناشر پیگیری خواهد شد تا رپورتاژ شما در اسرع وقت بررسی و در صورت تایید منتشر شود.<br />
                                سپاس از شکیبایی شما
                            </div>
                            <div className="showTicketChatCardFooter">
                                <div className="text-gray showTicketChatCardDate">
                                    12 اردیبهشت |  22:05
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

                {(Messages.data?.status !== "بسته شده") ?
                    <>
                        <div className="showTicketFooterMainBox">
                            <InputTemplate
                                placeholder={"پیام خود را بنویسید"}
                                value={UserChatInput.text}
                                onChange={(e) => setUserChatInput({ ...UserChatInput, text: e.target.value })}
                                onKeyPress={handleSendMessageByEnter}
                            />
                            <div className="d-flex gap-2">
                                <label>
                                    <div className="siteTempBtn design4 attachFileInChatBtn"
                                        style={{
                                            paddingTop: 9,
                                            paddingBottom: 9,
                                        }}
                                    >
                                        <div className="text-gray2">پیوست فایل</div>
                                        <img src="/assets/images/paper-plus.svg" alt="" />
                                    </div>
                                    <input type="file" hidden onChange={e => handleSendFile(e)} />
                                </label>
                                <LoadingButton
                                    loading={LoadingSendMessage}
                                    style={{
                                        padding: "9px 35px",
                                    }}
                                    onClick={handleSendMessage}
                                >
                                    ارسال پیام
                                </LoadingButton>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="showTicketClosedText">
                            این تیکت بسته شده است
                        </div>
                    </>
                }
            </div>
        </>
    );
}

export default ShowTicketPage;