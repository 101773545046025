const LoadingButton = ({loading , className , children , onClick , ...other}) => {
    return ( 
        <>
            <div 
                className={"siteTempBtn half-radius" + (className ? " "+className : "")}
                disabled={loading}
                onClick={loading ? null : onClick }
                {...other}
            >
                {loading ?
                    <>
                        صبرکنید ...
                    </>
                :
                    <>
                        {children}
                    </>
                }
            </div>
        </>
    );
}
 
export default LoadingButton;