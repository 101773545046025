
const initialState = {
    id : -1 ,
    carts : {},
    status : "",
    loading : false,
};

export const backlinkCartReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'INIT_BACKLINK_CART': {
            return {
                ...action.payload
            }
        }

        case 'EMPTY_BACKLINK_CART': {
            return {
                ...state,
                carts:{}
            }
        }

        case 'SET_BACKLINK_CART': {
            return {
                ...state,
                carts : {...action.payload},
                loading : false,
            }
        }

        case 'SET_BACKLINK_LOADING': {
            return {
                ...state,
                loading : action.payload,
            }
        }

        default:
            return state
    }
}