import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import InputTemplate from "../../../components/formComponents/inputComponent";
import LoadingButton from "../../../components/loadingButton";
import { separateNumberWithComma } from "../../../utilities";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import { depositWalletOnline } from "../../../service/financial";
import { toast } from "react-toastify";
import { NUM_REGEX } from "../../../utilities/config";

const OnlinePayment = () => {
  const dispatch = useDispatch();

  const [AmountInput, setAmountInput] = useState("")
  const [DepositLoading, setDepositLoading] = useState(false)

  const handleSubmit = () => {
    if (AmountInput === "") {
      toast.error("لطفا مبلغ اعتبار را وارد کنید!")
      return
    }

    const sendData = {
      amount: AmountInput
    }

    setDepositLoading(true)
    depositWalletOnline(sendData).then(({ data }) => {
      if (data?.url) {
        window.location.href = data?.url
      } else {
        toast.error("در پرداخت مشکلی پیش آمده است")
      }
      setDepositLoading(false)
    }).catch(err => {
      console.log(err)
      setDepositLoading(false)
    })


  }

  useEffect(() => {
    dispatch(setCurrentPageTitle_action("افزایش اعتبار آنلاین"));
  }, []);

  return (

    <>
      <Helmet>
        <title>
          افزایش اعتبار آنلاین
        </title>
      </Helmet>

      <div className="customBox">
        <div className="financialMainBox">
          {/* Checking Information Alert! */}
          {/* <div className="onlinePaymentInfoAlertSection">
            <div className="infoAlertLogo">
              //  color in figma is #2ECC71  
              <img src="/assets/images/document2.svg" alt="document" />
            </div>
            <p className="infoAlertText">
              فاکتور رسمی شما با اطلاعاتی که در قسمت پروفایل پرکرده بودید صادر
              خواهد شد، در صورت نیاز به ویرایش اطلاعات در قسمت
              <span className="infoAlertHighlight">مشاهده اطلاعات</span> اصلاح
              بفرمایید.
            </p>
          </div> */}
          {/* payment input */}
          <div>
            <p className="siteTempLabelTitle">مبلغ اعتبار</p>
            <div className="receiptSubmissionCrediteAmountInput">
              <InputTemplate
                type="text"
                placeholder="مبلغ خود را وارد کنید"
                value={separateNumberWithComma(AmountInput)}
                onChange={e => setAmountInput(e.target.value?.replace(NUM_REGEX, ''))}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <div className="currency">تومان</div>
            </div>
          </div>
          {/* suggestions */}
          <div className="receiptSubmissionSuggestionAmount">
            <div>
              <p className="receiptSubmissionSuggestionAmountText">پیشنهادی</p>
            </div>
            <div className="receiptSubmissionSuggestionBtns">
              <div className="siteTempBtn designSelectBtn"
                onClick={() => setAmountInput(500000)}
              >{separateNumberWithComma(500000)} تومان</div>
              <div className="siteTempBtn designSelectBtn"
                onClick={() => setAmountInput(1500000)}
              >
                {/* active */}
                {separateNumberWithComma(1500000)} تومان
              </div>
              <div className="siteTempBtn designSelectBtn"
                onClick={() => setAmountInput(4000000)}
              >{separateNumberWithComma(4000000)} تومان</div>
            </div>
          </div>
          {/* Including VAT */}
          <div className="onlinePaymentVATCalculationSection">
            {/* <div className="onlinePaymentVATCalculationSubSection">
              <div className="item">مبلغ</div>
              <div className="item dots"></div>
              <div className="item item-end">
                <span className="amount">{separateNumberWithComma(AmountInput)}</span>{" "}
                تومان
              </div>
            </div>
            <div className="onlinePaymentVATCalculationSubSection">
              <div className="item">9% مالیات بر ارزش افزوده</div>

              <div className="item dots"></div>
              <div className="item item-end">
                <span className="amount">{separateNumberWithComma(AmountInput)}</span>{" "}
                تومان
              </div>
            </div> */}
            <div className="onlinePaymentVATCalculationSubSection">
              <div className="item">مبلغ قابل پرداخت</div>
              <div className="item dots"></div>
              <div className="item item-end">
                <span className="amount">{separateNumberWithComma(AmountInput || 0)}</span>{" "}
                تومان
              </div>
            </div>
          </div>
          {/* payment submit button */}
          <div className="onlinePaymentSubmitBtnSection">
            <LoadingButton
              className="totalPaymentBtn"
              loading={DepositLoading}
              onClick={handleSubmit}
            >
              <div>پرداخت</div>
              <img
                src="/assets/images/arrow-left-square.svg"
                alt="arrow-left-square"
              />
            </LoadingButton>
            {/* <div className="siteTempBtn totalPaymentBtn">
            </div> */}
          </div>
        </div>
      </div>
    </>

  );
};

export default OnlinePayment;
