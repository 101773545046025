import { customAxios } from "./axiosSetup";

export const getAllFinancialReport = (params) => customAxios.get(`/client/financial` , {params});

export const depositWalletOnline = (data) => customAxios.post(`/wallet` , data);

export const checkDepositWalletOnline = (transactionId) => customAxios.put(`/wallet/${transactionId}`);


export const getAllReceiptReport = (params) => customAxios.get(`/client/receipt` , {params});

export const sendReceiptReport = (data) => customAxios.post(`/client/receipt` , data , {
    headers: { "Content-Type": "multipart/form-data" }
});