import { Modal, ModalDialog } from "react-bootstrap";
import PropTypes from 'prop-types';


const SiteModal = ({ children, modalClass = "", showModal, contentClassName = "", dialogClassname = "", closeModal = () => null , modalDesign = 1 , title , withCloseLogo = false }) => {
    return (
        <>
            <Modal
                className={"siteTempModal" + (modalClass !== "" ? " " + modalClass : modalClass) + (modalDesign === 2 ? " modalDesign2" : "")+ (modalDesign === 3 ? " modalDesign3" : "")}
                contentClassName={contentClassName}
                dialogClassName={dialogClassname}
                show={showModal}
                centered={true}
                onBackdropClick={closeModal}
                onHide={closeModal}
            >


                {withCloseLogo &&
                
                    <div className="closeModalTempBtn" onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <path d="M25.9997 25.6773L3.21484 3" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.7848 3.32263L3 25.9999" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                }

                {/* <ModalDialog> */}
                    {modalDesign === 2 &&

                        <>
                            <div className="d-flex align-items-center justify-content-between mb-3">

                                <div className="siteTempModalTitle">{title}</div>

                                <div className="closeModalTempBtn" onClick={closeModal}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                        <path d="M25.9997 25.6773L3.21484 3" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M25.7848 3.32263L3 25.9999" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <div>بازگشت</div>

                                </div>

                            </div>
                        </>
                    
                    }


                    {children}
                {/* </ModalDialog> */}
                
            </Modal>
        </>
    );
}


// SiteModal.prototype = {
//     children : PropTypes.node,
//     modalClass : PropTypes.string,
//     contentClassName : PropTypes.string,
//     dialogClassname : PropTypes.string,
//     title : PropTypes.string,
//     showModal : PropTypes.bool,
//     closeModal : PropTypes.func,
//     modalDesign : PropTypes.oneOf([1 , 2]),
// }

export default SiteModal;