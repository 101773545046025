import { customAxios } from "./axiosSetup";

export const loginUser = (data) => customAxios.post("/login" , data);

export const registerUser = (data) => customAxios.post("/register" , data);

export const verifyUser = (data) => customAxios.post("/verify" , data);

export const getUserData = () => customAxios.get("/get_profile");

export const updateUserData = (data) => customAxios.post("/get_profile" , data);

export const sendCode = (data) => customAxios.post("/send_code" , data);

export const checkCode = (data) => customAxios.post("/check_code" , data);

export const getFinanceUserData = () => customAxios.get("/client_info/1");

export const createFinanceUserData = (data) => customAxios.post(`/client_info` , data);

export const updateFinanceUserData = (id , data) => customAxios.put(`/client_info/${id}` , data);

export const getAllUserNotifications = () => customAxios.get(`/notification`);