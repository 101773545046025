import { useEffect, useState } from "react";
import InputTemplate from "../../../components/formComponents/inputComponent";
import TitleWithCircle from "../../../components/title";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet-async";
import { createFinanceUserData, getFinanceUserData, updateFinanceUserData } from "../../../service/auth";
import LoadingButton from "../../../components/loadingButton";
import { toast } from "react-toastify";

const FinancialProfilePage = () => {

    const dispatch = useDispatch();

    const [TabIndex, setTabIndex] = useState(0)

    const [UserFinancialData, setUserFinancialData] = useState({})
    const [FormItemsData, setFormItemsData] = useState({
        codeMeli: "",
        name: "",
        registrationNumber: "",
        economicalNumber: "",
        province: "",
        city: "",
        postalCode: "",
        cityCode: "",
        phone: "",
        address: "",
    })
    const [IsSubmitting, setIsSubmitting] = useState(false)


    const handleGetUserFinancialData = () => {
        getFinanceUserData().then(({ data }) => {
            setUserFinancialData(data);
            setTabIndex(data?.is_haghighi == 1 ? 0 : 1)
            setFormItemsData({
                codeMeli: data?.code_melli,
                name: data?.name,
                registrationNumber: data?.registrationـnumber,
                economicalNumber: data?.economicalـnumber,
                province: data?.province,
                city: data?.city,
                postalCode: data?.postal_code,
                cityCode: data?.city_code,
                phone: data?.phone,
                address: data?.address,
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleCheckForm = () => {

        if (FormItemsData?.codeMeli?.toString()?.trim() === "") {
            toast.error("کد ملی اجباری است!")
            return false
        }

        if (FormItemsData?.name?.toString()?.trim() === "") {
            toast.error("نام اجباری است!")
            return false
        }

        if (TabIndex === 1) {
            if (FormItemsData?.registrationNumber?.toString()?.trim() === "" && !FormItemsData?.registrationNumber) {
                toast.error("شماره ثبت اجباری است!")
                return false
            }

            if (FormItemsData?.economicalNumber?.toString()?.trim() === "" && !FormItemsData?.economicalNumber) {
                toast.error("شماره اقتصادی اجباری است!")
                return false
            }
        }

        if (FormItemsData?.province?.toString()?.trim() === "") {
            toast.error("استان اجباری است!")
            return false
        }

        if (FormItemsData?.city?.toString()?.trim() === "") {
            toast.error("شهر اجباری است!")
            return false
        }

        if (FormItemsData?.postalCode?.toString()?.trim() === "") {
            toast.error("کدپستی اجباری است!")
            return false
        }

        if (FormItemsData?.cityCode?.toString()?.trim() === "") {
            toast.error("کد شهر اجباری است!")
            return false
        }

        if (FormItemsData?.phone?.toString()?.trim() === "") {
            toast.error("شماره تلفن اجباری است!")
            return false
        }

        if (FormItemsData?.address?.toString()?.trim() === "") {
            toast.error("آدرس اجباری است!")
            return false
        }



        return true
    }

    const handleCreateUserFinancialData = () => {

        if (!handleCheckForm()) {
            return
        }

        let sendData = {
            is_haghighi: TabIndex === 0 ? 1 : 0,
            code_melli: FormItemsData?.codeMeli,
            name: FormItemsData?.name,
            province: FormItemsData?.province,
            city: FormItemsData?.city,
            postal_code: FormItemsData?.postalCode,
            city_code: FormItemsData?.cityCode,
            phone: FormItemsData?.phone,
            address: FormItemsData?.address,
        }

        if (TabIndex === 1) {
            sendData.registrationـnumber = FormItemsData?.registrationNumber;
            sendData.economicalـnumber = FormItemsData?.economicalNumber;
        }

        setIsSubmitting(true);

        createFinanceUserData(sendData).then(({ data }) => {
            // setUserFinancialData(data);
            handleGetUserFinancialData();
            setIsSubmitting(false);
        }).catch((err) => {
            console.log(err)
            setIsSubmitting(false);
        })
    }

    const handleUpdateUserFinancialData = () => {

        if (!handleCheckForm()) {
            return
        }

        let sendData = {
            is_haghighi: TabIndex === 0 ? 1 : 0,
            code_melli: FormItemsData?.codeMeli,
            name: FormItemsData?.name,
            province: FormItemsData?.province,
            city: FormItemsData?.city,
            postal_code: FormItemsData?.postalCode,
            city_code: FormItemsData?.cityCode,
            phone: FormItemsData?.phone,
            address: FormItemsData?.address,
        }

        if (TabIndex === 1) {
            sendData.registrationـnumber = FormItemsData?.registrationNumber;
            sendData.economicalـnumber = FormItemsData?.economicalNumber;
        }

        setIsSubmitting(true);

        updateFinanceUserData(UserFinancialData?.id, sendData).then(({ data }) => {
            // setUserFinancialData(data);
            handleGetUserFinancialData();
            setIsSubmitting(false);
        }).catch((err) => {
            console.log(err)
            setIsSubmitting(false);
        })
    }

    useEffect(() => {
        handleGetUserFinancialData();
        dispatch(setCurrentPageTitle_action("تکمیل اطلاعات"));
    }, []);
    return (
        <>
            <Helmet>
                <title>
                    تکمیل اطلاعات
                </title>
            </Helmet>
            <div className="customBox">
                <div className="d-flex gap-2">
                    <TitleWithCircle
                        className={"align-items-center"}
                        title={"اطلاعات مالی"}
                        withBorder
                    />
                </div>

                <div className="mt-5">

                    <div className="d-flex justify-content-center gap-2">
                        <div className={"siteTempBtn blueBg outline " + (TabIndex === 0 ? " active" : "")} onClick={() => setTabIndex(0)}>شخص حقیقی</div>
                        <div className={"siteTempBtn blueBg outline " + (TabIndex === 1 ? " active" : "")} onClick={() => setTabIndex(1)}>شخص حقوقی</div>
                    </div>

                    <div className="d-flex gap-2 mt-4 profileFinancialBox">
                        <InputTemplate
                            placeholder={"کد ملی"}
                            title={"کد ملی"}
                            value={FormItemsData?.codeMeli}
                            onChange={e => setFormItemsData({ ...FormItemsData, codeMeli: e?.target?.value })}
                        />
                        <InputTemplate
                            placeholder={"نام و نام خانوادگی "}
                            title={"نام و نام خانوادگی "}
                            value={FormItemsData?.name}
                            onChange={e => setFormItemsData({ ...FormItemsData, name: e?.target?.value })}
                        />
                    </div>

                    {TabIndex === 1 &&
                        <>
                            <div className="d-flex gap-2 mt-2 profileFinancialBox">
                                <InputTemplate
                                    placeholder={"شماره ثبت"}
                                    title={"شماره ثبت"}
                                    value={FormItemsData?.registrationNumber}
                                    onChange={e => setFormItemsData({ ...FormItemsData, registrationNumber: e?.target?.value })}
                                />
                                <InputTemplate
                                    placeholder={"شماره اقتصادی"}
                                    title={"شماره اقتصادی"}
                                    value={FormItemsData?.economicalNumber}
                                    onChange={e => setFormItemsData({ ...FormItemsData, economicalNumber: e?.target?.value })}
                                />
                            </div>
                        </>
                    }


                </div>

                <div className="d-flex gap-2 mt-4">
                    <TitleWithCircle
                        className={"align-items-center"}
                        title={"اطلاعات تماس"}
                        withBorder
                    />
                </div>
                <div className="d-flex gap-2 mt-4 profileFinancialBox">
                    <InputTemplate
                        placeholder={"استان"}
                        title={"استان"}
                        value={FormItemsData?.province}
                        onChange={e => setFormItemsData({ ...FormItemsData, province: e?.target?.value })}
                    />
                    <InputTemplate
                        placeholder={"شهر"}
                        title={"شهر"}
                        value={FormItemsData?.city}
                        onChange={e => setFormItemsData({ ...FormItemsData, city: e?.target?.value })}
                    />
                </div>
                <div className="d-flex gap-2 mt-3 profileFinancialBox">
                    <InputTemplate
                        placeholder={"کد پستی"}
                        title={"کد پستی"}
                        value={FormItemsData?.postalCode}
                        onChange={e => setFormItemsData({ ...FormItemsData, postalCode: e?.target?.value })}
                    />
                    <div className="d-flex gap-2 w-100">
                        <InputTemplate
                            placeholder={"شماره تلفن ثابت"}
                            title={"شماره تلفن ثابت"}
                            value={FormItemsData?.phone}
                            onChange={e => setFormItemsData({ ...FormItemsData, phone: e?.target?.value })}
                        />
                        <InputTemplate
                            placeholder={"کد شهر"}
                            title={"کد شهر"}
                            parentClassName={"cityCodeInputBox"}
                            value={FormItemsData?.cityCode}
                            onChange={e => setFormItemsData({ ...FormItemsData, cityCode: e?.target?.value })}
                        />

                    </div>
                </div>
                <div className="d-flex gap-2 mt-3 profileFinancialBox">
                    <InputTemplate
                        placeholder={"آدرس دقیق"}
                        title={"آدرس دقیق"}
                        value={FormItemsData?.address}
                        onChange={e => setFormItemsData({ ...FormItemsData, address: e?.target?.value })}
                    />
                </div>
                <div className="d-flex mt-4 justify-content-end profileFinancialBox">
                    <LoadingButton
                        loading={IsSubmitting}
                        onClick={UserFinancialData?.id ? handleUpdateUserFinancialData : handleCreateUserFinancialData}
                        className={"justify-content-center"}
                        style={{
                            width: 160
                        }}
                    >
                        {UserFinancialData?.id ? "ثبت درخواست ویرایش" : "ثبت درخواست"}
                    </LoadingButton>
                </div>
            </div>

        </>
    );
}

export default FinancialProfilePage;