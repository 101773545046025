import { Outlet } from "react-router-dom";

const SimpleLayout = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default SimpleLayout;