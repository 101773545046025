/* 
    // Table Head Object Template
    {
        title : "",
        id : "",
        className : "",
    },
*/

import SitePagination from "../pagination";

const TableComponent = ({
    tableHead = [],
    TableBody = [],
    tableData = {},
    totalItems,
    page = 1 ,
    setpage = () => null,
    perPage = 10,
    children,
}) => {
    return (
        <>
            <table className="siteTempTable design1">
                <thead>
                    <tr>
                        {tableHead.map((head) => 
                            <>
                                <th className={head.className}>{head?.title}</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>

            {(totalItems && totalItems > 0) &&
            
                <div className="d-flex align-items-center justify-content-between">
                    <div></div>
                    <SitePagination 
                        currentPage={page}
                        setCurrentPage={setpage}
                        totalItems={totalItems}
                        perPage={perPage}
                        showNav={true}
                    />
                            
                </div>
            
            }



        </>
    );
}

export default TableComponent;