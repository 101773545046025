import Select, { components } from 'react-select';
import jMoment from "moment-jalaali";
import { perPageArr, reportageStatusArray } from "../../../utilities/config";
import { customSelectStyle, perPageControl } from "../../../components/reactSelectComponens";
import ReportStatus from '../../reportStatus';
import { useEffect, useRef, useState } from 'react';
import SitePagination from '../../pagination';
import { LoadingData } from '../../loading';
import NoDataComponent from '../../noData';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes';
import { separateNumberWithComma } from '../../../utilities';

const CampaignListTableRow = ({
    campaign = {}
}) => {

    const campaignRowRef = useRef(null);

    const SHOW_EDIT_REPORTAGE = ["در انتظار ایجاد", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(false);

    function handleClickOutside(event) {
        if (campaignRowRef.current && !campaignRowRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
    }

    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMoreOption]);

    return (
        <>
            <tr className="position-relative" ref={campaignRowRef}>
                <td>
                    <div className="reportageCodeBox">
                        <span>{campaign?.id}</span>
                    </div>
                </td>
                <td>
                    <div className="d-flex">
                        <div>{campaign?.name || "بدون عنوان"}</div>
                    </div>
                </td>
                <td className="text-center">
                    <div className="campaignSitesMainBox">
                        {Array.isArray(campaign?.websites) &&
                            <>
                                {campaign?.websites?.map((site, index) =>
                                    <>
                                        {site}{index + 1 !== campaign?.websites?.length && " , "}
                                    </>
                                )}
                            </>
                        }
                        {/* <div className="campaignSitesBox">
                                                        <div>سایت</div>
                                                        <img src="/assets/images/info-octagon.svg" alt="" />
                                                    </div> */}
                    </div>
                </td>
                <td className="text-center">
                    <span>
                        {(() => {
                            let sum = 0;
                            if (campaign?.price) {
                                sum = campaign?.price;
                            } else {
                                for (let i = 0; i < campaign?.backlink.length; i++) {
                                    const element = campaign?.backlink[i]?.backlink_price;
                                    sum += element?.final_price
                                }
                            }
                            return separateNumberWithComma(sum)
                        })()}
                    </span>
                    <span> تومان</span>
                </td>
                <td className="text-center">
                    <ReportStatus reportStatus={campaign?.status_name} withBox={true} />
                </td>
                {/* <td className="text-center">
                    {jMoment(campaign?.created_at).format("jD jMMMM jYYYY")}
                </td> */}
                <td>
                    <div className="customFlexBox justify-content-end gap-2">

                        {SHOW_EDIT_REPORTAGE.indexOf(campaign?.status_name) > -1 &&

                            <>
                                <Link to={routes.backLink.create(campaign?.id)}>

                                    <div className="siteTempBtn tableBtn">
                                        <img src="/assets/images/document-upload.svg" alt="" />
                                        <div>آپلود محتوا</div>
                                    </div>
                                </Link>
                            </>

                        }
                        {["در انتظار پرداخت", "پرداخت نشده"].indexOf(campaign?.status_name) > -1 &&

                            <>
                                <div className="siteTempBtn tableBtn greenBg">
                                    <img src="/assets/images/money-send.svg" alt="" />
                                    <div>پرداخت</div>
                                </div>
                            </>

                        }
                        {["منتشر شده", "رد شده", "در حال بررسی", "لغو شده"].indexOf(campaign?.status_name) > -1 &&

                            <>
                                <Link to={routes.backLink.reportageOfCampaigns(campaign?.id)}>
                                    <div className="siteTempBtn tableBtn blueBg outline">
                                        <img src="/assets/images/eye.svg" alt="" />
                                        <div>رپورتاژ ها</div>
                                    </div>
                                </Link>
                            </>

                        }

                        <div className="siteTempBtn design1 _3dotBtn width-fit"
                            onClick={() => setShowMoreOption(!ShowMoreOption)}
                        >
                            <img src="/assets/images/3dot.svg" alt="" />
                        </div>
                        <div className={"table3dotMainBox" + (ShowMoreOption ? " show" : "")}>
                            {["در انتظار پرداخت", "پرداخت نشده"].indexOf(campaign?.status_name) > -1 &&
                                <>
                                    <div className="table3dotCard">
                                        <img src="/assets/images/edit1.svg" alt="" />
                                        <div>ویرایش کمپین</div>
                                    </div>
                                    <div className="table3dotCard">
                                        <img src="/assets/images/delete.svg" alt="" />
                                        <div>حذف کمپین</div>
                                    </div>

                                </>
                            }
                            {["منتشر شده", "رد شده", "در حال بررسی", "لغو شده"].indexOf(campaign?.status_name) > -1 &&
                                <>
                                    <Link to={routes.backLink.reportageOfCampaigns(campaign?.id)}>
                                        <div className="table3dotCard">
                                            <img src="/assets/images/shield-search.svg" alt="" />
                                            <div>رپورتاژ ها</div>
                                        </div>
                                    </Link>

                                </>
                            }
                            {["در انتظار ایجاد", "منتشر شده", "لغو شده"].indexOf(campaign?.status_name) > -1 &&
                                <>
                                    <div className="table3dotCard">
                                        <img src="/assets/images/receipt-text.svg" alt="" />
                                        <div>فاکتور کمپین</div>
                                    </div>

                                </>
                            }

                        </div>

                    </div>
                </td>
            </tr>
        </>
    )
}

const BacklinkCampaignListTable = ({
    tableData,
    loading = false,
    showFooter = true,
    currentPage = 1,
    setCurrentPage = () => null,
    perPage = 12,
    setPerPage = () => null,
}) => {

    const SHOW_EDIT_REPORTAGE = ["در انتظار ایجاد", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const handleShowMoreButton = (index) => {
        if (index === ShowMoreOption) {
            setShowMoreOption(-1)
        } else {
            setShowMoreOption(index)
        }
    }

    return (
        <>
            <div className="siteTempTableBox">
                <table className="siteTempTable">
                    <tr>
                        <th>کد کمپین</th>
                        <th>عنوان کمپین</th>
                        <th className="text-center">وبسایت ها</th>
                        <th className="text-center">مبلغ</th>
                        <th className="text-center">وضعیت</th>
                        <th className="text-center">اقدامات</th>
                    </tr>
                    {/* {ReportageData.data?.data?.map((reportage, index) => */}
                    {!loading &&
                        <>
                            {tableData?.data?.map((campaign, index) =>

                                <>
                                    <CampaignListTableRow
                                        key={`campaignRow-${index}`}
                                        campaign={campaign}
                                    />
                                </>

                            )}
                        </>
                    }



                </table>

                {loading &&
                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                }
                {(tableData?.data?.length === 0 && loading === false) &&
                    <>
                        <NoDataComponent />
                    </>
                }

            </div>

            {showFooter &&
                <>
                    <div className="siteTempTableFooter mt-3">


                        <Select
                            defaultValue={perPageArr[0]}
                            options={perPageArr}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            components={{
                                Control: perPageControl
                            }}
                            styles={{
                                ...customSelectStyle,
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                    borderRadius: 8,
                                    fontSize: 13,
                                    color: "#7E8692",
                                    boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                    padding: "7px 12px",
                                })
                            }}
                            onChange={(newValue) => setPerPage(newValue.value)}
                        />

                        <SitePagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalItems={tableData?.meta?.total || 0}
                            perPage={perPage}
                        />
                    </div>
                </>
            }

        </>
    );
}

export default BacklinkCampaignListTable;