import { useState } from "react";

const InputTemplate = ({onChange , value , type = "text" , title , placeholder , parentClassName , inputClassName , ...othre}) => {

    const [ShowPassword, setShowPassword] = useState(false)

    return (
        <div className={"siteTempInputBox" + (parentClassName ? " "+ parentClassName : "")}>
            

            <label>

                {title &&
                    <div className="siteTempInputLabel">{title}</div>
                }

                <div className="position-relative">
                    <input 
                        type={ShowPassword ? "text" : type}
                        className={"siteTempInput" + (inputClassName ? " " + inputClassName : "")}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder || `${title} خود را وارد کنید`}
                        {...othre}
                    />

                    {type === "password" &&
                        <>
                            <div className="inputPasswordIconShow" onClick={() => setShowPassword(!ShowPassword)}>
                                <img src="/assets/images/hide.svg" alt="" />
                            </div>
                        </>
                    }

                </div>

            </label>

            
            
        </div>
    );
}

export default InputTemplate;