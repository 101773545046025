import Select, { components } from 'react-select';
import { Interweave } from "interweave";

import SiteAccordion from "../../components/accordion";
import ReportageCard from "../../components/reportage";
import { customSelectStyle } from '../../components/reactSelectComponens';
import SiteModal from '../../components/siteModal';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { getAllReportage, getReportageCategory, getReportageData } from '../../service/reportage';
import { separateNumberWithComma } from '../../utilities';
import Slider from 'rc-slider';
import MiniCartCompoment from '../../components/cart';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPageTitle_action, setStepItemInHeader } from '../../store/action/app_action';
import LoadingButton from '../../components/loadingButton';
import SwitchBoxComponent from '../../components/formComponents/switchBoxComponent';
import { LoadingData } from '../../components/loading';
import { addToCart, deleteFromCart } from '../../service/cart';
import { addToCartAction, addToCartBeforApiAction, removeFromCartAction, removeFromCartBeforeApiAction } from '../../store/action/cart_action';
import CheckboxTemplate from '../../components/formComponents/checkboxComponent';
import useDebounce from '../../utilities/useDebounce';
import NoDataComponent from '../../components/noData';
import ReportageDetailModal from '../../components/reportage/detailModal';
import SearchNoResult from '../../components/noData/search';
import SitePagination from '../../components/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReportagePackages from '../../components/reportage/packages';
import { throttle } from 'lodash';

const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            <img src="/assets/images/swap.svg" alt="" />
            {children}
        </div>
    </components.Control>
);
const ReportageList = () => {

    const userCart = useSelector(store => store.cart);

    const ORDER_BY = [ //new -  cheap -  expensive - similar_web - domain_power
        {
            label: "مرتب سازی بر اساس",
            value: ""
        },
        {
            label: "جدیدترین",
            value: "new"
        },
        {
            label: "ارزان ترین",
            value: "cheap"
        },
        {
            label: "گران ترین",
            value: "expensive"
        },
        {
            label: "دامین آتوریتی",
            value: "da"
        },
        {
            label: "دامین ریت",
            value: "pa"
        },
        // {
        //     label: "سیمیلار وب",
        //     value: "similar_web"
        // },
        // {
        //     label: "قدرت دامنه",
        //     value: "domain_power"
        // },
    ]

    const defaultFilterValue = {
        showSpecialOffer: false,
        searchText: "",
        orderBy: ORDER_BY[1].value,
        selectedCategory: [],
        nofollow_link: false,
        is_share_to_social: false,
        is_link_to_other: false,
        is_video: false,
        is_ad_tag: false,
        main_page: false,
        min_price: 0,
        max_price: 10500000,
        min_similar_web: 0,
        max_similar_web: 500,
        min_domain_a: 0,
        max_domain_a: 100,
        min_spam: 0,
        max_spam: 150,
        min_pa: 0,
        max_pa: 150,
    }

    let currentPageVar = 1;
    let reportageDataVar = {};

    const pageStep = [
        {
            title: "انتخاب وبسایت ها",
            status: "current",
        },
        {
            title: "پرداخت کمپین",
            status: "none",
        },
        {
            title: "تایید نهایی کمپین",
            status: "none",
        },
    ]

    const REPORTAGE_FEATURE = [
        {
            key: "is_ad_tag",
            title: "بدون تگ تبلیغات",
        },
        {
            key: "is_link_to_other",
            title: "لینک به دامنه متفاوت",
        },
        {
            key: "is_main_page",
            title: "نمایش در صفحه اصلی",
        },
        {
            key: "is_nofollow",
            title: "درج لینک نوفالو",
        },
        {
            key: "is_share_to_social",
            title: "باز نشر در شبکه های اجتماعی",
        },
        {
            key: "is_video",
            title: "درج محتوای ویدیویی",
        },
    ]

    const dispatch = useDispatch();

    const currentPageVarRef = useRef(1);

    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        selectedId: -1,
    });
    const [ReportageData, setReportageData] = useState({
        loading: false,
        miniLoading: false,
        data: {
            data: [],
            meta: {},
            link: {},
        },
    })
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })

    const [ActiveFilters, setActiveFilters] = useState(defaultFilterValue);
    const [ActiveFilterInHeader, setActiveFilterInHeader] = useState([]);

    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);
    const [ReportageCategoryArr, setReportageCategoryArr] = useState([]);

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(15);
    const [LiveSearchText, setLiveSearchText] = useState('')
    const [LiveSearchLoading, setLiveSearchLoading] = useState(false)
    const [LiveSearchData, setLiveSearchData] = useState({
        data : [],
        links : {},
        meta : {},
    })
    const [ShowLiveSearchBox, setShowLiveSearchBox] = useState(false)

    const debouncedLiveSearchText = useDebounce(LiveSearchText, 800);

    const debouncedMinPrice = useDebounce(ActiveFilters.min_price, 800);
    const debouncedMaxPrice = useDebounce(ActiveFilters.max_price, 800);
    const debouncedMinSpam = useDebounce(ActiveFilters.min_spam, 800);
    const debouncedMaxSpam = useDebounce(ActiveFilters.max_spam, 800);
    const debouncedMinSimilarWeb = useDebounce(ActiveFilters.min_similar_web, 800);
    const debouncedMaxSimilarWeb = useDebounce(ActiveFilters.max_similar_web, 800);
    const debouncedMinDomainA = useDebounce(ActiveFilters.min_domain_a, 800);
    const debouncedMaxDomainA = useDebounce(ActiveFilters.max_domain_a, 800);
    const debouncedMinPA = useDebounce(ActiveFilters.min_pa, 800);
    const debouncedMaxPA = useDebounce(ActiveFilters.max_pa, 800);

    let showMiniLoading = false;
    const showMiniLoadingRef = useRef(false);
    let lastCalledPage = 1;
    const handleGetReportages = (customCurrentPage) => {
        if (ReportageData.loading || ReportageData.miniLoading) return

        showMiniLoading = !customCurrentPage ? true : false;
        showMiniLoadingRef.current = !customCurrentPage ? true : false;
        setReportageData({
            ...ReportageData,
            loading: customCurrentPage ? true : false,
            miniLoading: !customCurrentPage ? true : false,
        })


        getAllReportage({
            // page: customCurrentPage || CurrentPage,
            page: customCurrentPage || currentPageVarRef.current,
            perPage: PerPage,
            searchText: ActiveFilters.searchText,
            order_by: ActiveFilters.orderBy,
            categoryArr: ActiveFilters.selectedCategory,
            main_page: ActiveFilters.main_page,
            nofollow_link: ActiveFilters.nofollow_link,
            is_video: ActiveFilters.is_video,
            is_share_to_social: ActiveFilters.is_share_to_social,
            is_link_to_other: ActiveFilters.is_link_to_other,
            is_ad_tag: ActiveFilters.is_ad_tag,
            discount: ActiveFilters.showSpecialOffer,
            min_price: ActiveFilters.min_price,
            max_price: ActiveFilters.max_price,
            defaultMaxPrice: defaultFilterValue.max_price,
            min_spam: ActiveFilters.min_spam,
            max_spam: ActiveFilters.max_spam,
            defaultMaxSpam: defaultFilterValue.max_spam,
            min_similar_web: ActiveFilters.min_similar_web,
            max_similar_web: ActiveFilters.max_similar_web,
            defaultSimilarWeb: defaultFilterValue.max_similar_web,
            min_domain_a: ActiveFilters.min_domain_a,
            max_domain_a: ActiveFilters.max_domain_a,
            defaultDomainA: defaultFilterValue.max_domain_a,
            min_pa: ActiveFilters.min_pa,
            max_pa: ActiveFilters.max_pa,
            defaultPA: defaultFilterValue.max_pa,
        }).then(({ data }) => {
            reportageDataVar = data
            lastCalledPage = customCurrentPage || CurrentPage

            if (customCurrentPage) {
                setReportageData({
                    loading: false,
                    miniLoading: false,
                    data: data
                })
                currentPageVar = 1;
                currentPageVarRef.current = 1;
            } else {

                let helpObj = { ...ReportageData };

                if (helpObj.data.data) {


                    helpObj.data.data = [
                        ...helpObj.data.data || [],
                        ...data?.data || []
                    ];

                    helpObj.loading = false;
                    helpObj.miniLoading = false;
                    showMiniLoading = false;
                    showMiniLoadingRef.current = false;
                    helpObj.data.meta = data?.meta;
                    helpObj.data.link = data?.links;

                    console.log(helpObj)

                    setReportageData(helpObj)
                } else {
                    setReportageData({
                        loading: false,
                        miniLoading: false,
                        data: data
                    })
                    // showMiniLoading = false;
                    showMiniLoadingRef.current = false;
                }


            }

            showMiniLoading = false;
            showMiniLoadingRef.current = false;

        }).catch((err) => {
            console.log(err);
        })
    }

    const handleGetOneReportage = (reportageId) => {

        console.log(ShowReportageModal.show)

        if (reportageId === -1 || ReportageSingleData.loading || !ShowReportageModal.show) return

        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getReportageData(reportageId).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleAddItemToCart = () => {
        setLoadingAddToCart(true);

        let sendData = {
            reportage_id: ReportageSingleData.data?.id,
            type: "reportage",
        }
        dispatch(addToCartBeforApiAction({ reportage: ReportageSingleData.data }));

        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            dispatch(addToCartAction(data));
            // setShowReportageModal({ ...ShowReportageModal, show: false })

        }).catch((err) => {
            setLoadingAddToCart(false);
            dispatch(removeFromCartBeforeApiAction({ reportage: ReportageSingleData.data }));
            console.log(err)
        })

    }

    const handleDeleteItemFromCart = (itemId, reportageId, cart) => {

        const sendData = {
            reportage_id: reportageId
        }

        let sendType = "reportage";
        dispatch(removeFromCartAction(cart));

        deleteFromCart(sendType, itemId, sendData).then(({ data }) => { }).catch((err) => {
            console.log(err)
            dispatch(addToCartAction(cart));

        })
    }

    const handleGetCategories = () => {
        getReportageCategory().then(({ data }) => {
            setReportageCategoryArr(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSelectCategory = (id) => {
        let helpArr = ActiveFilters.selectedCategory;
        console.log({
            id, helpArr
        })
        if (helpArr.findIndex(item => item == id) > -1) {
            helpArr = helpArr.filter(a => (a != id))
        } else {
            helpArr.push(id)
        }

        // let helpArrFilter = ActiveFilterInHeader;

        // for (let i = 0; i < helpArr.length; i++) {
        //     const element = helpArr[i];
        //     for (let j = 0; j < ReportageCategoryArr.length; j++) {
        //         const element1 = ReportageCategoryArr[j];
        //         if(element1.id === element){
        //             helpArrFilter.push({
        //                 type : "category",
        //                 text : element1.title,
        //                 id : element1.id
        //             })
        //             break;
        //         }
        //     }
        // }

        // setActiveFilterInHeader(helpArrFilter);
        setActiveFilters({
            ...ActiveFilters,
            selectedCategory: [...helpArr]
        })
    }

    const handleFindActiveFilters = () => {
        let helpArr = [];

        if (
            ActiveFilters.min_price !== defaultFilterValue.min_price ||
            ActiveFilters.max_price !== defaultFilterValue.max_price
        ) {
            helpArr.push({
                type: "price",
                text: `قیمت از ${separateNumberWithComma(ActiveFilters.min_price)} تا ${separateNumberWithComma(ActiveFilters.max_price)}`
            }
            )
        }
        if (
            ActiveFilters.min_spam !== defaultFilterValue.min_spam ||
            ActiveFilters.max_spam !== defaultFilterValue.max_spam
        ) {
            helpArr.push({
                type: "spam",
                text: `اسپم اسکور از ${separateNumberWithComma(ActiveFilters.min_spam)} تا ${separateNumberWithComma(ActiveFilters.max_spam)}`
            }
            )
        }
        if (
            ActiveFilters.min_similar_web !== defaultFilterValue.min_similar_web ||
            ActiveFilters.max_similar_web !== defaultFilterValue.max_similar_web
        ) {
            helpArr.push({
                type: "similar_web",
                text: `سیمیلاروب از ${separateNumberWithComma(ActiveFilters.min_similar_web)} تا ${separateNumberWithComma(ActiveFilters.max_similar_web)}`
            }
            )
        }
        if (
            ActiveFilters.min_pa !== defaultFilterValue.min_pa ||
            ActiveFilters.max_pa !== defaultFilterValue.max_pa
        ) {
            helpArr.push({
                type: "pa",
                text: `دامین ریت از ${separateNumberWithComma(ActiveFilters.min_pa)} تا ${separateNumberWithComma(ActiveFilters.max_pa)}`
            }
            )
        }
        if (ActiveFilters.nofollow_link) {
            helpArr.push({
                type: "nofollow_link",
                text: `لینک‌های نوفالو`
            })
        }
        if (ActiveFilters.is_video) {
            helpArr.push({
                type: "is_video",
                text: `محتوای ویدیویی`
            })
        }
        if (ActiveFilters.is_share_to_social) {
            helpArr.push({
                type: "is_share_to_social",
                text: `شبکه‌های اجتماعی`
            })
        }
        if (ActiveFilters.is_ad_tag) {
            helpArr.push({
                type: "is_ad_tag",
                text: `بدون تگ تبلیغات`
            })
        }
        if (ActiveFilters.is_link_to_other) {
            helpArr.push({
                type: "is_link_to_other",
                text: `لینک به دامنه متفاوت`
            })
        }
        if (ActiveFilters.main_page) {
            helpArr.push({
                type: "main_page",
                text: `نمایش در صفحه اصلی`
            })
        }

        for (let i = 0; i < ActiveFilters.selectedCategory.length; i++) {
            const element = ActiveFilters.selectedCategory[i];
            helpArr.push({
                type: "category",
                text: ReportageCategoryArr?.find(item => item.id === parseInt(element))?.title,
                id: ReportageCategoryArr?.find(item => item.id === parseInt(element))?.id
            })
        }

        console.log({ helpArr })

        setActiveFilterInHeader(helpArr);
        // handleSelectCategory();

    }

    const handleRemoveActiveFilter = (type, otherData) => {
        if (type === "all") {
            setActiveFilters(defaultFilterValue);
        }
        if (type === "price") {
            setActiveFilters({
                ...ActiveFilters,
                min_price: defaultFilterValue.min_price,
                max_price: defaultFilterValue.max_price,
            });
        }
        if (type === "spam") {
            setActiveFilters({
                ...ActiveFilters,
                min_spam: defaultFilterValue.min_spam,
                max_spam: defaultFilterValue.max_spam,
            });
        }
        if (type === "similar_web") {
            setActiveFilters({
                ...ActiveFilters,
                min_similar_web: defaultFilterValue.min_similar_web,
                max_similar_web: defaultFilterValue.max_similar_web,
            });
        }
        if (type === "pa") {
            setActiveFilters({
                ...ActiveFilters,
                min_pa: defaultFilterValue.min_pa,
                max_pa: defaultFilterValue.max_pa,
            });
        }
        if (type === "nofollow_link") {
            setActiveFilters({
                ...ActiveFilters,
                nofollow_link: defaultFilterValue.nofollow_link,
            });
        }
        if (type === "is_video") {
            setActiveFilters({
                ...ActiveFilters,
                is_video: defaultFilterValue.is_video,
            });
        }
        if (type === "is_share_to_social") {
            setActiveFilters({
                ...ActiveFilters,
                is_share_to_social: defaultFilterValue.is_share_to_social,
            });
        }
        if (type === "is_ad_tag") {
            setActiveFilters({
                ...ActiveFilters,
                is_ad_tag: defaultFilterValue.is_ad_tag,
            });
        }
        if (type === "is_link_to_other") {
            setActiveFilters({
                ...ActiveFilters,
                is_link_to_other: defaultFilterValue.is_link_to_other,
            });
        }
        if (type === "main_page") {
            setActiveFilters({
                ...ActiveFilters,
                main_page: defaultFilterValue.main_page,
            });
        }
        if (type === "category") {
            if (ActiveFilters.selectedCategory?.length > 0) {
                handleSelectCategory(otherData)
            } else {
                setActiveFilters({
                    ...ActiveFilters,
                    selectedCategory: [],
                });
            }
        }

        handleFindActiveFilters();
    }

    const handleGenerateLink = (link) => {

        // reportage?.news?.site || new URL(reportage?.news?.site || "").hostname?.split("www.")[1] || new URL(reportage?.news?.site).hostname || ""

        if (link) {
            return new URL(link || "").hostname?.split("www.")[1] || new URL(link).hostname
        }

        return ""

    }

    const handleLiveSearch = () => {
        // if(searchText.trim)
        setLiveSearchLoading(true)
        getAllReportage({ page: 1, order_by: "new", searchText: LiveSearchText }).then(({ data }) => {
            setLiveSearchData(data)
            setLiveSearchLoading(false)
        }).catch((err) => {
            console.log(err)
            setLiveSearchLoading(false)
        })
    }

    const handleSendMessageByEnter = (e) => {
        if (e.key === 'Enter') {
            handleGetReportages(1);
        }
    }

    const handleShowAllResult = () => {
        setCurrentPage(1);
        currentPageVar = 1;
        currentPageVarRef.current = 1;
        handleFindActiveFilters();
        handleGetReportages(1);
    }

    const containerRef = useRef();

    // Function to handle scroll event
    const handleScroll = throttle(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 200
        ) {
            // User has scrolled to the bottom
            // if(Math.ceil(totalItems / PerPage))
            console.log({ showMiniLoading, ref: showMiniLoadingRef.current })
            if (!ReportageData.loading && !ReportageData.miniLoading && !showMiniLoading && !showMiniLoadingRef.current) {
                // console.log({
                //     CurrentPage,
                //     total: ReportageData?.data?.meta?.total,
                //     currentPageVar,
                //     totalVar: reportageDataVar?.meta?.total,
                //     showMiniLoading,
                //     if : Math.ceil(reportageDataVar?.meta?.total / PerPage)
                // })
                // if (Math.ceil(ReportageData?.data?.meta?.total / PerPage) > CurrentPage) {
                // if (Math.ceil(reportageDataVar?.meta?.total / PerPage) > CurrentPage) {
                console.log({ currentPageVarRef: currentPageVarRef.current })
                if (Math.ceil(reportageDataVar?.meta?.total / PerPage) >= currentPageVarRef.current) {
                    // showMiniLoading = true
                    // setCurrentPage(currentPageVar + 1);
                    setCurrentPage(prevPage => prevPage + 1);
                    currentPageVar = currentPageVar + 1;
                    currentPageVarRef.current = currentPageVarRef.current + 1
                    console.log({ currentPageVar, showMiniLoading, currentPageVarRefCurrent: currentPageVarRef.current })
                }
            }
        }
    }, 400);

    useEffect(() => {
        console.log("Change Order By , selected category")
        setCurrentPage(1);
        currentPageVar = 1;
        currentPageVarRef.current = 1;
        handleFindActiveFilters();
        handleGetReportages(1);
    }, [
        ActiveFilters.orderBy,
        ActiveFilters.selectedCategory,
        ActiveFilters.nofollow_link,
        ActiveFilters.is_video,
        ActiveFilters.is_share_to_social,
        ActiveFilters.is_ad_tag,
        ActiveFilters.is_link_to_other,
        ActiveFilters.main_page,
        ActiveFilters.showSpecialOffer,
        debouncedMinPrice,
        debouncedMaxPrice,
        debouncedMinSpam,
        debouncedMaxSpam,
        debouncedMinSimilarWeb,
        debouncedMaxSimilarWeb,
        debouncedMinDomainA,
        debouncedMaxDomainA,
        debouncedMinPA,
        debouncedMaxPA,
    ])

    useEffect(() => {
        handleGetReportages();
    }, [
        CurrentPage,
    ])

    useEffect(() => {
        if(ShowLiveSearchBox){
            handleLiveSearch();
        }
    }, [
        debouncedLiveSearchText,
    ])

    useEffect(() => {
        handleGetOneReportage(ShowReportageModal.selectedId);
    }, [ShowReportageModal.show])

    useEffect(() => {
        handleGetCategories();
        setTimeout(() => {
            dispatch(setCurrentPageTitle_action("ساخت کمپین رپورتاژ"))
            dispatch(setStepItemInHeader(pageStep))
        }, 10)
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])


    return (
        <>

            <Helmet>
                <title>
                    رپورتاژ ها
                </title>
            </Helmet>

            <SiteModal
                modalDesign={2}
                title={"رپورتاژ خبر"}
                showModal={ShowReportageModal.show}
                closeModal={() => setShowReportageModal({ ...ShowReportageModal, show: false })}
            >

                {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>
                        <ReportageDetailModal
                            reportageData={ReportageSingleData.data}
                            LoadingAddToCart={LoadingAddToCart}
                            handleAddItemToCart={handleAddItemToCart}
                            handleDeleteItemFromCart={handleDeleteItemFromCart}
                        />
                    </>
                }


            </SiteModal>

            <div className="d-flex align-items-baseline">
                <div className="hasMiniCart row ms-0">
                    <div className="filterColBox">
                        <div className="filterMainBox">
                            <div className="filterMainHeaderBox">
                                <div className="filterMainHeader">
                                    <div className="filterMainTitle">
                                        فیلترهای انتخابی
                                    </div>
                                    <div className="filterMainSelectedCount">
                                        <div>{ActiveFilterInHeader.length}</div>
                                        <img src="/assets/images/candle-2.svg" alt="" />
                                    </div>
                                </div>

                                {ActiveFilterInHeader.length > 0 &&
                                    <>
                                        <div className="filterMainHeaderSelected">
                                            {ActiveFilterInHeader.map((filter, index) =>
                                                <>
                                                    <div className="filterMainHeaderSelectedCard">
                                                        <div>{filter.text}</div>
                                                        <div className='close' onClick={() => handleRemoveActiveFilter(filter.type, filter.id)}>
                                                            <img src="/assets/images/closeWithCircle.svg" alt="" />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                }


                            </div>
                            <div className="filterMainBody">

                                <div className="filterSpecialItemCard">
                                    {/* <div></div> */}
                                    <SwitchBoxComponent
                                        title={"فروش ویژه ها"}
                                        onChange={e => setActiveFilters({ ...ActiveFilters, showSpecialOffer: e.currentTarget.checked })}
                                    />
                                </div>

                                <div className="filterItemsCard">
                                    <SiteAccordion title={"ویژه ها"}>
                                        <div className="reportageFilterBox">
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"لینک‌های نوفالو"}
                                                    checked={ActiveFilters.nofollow_link}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, nofollow_link: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"شبکه‌های اجتماعی"}
                                                    checked={ActiveFilters.is_share_to_social}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, is_share_to_social: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"لینک به دامنه متفاوت"}
                                                    checked={ActiveFilters.is_link_to_other}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, is_link_to_other: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"محتوای ویدیویی"}
                                                    checked={ActiveFilters.is_video}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, is_video: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"بدون تگ تبلیغات"}
                                                    checked={ActiveFilters.is_ad_tag}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, is_ad_tag: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <SwitchBoxComponent
                                                    title={"نمایش در صفحه اصلی"}
                                                    checked={ActiveFilters.main_page}
                                                    onChange={e => setActiveFilters({ ...ActiveFilters, main_page: e.currentTarget.checked })}
                                                    hasChecked
                                                />
                                            </div>

                                        </div>
                                    </SiteAccordion>
                                </div>

                                <div className="filterItemsCard">
                                    {/* <SiteAccordion title={"دسته بندی ها"}> */}
                                    <SiteAccordion title={"موضوع رسانه"}>
                                        <div className="reportageFilterBox">
                                            {ReportageCategoryArr.map((category, index) =>
                                                <>
                                                    <div className="mb-2">
                                                        <CheckboxTemplate
                                                            key={`category-${index}`}
                                                            title={category.title}
                                                            value={category.id}
                                                            checked={ActiveFilters?.selectedCategory?.findIndex(item => item == category?.id) > -1}
                                                            onChange={e => handleSelectCategory(e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </SiteAccordion>
                                </div>
                                <div className="filterItemsCard">
                                    <SiteAccordion title={"قیمت"}>

                                        <div className="reportageFilterBox">

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    از قیمت
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.min_price)}</div>
                                                    <div className='unit'>تومان</div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    تا قیمت
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{ActiveFilters.max_price === 10500000 ? separateNumberWithComma(10000000) + " +" : separateNumberWithComma(ActiveFilters.max_price)}</div>
                                                    <div className='unit'>تومان</div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <Slider
                                                    range
                                                    min={defaultFilterValue.min_price}
                                                    max={defaultFilterValue.max_price}
                                                    value={[ActiveFilters.min_price, ActiveFilters.max_price]}
                                                    step={500000}
                                                    pushable={true}
                                                    className="siteTempRangeSlider"
                                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, min_price: newValue[0], max_price: newValue[1] })}
                                                />

                                                <div className="bottomTitleForRangeSlider">
                                                    <div>گرانترین</div>
                                                    <div>ارزان ترین</div>
                                                </div>

                                            </div>
                                        </div>

                                    </SiteAccordion>
                                </div>
                                {/* <div className="filterItemsCard">
                                    <SiteAccordion title={"سیمیلاروب"}>
                                        <div className="reportageFilterBox">

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    از رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.min_similar_web)}</div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    تا رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.max_similar_web)}</div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <Slider
                                                    range
                                                    min={defaultFilterValue.min_similar_web}
                                                    max={defaultFilterValue.max_similar_web}
                                                    defaultValue={[ActiveFilters.min_similar_web, ActiveFilters.max_similar_web]}
                                                    pushable={true}
                                                    className="siteTempRangeSlider"
                                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, min_similar_web: newValue[0], max_similar_web: newValue[1] })}
                                                />

                                                <div className="bottomTitleForRangeSlider">
                                                    <div>بالاترین</div>
                                                    <div>پایین ترین</div>
                                                </div>

                                            </div>
                                        </div>

                                    </SiteAccordion>
                                </div> */}
                                <div className="filterItemsCard">
                                    <SiteAccordion title={"اسپم اسکور"}>
                                        <div className="reportageFilterBox">

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    از رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.min_spam)}</div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    تا رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.max_spam)}</div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <Slider
                                                    range
                                                    min={defaultFilterValue.min_spam}
                                                    max={defaultFilterValue.max_spam}
                                                    defaultValue={[ActiveFilters.min_spam, ActiveFilters.max_spam]}
                                                    pushable={true}
                                                    className="siteTempRangeSlider"
                                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, min_spam: newValue[0], max_spam: newValue[1] })}
                                                />

                                                <div className="bottomTitleForRangeSlider">
                                                    <div>بالاترین</div>
                                                    <div>پایین ترین</div>
                                                </div>

                                            </div>
                                        </div>
                                    </SiteAccordion>
                                </div>
                                <div className="filterItemsCard">
                                    <SiteAccordion title={"دامین اتوریتی"}>
                                        <div className="reportageFilterBox">

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    از رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.min_domain_a)}</div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    تا رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.max_domain_a)}</div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <Slider
                                                    range
                                                    min={defaultFilterValue.min_domain_a}
                                                    max={defaultFilterValue.max_domain_a}
                                                    defaultValue={[ActiveFilters.min_domain_a, ActiveFilters.max_domain_a]}
                                                    pushable={true}
                                                    className="siteTempRangeSlider"
                                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, min_domain_a: newValue[0], max_domain_a: newValue[1] })}
                                                />

                                                <div className="bottomTitleForRangeSlider">
                                                    <div>بالاترین</div>
                                                    <div>پایین ترین</div>
                                                </div>

                                            </div>
                                        </div>
                                    </SiteAccordion>
                                </div>
                                <div className="filterItemsCard">
                                    <SiteAccordion title={"دامین ریت"}>
                                        <div className="reportageFilterBox">

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    از رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.min_pa)}</div>
                                                </div>
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div>
                                                    تا رتبه
                                                </div>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='price'>{separateNumberWithComma(ActiveFilters.max_pa)}</div>
                                                </div>
                                            </div>

                                            <div className='mt-4'>
                                                <Slider
                                                    range
                                                    min={defaultFilterValue.min_pa}
                                                    max={defaultFilterValue.max_pa}
                                                    defaultValue={[ActiveFilters.min_pa, ActiveFilters.max_pa]}
                                                    pushable={true}
                                                    className="siteTempRangeSlider"
                                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, min_pa: newValue[0], max_pa: newValue[1] })}
                                                />

                                                <div className="bottomTitleForRangeSlider">
                                                    <div>بالاترین</div>
                                                    <div>پایین ترین</div>
                                                </div>

                                            </div>
                                        </div>
                                    </SiteAccordion>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="reportageColMainBox pe-0" ref={containerRef}>

                        {ActiveFilters.showSpecialOffer &&
                            <>
                            </>
                        }
                        <ReportagePackages navClassName='mb-0' />



                        <div className="row searchAndFilterTopBox">
                            {/* filterTopMainBox */}

                            <div className="col-8 mb-3">
                                <div className="reportageSearchBox">
                                    <input
                                        type="text"
                                        className="siteTempInput"
                                        placeholder="جستجو در میان نام و آدرس وبسایت ها"
                                        onChange={e => { setActiveFilters({ ...ActiveFilters, searchText: e.target.value }); setLiveSearchText(e.target.value) }}
                                        onKeyPress={handleSendMessageByEnter}
                                        onFocus={() => setShowLiveSearchBox(true)}
                                        onBlur={() => setShowLiveSearchBox(false)}
                                    />
                                    <div className="reportageSearchImage" onClick={handleGetReportages}>
                                        <img src="/assets/images/search.svg" alt="" />
                                    </div>

                                    <div className={"showLiveSearchBox" + (ShowLiveSearchBox ? " show" : "")}>
                                        {LiveSearchLoading ?
                                            <>
                                                <div className="d-flex align-items-center justify-content-center my-3">
                                                    <LoadingData />
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="">رسانه ها</div>
                                                    <div className="siteTempBtn reverse" onClick={handleShowAllResult}>
                                                        <div className="">مشاهده همه</div>
                                                    </div>
                                                </div>
                                                <div className="liveSearchResultMainBox mt-2">
                                                    {LiveSearchData?.data?.map((item , index) => 
                                                        <>
                                                            <div className="liveSearchResultCard">
                                                                <div className="liveSearchResultCardImage">
                                                                    <img src={item?.news?.image} alt="" />
                                                                </div>
                                                                <div className="liveSearchResultCardTitle">{item?.title}</div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                
                                                {LiveSearchData?.meta?.total?.length === 0 && <NoDataComponent text={"نتیجه ای یافت نشد"} />}
                                            </>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="col-4 pe-0">
                                <Select
                                    defaultValue={ORDER_BY[1]}
                                    options={ORDER_BY}
                                    classNamePrefix="react-select"
                                    className="react-select-temp"
                                    components={{ Control }}
                                    styles={customSelectStyle}
                                    onChange={(newValue) => setActiveFilters({ ...ActiveFilters, orderBy: newValue.value })}
                                />
                            </div>

                        </div>

                        <div className="row customRow">

                            {ReportageData.loading ?
                                <>
                                    <div className="d-flex align-items-center justify-content-center my-3">
                                        <LoadingData />
                                    </div>
                                </>
                                :
                                <>
                                    {ReportageData.data?.data?.map((reportage, index) =>

                                        <>
                                            <div className="itemColBox mb-3">
                                                <ReportageCard
                                                    key={`reportage-${index}`}
                                                    reportage={reportage}
                                                    fullDetail={true}
                                                    setShowDetailModal={setShowReportageModal}
                                                />
                                            </div>
                                        </>

                                    )}
                                    <div className={
                                        "d-flex align-items-center justify-content-center my-3 " +
                                        (ReportageData?.data?.meta?.last_page <= CurrentPage ? " d-none" : "") +
                                        (ReportageData.miniLoading ? " opacity-100" : " opacity-0")
                                    }>
                                        <LoadingData />
                                    </div>
                                    {/* {ReportageData.miniLoading &&
                                        <>
                                        </>
                                    } */}
                                    {/* <SitePagination
                                        totalItems={ReportageData?.data?.meta?.total || 0}
                                        perPage={PerPage}
                                        currentPage={CurrentPage}
                                        setCurrentPage={setCurrentPage}
                                    /> */}
                                    {(ReportageData.data?.data?.length === 0 && !ReportageData.loading && !ReportageData.miniLoading) &&
                                        <>
                                            {/* <NoDataComponent 
                                                text={"رپورتاژی یافت نشد!"}
                                            /> */}
                                            <SearchNoResult />
                                        </>
                                    }
                                </>
                            }

                        </div>

                    </div>
                </div>

                <MiniCartCompoment />

            </div>

        </>
    );
}

export default ReportageList;