// import moment from 'moment';
import moment from 'moment-jalaali';

const setupMomentJalaali = () => {
    // Set locale to Persian
    // moment.locale('fa');

    // Override the default Persian weekdays
    moment.updateLocale('fa', {
        weekdays: 'یک‌شنبه_دوشنبه_سه‌شنبه_چهارشنبه_پنج‌شنبه_جمعه_شنبه'.split('_')
    });

    // Load Persian calendar system
    // moment.loadPersian({ usePersianDigits: true });
};

export default setupMomentJalaali;
