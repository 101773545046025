const ReportStatus = ({ 
    reportStatus, 
    withBox = false ,
    handleShowReason = () => null,
}) => {


    const statusArr = {
        // success
        "منتشر شده": {
            color: "#09A363",
        },
        "پاسخ داده شد": {
            color: "#09A363",
        },
        "شارژ حساب": {
            color: "#09A363",
        },
        "تایید شده": {
            color: "#09A363",
        },
        "پاسخ داده شده": {
            color: "#09A363",
        },
        // warning
        "در انتظار بررسی": {
            color: "#E1AA74",
        },
        "در انتظار پرداخت": {
            color: "#E1AA74",
        },
        "در انتظار تایید پرداخت": {
            color: "#E1AA74",
        },
        "پرداخت نشده": {
            color: "#E1AA74",
        },
        "در انتظار تایید": {
            color: "#E1AA74",
        },
        "پرداخت کمپین": {
            color: "#E1AA74",
        },
        "پرداخت رپورتاژ": {
            color: "#E1AA74",
        },
        "پرداخت بک لینک": {
            color: "#E1AA74",
        },
        // info
        "آپلود محتوا": {
            color: "#1370AF",
        },
        "در انتظار": {
            color: "#1370AF",
        },
        "در انتظار ایجاد": {
            color: "#1370AF",
        },
        "در انتظار انتشار": {
            color: "#1370AF",
        },
        "در جریان": {
            color: "#1370AF",
        },
        "در حال بررسی": {
            color: "#1370AF",
        },
        // error
        "لغو شده": {
            color: "#8C2230",
        },
        "بسته شد": {
            color: "#8C2230",
        },
        "رد شده": {
            color: "#8C2230",
        },
        
    }

    if (withBox) {
        return (
            <>
                <div
                    className={
                        "reportStatusBox" + 
                        (withBox ? " withBox" : "") +
                        (reportStatus === "رد شده" ? " decline" : "")
                    }
                    style={{
                        color: statusArr?.[reportStatus]?.color || "#fff",
                        backgroundColor: (statusArr?.[reportStatus]?.color || "#000") + "20"
                    }}
                >
                    <div>
                        {reportStatus}
                    </div>

                    {reportStatus === "رد شده" &&
                        <>
                            <img src="/assets/images/warning-2.svg" alt="" onClick={handleShowReason}/>
                        </>
                    }
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="reportStatusBox" style={{
                    color: statusArr[reportStatus]?.color || "#000",
                    margin: 0,
                }}>
                    {reportStatus}
                </div>
            </>
        );
    }
}

export default ReportStatus;