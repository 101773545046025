const RadioButtonTemplate = ({
    children,
    checked = false,
    onChange = () => null,
    value,
    name = ""
}) => {
    return (
        <>
            <div className="radioBtnTempBox">
                <label class="radioBtnTempLabel">
                    {children}
                    <input type="radio"  onChange={onChange} name = {name} value={value} checked={checked} hidden/>
                    <span class="checkmark"></span>
                </label>
            </div>
        </>
    );
}

export default RadioButtonTemplate;