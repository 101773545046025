import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment-jalaali";
import { routes } from "./routes";
// import { useDispatch } from "react-redux";
import { useDispatch } from "react-redux";
import { setStepItemInHeader } from "./store/action/app_action";
import { Suspense, lazy, useEffect } from "react";
import PrivateRoute from "./utilities/privateRoutes";
import AppLayout from "./components/layout/appLayout";
import AuthLayout from "./components/layout/authLayout";
import SimpleLayout from "./components/layout/simpleLayout";
import DashboardPage from "./views/dashboard";
import LoginPage from "./views/auth/login";
import RegisterPage from "./views/auth/register";
import TicketsList from "./views/tickets/list";
import ReportageList from "./views/reportage";
import AddTicketPage from "./views/tickets/add";
import ShowTicketPage from "./views/tickets/show";
import FAQListPage from "./views/faq/list";
import CartListPage from "./views/cart";
import CreateReportagePage from "./views/reportage/create";
import CreateCampaignPage from "./views/campaign/create";
import ReceiptSubmission from "./views/financial/receiptSubmission/create";
import OnlinePayment from "./views/financial/onlinePayment";
import MyReportageCampaignListPage from "./views/reportage/myReportageCampaigns";
import MyReportageListPage from "./views/reportage/myReportage";
import MyBackLinkListPage from "./views/backLink/myBackLink";
import MyBackLinkCampaignListPage from "./views/backLink/myBackLinkCampaigns";
import ReceiptSubmissionReportList from "./views/financial/receiptSubmission";
import FinancialReportList from "./views/financial";
import CreateContentPage from "./views/createContent";
import BackLinkList from "./views/backLink";
import PayPage from "./views/cart/pay";
import ReportageListOfCampaignPage from "./views/reportage/myReportageCampaigns/reportagesOfCampaign";
import ForeignReportageList from "./views/foreign_reportage";
import ForeignReportageCartListPage from "./views/cart/foreign_reportage_cart";
import BacklinkCartListPage from "./views/cart/backlink_cart";
import CreateBacklinkPage from "./views/backLink/create";
import PayDepositPage from "./views/financial/pay";
import ForgetPasswordPage from "./views/auth/forgetPassword";
import ProfileUserInfoPage from "./views/profile/userInfo";
import FinancialProfilePage from "./views/profile/financial";

// const DashboardPage = lazy(() => import('./views/dashboard'));
// const LoginPage = lazy(() => import('./views/auth/login'));
// const RegisterPage = lazy(() => import('./views/auth/register'));
// const TicketsList = lazy(() => import('./views/tickets/list'));
// const ReportageList = lazy(() => import('./views/reportage'));
// const AddTicketPage = lazy(() => import('./views/tickets/add'));
// const ShowTicketPage = lazy(() => import('./views/tickets/show'));
// const FAQListPage = lazy(() => import('./views/faq/list'));
// const CartListPage = lazy(() => import('./views/cart'));
// const CreateReportagePage = lazy(() => import('./views/reportage/create'));
// const CreateCampaignPage = lazy(() => import('./views/campaign/create'));
// const ReceiptSubmission = lazy(() => import('./views/financial/receiptSubmission/create'));
// const OnlinePayment = lazy(() => import('./views/financial/onlinePayment'));
// const MyReportageCampaignListPage = lazy(() => import('./views/reportage/myReportageCampaigns'));
// const MyReportageListPage = lazy(() => import('./views/reportage/myReportage'));
// const MyBackLinkListPage = lazy(() => import('./views/backLink/myBackLink'));
// const MyBackLinkCampaignListPage = lazy(() => import('./views/backLink/myBackLinkCampaigns'));
// const ReceiptSubmissionReportList = lazy(() => import('./views/financial/receiptSubmission'));
// const FinancialReportList = lazy(() => import('./views/financial'));
// const CreateContentPage = lazy(() => import('./views/createContent'));
// const BackLinkList = lazy(() => import('./views/backLink'));
// const PayPage = lazy(() => import('./views/cart/pay'));
// const ReportageListOfCampaignPage = lazy(() => import('./views/reportage/myReportageCampaigns/reportagesOfCampaign'));
// const ForeignReportageList = lazy(() => import('./views/foreign_reportage'));
// const ForeignReportageCartListPage = lazy(() => import('./views/cart/foreign_reportage_cart'));
// const BacklinkCartListPage = lazy(() => import('./views/cart/backlink_cart'));
// const CreateBacklinkPage = lazy(() => import('./views/backLink/create'));
// const PayDepositPage = lazy(() => import('./views/financial/pay'));
// const ForgetPasswordPage = lazy(() => import('./views/auth/forgetPassword'));
// const ProfileUserInfoPage = lazy(() => import('./views/profile/userInfo'));

function App() {
  moment.locale("fa");
  moment.loadPersian({ dialect: 'persian-modern' })
  // Override the default Persian weekdays
  // moment.updateLocale('fa', {
  //   weekdays: 'یک‌شنبه_دوشنبه_سه‌شنبه_چهارشنبه_پنج‌شنبه_جمعه_شنبه'.split('_'),
  //   months:["فروردین" , "اردیبهشت" , "خرداد" , "تیر" , "مرداد" , "شهریور" , "مهر" , "آبان" , "آذر" , "دی" , "بهمن" , "اسفند"],
  // });
  // moment.loadPersian()


  const location = useLocation();
  const dispatch = useDispatch();

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    handleScrollToTop();
    // dispatch(showLoading());
    dispatch(setStepItemInHeader([]))

  }, [location.pathname]);

  return (
    <>
      <ToastContainer
        rtl={true}
        toastClassName={"siteTempToast"}
        position="bottom-right"
        hideProgressBar={true}
      />


      {/* <Suspense fallback={<></>}> */}
        <Routes>

          <Route element={<AuthLayout />}>
            <Route path={routes.auth.login} element={<LoginPage />} />
            <Route path={routes.auth.register} element={<RegisterPage />} />
            <Route path={routes.auth.forgetPassword} element={<ForgetPasswordPage />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route element={<AppLayout />}>

              <Route path={routes.dashboard} element={<DashboardPage />} />

              <Route path={routes.campaign.create} element={<CreateCampaignPage />} />
              {/* <Route path={routes.campaign.list} element={<CreateCampaignPage />} /> */}

              <Route path={routes.ticket.list} element={<TicketsList />} />
              <Route path={routes.ticket.show(":id")} element={<ShowTicketPage />} />
              <Route path={routes.ticket.add} element={<AddTicketPage />} />

              <Route path={routes.cart.list} element={<CartListPage />} />
              <Route path={routes.cart.backlink} element={<BacklinkCartListPage />} />
              <Route path={routes.cart.foreignReportage} element={<ForeignReportageCartListPage />} />
              <Route path={routes.cart.pay} element={<PayPage />} />

              <Route path={routes.reportage.list} element={<ReportageList />} />
              <Route path={routes.reportage.myReportage} element={<MyReportageListPage />} />
              <Route path={routes.reportage.campaigns} element={<MyReportageCampaignListPage />} />
              <Route path={routes.reportage.reportageOfCampaigns(":id")} element={<ReportageListOfCampaignPage />} />

              <Route path={routes.foreign_reportage.list} element={<ForeignReportageList />} />
              <Route path={routes.foreign_reportage.myReportage} element={<MyReportageListPage />} />
              <Route path={routes.foreign_reportage.campaigns} element={<MyReportageCampaignListPage />} />
              <Route path={routes.foreign_reportage.reportageOfCampaigns(":id")} element={<ReportageListOfCampaignPage />} />

              <Route path={routes.backLink.list} element={<BackLinkList />} />
              <Route path={routes.backLink.myBackLink} element={<MyBackLinkListPage />} />
              <Route path={routes.backLink.campaigns} element={<MyBackLinkCampaignListPage />} />

              <Route path={routes.faq.list} element={<FAQListPage />} />

              <Route path={routes.financial.reportList} element={<FinancialReportList />} />
              <Route path={routes.financial.receiptSubmissionReportList} element={<ReceiptSubmissionReportList />} />
              <Route path={routes.financial.receiptSubmissionAdd} element={<ReceiptSubmission />} />
              <Route path={routes.financial.onlinePayment} element={<OnlinePayment />} />
              <Route path={routes.financial.pay} element={<PayDepositPage />} />

              <Route path={routes.createContent.root} element={<CreateContentPage />} />

              <Route path={routes.profile.root} element={<ProfileUserInfoPage />} />
              <Route path={routes.profile.financial} element={<FinancialProfilePage />} />

            </Route>

            <Route element={<SimpleLayout />}>
              <Route path={routes.reportage.create(":id")} element={<CreateReportagePage />} />
              <Route path={routes.backLink.create(":id")} element={<CreateBacklinkPage />} />
            </Route>


          </Route>

        </Routes>
      {/* </Suspense> */}


    </>
  );
}

export default App;
