/*
    Step Array Design

    [
        {
            title : "",
            status : "current" // this item must be one of ["current" , "pass" ,"error"]
        }
    ]

*/


const StepPattern = ({
    className ,
    stepArray = []
}) => {
    return ( 
        <>

            {stepArray.length > 0 &&

                <>

                    <div className="stepItemMainBox">

                        {stepArray.map((item , index) => 

                            <>
                                
                                <div 
                                    className={
                                        "stepItemCard" + 
                                        (className ? " " + className : "") +
                                        (item.status ? " " + item.status : " none")
                                    }
                                >
                                    <div className="stepItemCardSquare"></div>
                                    <div className="stepItemCardTitle">
                                        {item.title}
                                    </div>
                                </div>

                            </>
                        
                        )}

                    </div>
                </>
            
            }

        </>
    );
}
 
export default StepPattern;