
const initialState = {
    id : -1 ,
    carts : {},
    status : "",
    loading : false,
};

export const foreignReportageCartReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'INIT_FOREIGN_REPORTAGE_CART': {
            return {
                ...action.payload
            }
        }

        case 'EMPTY_FOREIGN_REPORTAGE_CART': {
            return {
                ...state,
                carts:{}
            }
        }

        case 'SET_FOREIGN_REPORTAGE_CART': {
            return {
                ...state,
                carts : {...action.payload},
                loading : false,
            }
        }

        case 'SET_FOREIGN_REPORTAGE_LOADING': {
            return {
                ...state,
                loading : action.payload,
            }
        }

        default:
            return state
    }
}