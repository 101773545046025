import InputTemplate from "../../../../components/formComponents/inputComponent";
import LoadingButton from "../../../../components/loadingButton";
import SiteFileUploader from "../../../../components/formComponents/fileUploader";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setCurrentPageTitle_action } from "../../../../store/action/app_action";
import { Helmet } from "react-helmet-async";
import { separateNumberWithComma } from "../../../../utilities";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../../routes";
import { toast } from "react-toastify";
import { sendReceiptReport } from "../../../../service/financial";
import { NUM_REGEX } from "../../../../utilities/config";

const ReceiptSubmission = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [FormInputs, setFormInputs] = useState({
    amount: "",
    file: null,
    description: ""
  })

  const [DepositLoading, setDepositLoading] = useState(false)


  const handleSubmit = () => {
    if (FormInputs?.amount?.toString()?.trim() === "") {
      toast.error("لطفا مبلغ اعتبار را وارد کنید!")
      return
    }
    if (!FormInputs?.file) {
      toast.error("لطفا فیش واریزی را انتخاب کنید!")
      return
    }
    if (FormInputs?.description?.trim() === "") {
      toast.error("لطفا توضیحات را وارد کنید!")
      return
    }

    // const sendData = {
    //   amount: FormInputs?.amount,
    //   file : 
    // }

    const sendData = new FormData();

    sendData.append("amount", FormInputs?.amount);
    sendData.append("receipt", FormInputs?.file);
    sendData.append("description", FormInputs?.description);

    setDepositLoading(true)
    sendReceiptReport(sendData).then(({ data }) => {
      setFormInputs({
        amount: "",
        file: null,
        description: ""
      })
      toast.success("رسید واریزی شما با موفقیت ثبت شد!")
      navigate(routes.financial.receiptSubmissionReportList)
      setDepositLoading(false)
    }).catch(err => {
      console.log(err)
      setDepositLoading(false)
    })


  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(setCurrentPageTitle_action("ثبت فیش واریزی"));
    }, 100)
  }, [])
  return (
    <>
      <Helmet>
        <title>
          ثبت فیش واریزی
        </title>
      </Helmet>

      <div className="customBox justify-content-center">
        <div className="receiptSubmissionMainBox">
          <div>
            <p className="siteTempLabelTitle">مبلغ اعتبار</p>
            <div className="receiptSubmissionCrediteAmountInput">
              <InputTemplate
                value={separateNumberWithComma(FormInputs.amount)}
                type="text"
                placeholder="مبلغ خود را وارد کنید"
                onChange={e => setFormInputs({ ...FormInputs, amount: e.target.value?.replace(NUM_REGEX, '') })}
                onWheel={(event) => event.currentTarget.blur()}
              />
              <div className="currency">تومان</div>
            </div>
          </div>

          <div className="receiptSubmissionSuggestionAmount">
            <div>
              <p className="receiptSubmissionSuggestionAmountText">پیشنهادی</p>
            </div>
            <div className="receiptSubmissionSuggestionBtns">
              <div className="siteTempBtn designSelectBtn" onClick={() => setFormInputs({ FormInputs, amount: 500000 })}> {separateNumberWithComma(500000)} تومان</div>
              <div className="siteTempBtn designSelectBtn "
                onClick={() => setFormInputs({ FormInputs, amount: 1500000 })}
              >
                {/* active */}
                {separateNumberWithComma(1500000)} تومان
              </div>
              <div className="siteTempBtn designSelectBtn"
                onClick={() => setFormInputs({ FormInputs, amount: 4000000 })}
              >{separateNumberWithComma(4000000)} تومان</div>
            </div>
          </div>

          <div className="receiptSubmissionUploaderMainBox">
            <SiteFileUploader showPaper={true} title="رسید واریز" 
              file = {FormInputs.file}
              setFile={e => setFormInputs({...FormInputs , file : e.target.files[0]})}
            />
            <p className="receiptSubmissionUploaderLimitText">
              حداکثر حجم فایل 500 کیلوبایت
            </p>
          </div>

          <div>
            <p className="siteTempLabelTitle">توضیحات</p>
            <textarea
              className="receiptSubmissionExplanationText"
              cols="30"
              rows="5"
              placeholder="توضیحات خود را وارد کنید..."
              onChange={e => setFormInputs({ ...FormInputs, description: e.target.value })}
            />
          </div>

          <div className="receiptSubmissionRequestBtns">
            <Link to={routes.financial.receiptSubmissionReportList}>
              <div className="siteTempBtn blueBg showRequestsBtn">
                مشاهده لیست درخواست ها
              </div>
            </Link>
            <div className="siteTempBtn requestSubmitBtn">
              <LoadingButton 
                className="receiptSubmissionLoadingBtn"
                loading={DepositLoading}
                onClick={handleSubmit}
              >
                ثبت درخواست
              </LoadingButton>
              <div>
                <img
                  src="/assets/images/arrow-left-square.svg"
                  alt="arrow-left-square"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptSubmission;
