import { useDispatch, useSelector } from "react-redux";
import { addToCart, deleteCart, deleteFromCart } from "../../service/cart";
import { separateNumberWithComma } from "../../utilities";
import LoadingButton from "../loadingButton";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { LoadingData } from "../loading";
import { addToForeignReportageCartAction, addToForeignReportageCartBeforApiAction, emptyForeignReportageCartAction, getUserForeignReportageCartAction, removeFromForeignReportageCartAction, removeFromForeignReportageCartBeforeApiAction } from "../../store/action/foreign_reportage_cart_action";

const CartCardItem = ({
    cartItem,
    cartGroupId
}) => {
    const dispatch = useDispatch();

    const [LoadingAddToCart, setLoadingAddToCart] = useState(false);


    const handleAddItemToCart = (reportage) => {
        if(LoadingAddToCart) return;

        setLoadingAddToCart(true);

        let sendData = {
            reportage_id: reportage?.id,
            type : "foreign_reportage"
        }
        dispatch(addToForeignReportageCartBeforApiAction({reportage}));
        addToCart(sendData).then(({ data }) => {
            setLoadingAddToCart(false);
            dispatch(addToForeignReportageCartAction(data));
            // setShowReportageModal({ ...ShowReportageModal, show: false })

        }).catch((err) => {
            setLoadingAddToCart(false);
            dispatch(removeFromForeignReportageCartBeforeApiAction({reportage}));
            console.log(err)
        })

    }

    const handleDeleteItemFromCart = (itemId, reportageId, cart) => {

        const sendData = {
            reportage_id: reportageId
        }

        dispatch(removeFromForeignReportageCartAction(cart));
        deleteFromCart("foreign_reportage" , itemId, sendData).then(({ data }) => {
            // setLoadingAddToCart(false);
            // handleCalculateCart();
            // toast.success("با موفقیت به سبد خرید اضافه شد!")

        }).catch((err) => {
            console.log(err)
            dispatch(addToForeignReportageCartAction(cart));

        })
    }

    return (
        <>
            <div className="miniCartCardItem">
                <div className="text-truncate miniCartCardItemTitle">
                    {cartItem?.[0]?.reportage?.title || `آیتم ${cartGroupId}`}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="miniCartCardItemCount">

                        {/* {!LoadingAddToCart ?
                            <>

                                <LoadingData />
                            </>
                            :
                            <>
                            </>
                        } */}
                                <div className="miniCartCardItemCountOpt"
                                    onClick={() => handleAddItemToCart(cartItem?.[0]?.reportage)}
                                >
                                    <img src="/assets/images/plus.svg" alt="" />

                                </div>
                        <div>{cartItem?.length}</div>
                        <div className="miniCartCardItemCountOpt"
                            onClick={() => handleDeleteItemFromCart(cartItem?.[0]?.id, cartItem?.[0]?.reportage?.id, cartItem?.[0])}
                        >
                            <img src="/assets/images/minus.svg" alt="" />
                        </div>
                    </div>
                    <div className="miniCartCardItemPrice">
                        <div>{separateNumberWithComma(cartItem?.[0]?.reportage?.price)}</div>
                        <div className="unit">تومان</div>
                    </div>
                </div>
            </div >
        </>
    )
}

const ForeignReportageMiniCartCompoment = ({ className, payOrderFunction = () => { }, loading = false, cartStep = 1 }) => {

    const userCart = useSelector(store => store.foreign_reportage_cart)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [CartFinalSumItems, setCartFinalSumItems] = useState({
        discounts: 0,
        finalPrice: 0,
        price: 0,
        count: 0,
    })


    const handleCalculateCart = () => {
        let discounts = 0;
        let finalPrice = 0;
        let price = 0;
        let count = 0;

        // for (let i = 0; i < userCart?.carts.length; i++) {
        //     const element = userCart?.carts[i];

        // }
        Object.keys(userCart?.carts).map((key) => {
            for (let i = 0; i < userCart?.carts[key].length; i++) {
                const element = userCart?.carts[key]?.[i];

                discounts += (element?.reportage?.price - element?.reportage?.final_price)
                finalPrice += element?.reportage?.final_price
                price += element?.reportage?.price;
                count++;

            }
        });
        setCartFinalSumItems({
            discounts: discounts,
            finalPrice: finalPrice,
            price: price,
            count: count,
        })
    }

    // const handleDeleteAllCart = async () => {
    //     for (let i = 0; i < userCart.length; i++) {
    //         const element = userCart[i];
    //         await handleDeleteItemFromCart(element?.id, element?.reportage?.id);
    //     }

    // }

    const handleDeleteCart =() => {
        dispatch(emptyForeignReportageCartAction());
        deleteCart("foreign_reportage" , 1).then(({data}) => {
            console.log(data);
        }).catch((err) =>{
            dispatch(getUserForeignReportageCartAction());
            console.log(err)
        })
    }

    const handleGoToNextStep = () => {
        if (CartFinalSumItems.count > 0) {
            navigate(routes.cart.foreignReportage)
        }
    }


    useEffect(() => {
        handleCalculateCart();
    }, [userCart])

    return (
        <>
            <div className="showMiniCartMainBox">
                <div
                    className={"miniCartHeader" + (CartFinalSumItems.count > 0 ? " hasData" : "")}
                >
                    <div className="miniCartTitle">
                        <div className="miniCartTitleCount">{CartFinalSumItems.count}</div>
                        <div>سبد خرید</div>
                    </div>
                    <div className="miniCartSumItems">
                        <div>{separateNumberWithComma(CartFinalSumItems.finalPrice)}</div>
                        <div className="unit">تومان</div>
                    </div>
                </div>
                <div className="miniCartBody">
                    <div className="miniCartItemsBox">

                        {Object.keys(userCart?.carts).map((key, index) =>
                            <>
                                <CartCardItem
                                    cartItem={userCart?.carts[key]}
                                    cartGroupId={key}
                                    key={`cartItem-${key}-${index}`}
                                />
                            </>
                        )}

                        {/* {userCart?.carts?.map((cart, index) =>


                        )} */}

                        {(CartFinalSumItems.count === 0 || !userCart?.carts) &&

                            <>
                                <div className="emptyMiniCartBox">
                                    <img src="/assets/images/emptyCart.svg" alt="" />
                                    <div className="mt-3">
                                        سبد خرید خالی است
                                    </div>
                                </div>
                            </>

                        }

                    </div>

                    <div className="miniCartBodyFooterBox">
                        <div className="miniCartBodyFooterSumBox">
                            <div className="d-flex align-items justify-content-between text-gray3 miniCartBodyFooterSumCard">
                                <div>مجموع سبد خرید</div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.price)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                            <div className="d-flex align-items justify-content-between text-accentColor miniCartBodyFooterSumCard">
                                <div>مجموع تخفیف ها </div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.discounts)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                            <div className="d-flex align-items justify-content-between miniCartBodyFooterSumCard">
                                <div>مبلغ نهایی</div>
                                <div className="price">
                                    <div>
                                        {separateNumberWithComma(CartFinalSumItems.finalPrice)}
                                    </div>
                                    <div className="unit">تومان</div>
                                </div>
                            </div>
                        </div>

                        {cartStep === 1 &&

                            <div className={"miniCartBodyFooterBtnBox miniCartStep1" + (CartFinalSumItems.count > 0 ? " hasData" : "")}>


                                <div className="siteTempBtn design4"
                                    onClick={handleDeleteCart}
                                >
                                    <div>حذف سبد</div>
                                    <img src="/assets/images/delete.svg" alt="" />
                                </div>



                                <div
                                    className={"siteTempBtn primary justify-content-between"}
                                    onClick={handleGoToNextStep}
                                >
                                    <div>ادامه خرید</div>
                                    <img src="/assets/images/left-chevron.svg" alt="" />
                                </div>
                            </div>

                        }

                        {cartStep === 2 &&

                            <div className="miniCartBodyFooterBtnBox">


                                <Link to={routes.foreign_reportage.list}>
                                    <div className="siteTempBtn design4"
                                        style={{ width: "100%" }}
                                    >
                                        مرحله قبل
                                    </div>
                                </Link>



                                <LoadingButton
                                    className={"justify-content-between"}
                                    onClick={payOrderFunction}
                                    loading={loading}
                                >
                                    <div>پرداخت</div>
                                    <img src="/assets/images/left-chevron.svg" alt="" />
                                </LoadingButton>
                            </div>

                        }



                    </div>


                </div>
                {cartStep !== 1 &&
                    <>
                        <div className="siteTempBtn reverse justify-content-center mt-3"
                            onClick={() => payOrderFunction({payLater : true})}
                        >
                            بعدا پرداخت میکنم !
                        </div>
                        <div className="text-gray4 text-center mt-3"
                            style={{
                                fontSize: 11.5,
                                fontWeight: 500
                            }}
                        >
                            انتشار رپورتاژ تنها بعد از پرداخت امکان پذیر است.
                        </div>

                    </>
                }

            </div>
        </>
    );
}

export default ForeignReportageMiniCartCompoment;