const TitleWithCircle = ({title , className , withBorder , ...other}) => {
    return (
        <>
            <div className={"siteTempTitle withCircle" + (className ? " "+className : "") + (withBorder ? " withBorder" : "")} {...other}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="8" cy="8" r="5" fill="#8C2230" />
                    <circle cx="8" cy="8" r="7.5" stroke="#8C2230" />
                </svg>
                <div>
                    {title}
                </div>
                {withBorder &&
                    <>
                        <hr />
                    </>
                }
            </div>
        </>
    );
}

export default TitleWithCircle;