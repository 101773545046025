import { useEffect, useState } from "react";
import jMoment from "moment-jalaali";
import { useDispatch } from "react-redux";
import $ from 'jquery';
import { getAllCampaign } from "../../../service/campaign";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import ReportStatus from "../../../components/reportStatus";
import SitePagination from "../../../components/pagination";
import { getUserReportage } from "../../../service/reportage";
import { Helmet } from "react-helmet-async";
import Select, { components } from 'react-select';
import { perPageArr, reportageStatusArray } from "../../../utilities/config";
import { customSelectStyle, indicatorContainerBg, perPageControl } from "../../../components/reactSelectComponens";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import CampaignListTable from "../../../components/campaignListTable";
import { getUserBacklink } from "../../../service/backlink";
import BacklinkCampaignListTable from "../../../components/campaignListTable/backlink";


const Control = ({ children, ...props }) => (
    <components.Control {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
            {/* <div className="itemSelectCount">126</div> */}
        </div>
    </components.Control>
);

const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <div className="reactSelectTitleWithImage">
            {/* <img src="/assets/images/swap.svg" alt="" /> */}
            {children}
            <div className="itemSelectCountInSelectBox">0</div>
        </div>
    </components.SingleValue>
);

const MyBackLinkCampaignListPage = () => {

    const dispatch = useDispatch();

    const REPORTAGE_FAKE_DATA = [
        {
            id: 1,
            title: "کمپین تست",
            news: {
                name: "افق"
            },
            status_name: "منتشر شده"
        },
        {
            id: 1,
            title: "کمپین تست",
            news: {
                name: "افق"
            },
            status_name: "در انتظار ایجاد"
        },
        {
            id: 1,
            title: "کمپین تست",
            news: {
                name: "افق"
            },
            status_name: "در انتظار پرداخت"
        },
        {
            id: 1,
            title: "کمپین تست",
            news: {
                name: "افق"
            },
            status_name: "لغو شده"
        },
    ]

    const SHOW_EDIT_REPORTAGE = ["در انتظار ایجاد", "رد شده"];

    const ORDER_BY= [
        {
            label : "پیش فرض",
            value : "پیش فرض",
        },
        {
            label : "در انتظار پرداخت",
            value : "در انتظار پرداخت",
        },
        {
            label : "در انتظار ایجاد ",
            value : "در انتظار ایجاد ",
        },
        {
            label : "درحال بررسی ",
            value : "درحال بررسی ",
        },
        {
            label : "منتشرشده",
            value : "منتشرشده",
        },
        {
            label : "رد شده",
            value : "رد شده",
        },
    ]

    const [ReportageData, setReportageData] = useState({
        loading: true,
        data: {}
    })
    const [ShowMoreOption, setShowMoreOption] = useState(-1);

    const [ActiveFilters, setActiveFilters] = useState({
        searchText: "",
        status: "all",
    })

    const [CurrentPage, setCurrentPage] = useState(1);
    const [PerPage, setPerPage] = useState(12)

    const handleGetCampaign = () => {
        setShowMoreOption(-1);
        getAllCampaign({
            page : CurrentPage,
            perPage : PerPage,
            // status : ActiveFilters.status,
            type:"backlink"
        }).then(({ data }) => {
            setReportageData({

                loading: false,
                data

            })

            $(".itemSelectCountInSelectBox").text(data?.meta?.total || 0)

        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetCampaign();
        dispatch(setCurrentPageTitle_action("کمپین های بک لینک"));
    }, [])


    return (
        <>
            <Helmet>
                <title>
                    کمپین های بک لینک
                </title>
            </Helmet>

            <div className="d-flex justify-content-between mb-3">

                <div className="d-flex align-items-center gap-2">
                    <div className="reportageSearchBox inMyReportagePage">
                        <input
                            type="text"
                            className="siteTempInput"
                            placeholder="جستجو براساس کدکمپین، عنوان کمپین، وبسایت ها"
                            onChange={e => setActiveFilters({ ...ActiveFilters, searchText: e.target.value })}
                        />
                        <div className="reportageSearchImage"
                        // onClick={handleGetReportages}
                        >
                            <img src="/assets/images/search.svg" alt="" />
                        </div>
                    </div>

                    <Select
                        defaultValue={ORDER_BY[0]}
                        options={ORDER_BY}
                        classNamePrefix="react-select"
                        className="react-select-temp"
                        components={{ Control , SingleValue }}
                        styles={{
                            ...customSelectStyle,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                borderRadius: 8,
                                fontSize: 13,
                                color: "#7E8692",
                                boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                padding: "7px 12px",
                            }),
                            dropdownIndicator:indicatorContainerBg,
                        }}
                        onChange={(newValue) => setActiveFilters({ ...ActiveFilters, status: newValue.value })}
                    />

                </div>

                <div className="d-flex gap-2">
                    <div className="siteTempBtn blueBg radius-10 noHover">
                        <img src="/assets/images/excel.svg" alt="" />
                        <div>
                            خروجی اکسل
                        </div>
                    </div>
                    <Link to={routes.reportage.create} className="d-flex">
                        <div className="siteTempBtn radius-10 my-0">
                            <img src="/assets/images/plus-withBg.svg" alt="" />
                            <div className='hideInMiniSidebar'>ساخت کمپین بک لینک جدید</div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="customBox fullHeightTableInPage">
                <BacklinkCampaignListTable 
                    tableData={ReportageData.data}
                    loading={ReportageData.loading}
                    currentPage={CurrentPage}
                    setCurrentPage={setCurrentPage}
                    perPage={PerPage}
                    setPerPage={setPerPage}
                    showFooter={true}
                />

            </div>
        </>
    );
}

export default MyBackLinkCampaignListPage;