import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";
import { LoadingData } from "../../../components/loading";
import { Link, useSearchParams } from "react-router-dom";
import { verifyOrder } from "../../../service/cart";
import { getUserCartAction } from "../../../store/action/cart_action";
import { routes } from "../../../routes";
import { getUserBacklinkCartAction } from "../../../store/action/backlink_cart_action";
import { getUserForeignReportageCartAction } from "../../../store/action/foreign_reportage_cart_action";
import { getUserDataAction } from "../../../store/action/user_action";
import { checkDepositWalletOnline } from "../../../service/financial";

const PayDepositPage = () => {

    const dispatch = useDispatch();

    let [searchParams] = useSearchParams();

    const [Loading, setLoading] = useState(true);
    const [PaymentStatus, setPaymentStatus] = useState("error")
    const [PayItemType, setPayItemType] = useState("reportage") //reportage , backlink
    const [PayItemName, setPayItemName] = useState("")

    const handleShowVeriryPaymentModal = () => {
        setLoading(true)
        checkDepositWalletOnline(searchParams.get("trackId")).then(({ data }) => {
            // setShowPaymentModal(true)
            dispatch(getUserDataAction());
            // toast.dismiss(loading)
            // setTimeout(() => {
            //     setShowPaymentModal(false)
            //     navigate({
            //         pathname: routes.dashboard,
            //         search: '',
            //     });
            // }, 3000);
            setLoading(false)
            setPaymentStatus("success");
        }).catch((err) => {
            console.log(err)
            setPaymentStatus("error");
            setLoading(false)
            // toast.update(loading, {
            //     render: "پرداخت به مشکل خورده است ، بعدا تلاش کنید",
            //     type: "error"
            // })
        })
    }

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("بررسی پرداخت"))
        // setPayItemType(localStorage.getItem("payItemType") || "reportage")
        // setPayItemName(localStorage.getItem("payItemName") || null)
        if (searchParams.get("trackId") && searchParams.get("orderId")) {
            handleShowVeriryPaymentModal()
        }
    }, [])

    return (
        <>
            <div className="customBox maxHeightOfPage d-flex align-items-center justify-content-center">
                <div className="checkingPaymentMainBox">

                    {Loading ?
                        <>
                            <div className="checkingPaymentLoadingBox">
                                <LoadingData />

                                <div className="checkingPaymentLoadingTitle">
                                    <span>در حال بررسی پرداخت شما به شماره </span>
                                    <span>{searchParams.get("trackId")}</span>
                                    <span> هستیم ، لطفا منتظر بمونید... </span>
                                </div>

                            </div>
                        </>
                        :
                        <>

                            {PaymentStatus === "success" ?
                                <>
                                    <div className="text-center depositPayBox">
                                        <img src="/assets/images/success.png" alt="" />
                                        <div className="siteTempTitle text-success">
                                            <span>
                                                شارژ حساب 
                                            </span>
                                            {/* {PayItemName ?
                                                <span> ({PayItemName}) </span>

                                                :

                                                <span> به شماره {searchParams.get("trackId")} </span>
                                            } */}
                                            <span> با موفقیت انجام شد </span>
                                        </div>
{/* 
                                        <div className="payPageText">
                                            شما می توانید هم اکنون رپورتاژ خود را منتشر بفرمایید و یا در لیست "رپورتاژ های من" در آینده اقدام به ثبت رپورتاژ موردنظر خود نمایید.
                                        </div> */}

                                        <div className="payPageFooter d-flex justify-content-center gap-1 mt-3">
                                            <Link to={routes.dashboard} className="">
                                                <div className="siteTempBtn">
                                                    بازگشت به صفحه اصلی
                                                </div>
                                            </Link>
                                        </div>


                                    </div>
                                </>
                                :
                                <>
                                    <div className="text-center depositPayBox error">
                                        {/* <img src="/assets/images/success.png" alt="" /> */}
                                        <div className="siteTempTitle text-danger">
                                            <span>
                                                شارژ حساب با خطا مواجه شد !
                                            </span>
                                            {/* <span> {searchParams.get("trackId")} </span>
                                            <span> با خطا مواجه شد </span> */}
                                        </div>

                                        <div className="payPageText">
                                            فرایند پرداخت موفقیت آمیز نبود لطفا دوباره تلاش کنید
                                        </div>

                                        <div className="d-flex">
                                            <Link to={routes.financial.onlinePayment} className="mx-auto mt-3">
                                                <div className="siteTempBtn">
                                                    تلاش دوباره
                                                </div>
                                            </Link>
                                        </div>


                                    </div>
                                </>
                            }

                        </>
                    }

                </div>
            </div>
        </>
    );
}

export default PayDepositPage;