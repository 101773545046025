import { useEffect, useState } from "react";

const SitePagination = ({
    showNav = false,
    currentPage = 1 ,
    setCurrentPage = () => null,
    perPage = 10,
    totalItems ,
    position = "center",
}) => {

    const [CalcTotalPages , setCalcTotalPages] = useState(1);

    useEffect(() => {
        setCalcTotalPages(Math.ceil(totalItems / perPage));
    },[totalItems , perPage])


    return ( 
        <>
            <div className={"sitePaginationMainBox" + 
                (position === "left" ? " justify-content-end" : position === "right" ? " justify-content-start" : "")
            }>
                {(showNav && currentPage !== 1) &&
                    <div className="sitePaginationCard prev"
                        onClick={() => setCurrentPage(currentPage - 1)}
                    ></div>
                }

                {Array(CalcTotalPages).fill("").map((item , index) => 
                    
                    <div 
                        className={"sitePaginationCard" + (currentPage === index + 1 ? " active" : "")} 
                        onClick={() => setCurrentPage(index + 1)}
                    >
                        {index + 1}
                    </div>
                
                )}

                {(showNav && CalcTotalPages !== currentPage) &&
                    <div className="sitePaginationCard next"
                        onClick={() => setCurrentPage(currentPage + 1)}
                    ></div>
                }
            </div>
        </>
    );
}
 
export default SitePagination;