import { motion } from "framer-motion"

const SearchNoResult = () => {
    return (
        <>
            <motion.div
                className="searchNoResultMainBox"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                        type: "spring",
                        damping: 10,
                        stiffness: 100,
                        restDelta: 0.001
                    }
                }}
            >
                <img src="/assets/images/searchNoResult.svg" alt="" />
                <div className="siteTempTitle searchNoResultTitle">
                    جستجوی شما نتیجه ای نداشت
                </div>
                <div className="searchNoResultText">
                    فیلتر های فعال خود را بررسی کنید
                    <br />
                    و یا برای جستجوی رسانه از عبارات متداول تر استفاده نمایید
                </div>
            </motion.div>
        </>
    );
}

export default SearchNoResult;