
const initialState = []

export const breadcrumbReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BREADCRUMBS': {
            return [
                ...action.payload
            ]
        }
        
        default:
            return state
    }
}