import { customAxios } from "./axiosSetup";

export const getAllTicket = (params) => customAxios.get(`/tickets`,{params});

export const getSingleTicket = (id) => customAxios.get(`/tickets/${id}`);

export const getTicketType = () => customAxios.get("/ticket_type");

export const createTicket = (data) => customAxios.post("/tickets" , data , {
    headers:{ "Content-Type": "multipart/form-data" }
});

export const sendNewMessageInTicket = (id , data) => customAxios.post("/tickets/" + id , data , {
    headers:{ "Content-Type": "multipart/form-data" }
});