import { getUserCart } from "../../service/cart";
import { groupBy } from "../../utilities";

const groupCartAction = (cartArr) =>{
    // let result = Object.groupBy(cartArr, ({ reportage }) => backlink?.id);
    let result = groupBy(cartArr, ({ backlink }) => backlink?.id);

    for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
            const element = result[key];
            result[key] = groupBy(element, ({ backlink_price }) => backlink_price?.month);
        }
    }

    // return cartArr.reduce((acc, item) => {
    //     const key = ({ reportage }) => backlink?.id;
    //     acc[key] = acc[key] || [];
    //     acc[key].push(item);
    //     return acc;
    //   }, {});

    console.log({result})

    return result;
}

export const emptyBacklinkCartAction = () =>{
    return dispatch =>{
        dispatch({
            type : "EMPTY_BACKLINK_CART"
        })
    }
} 

export const getUserBacklinkCartAction = () => {
    return async dispath => {
        const {data} = await getUserCart("backlink");

        let newCart = {
            ...data,
            carts:groupCartAction(data?.carts || [])
        }

        dispath({
            type : "INIT_BACKLINK_CART",
            // payload : data
            payload : newCart
        })
    }
}

export const addToBacklinkCartBeforApiAction = (data) => {
    return (dispath , getStore) => {
        const {backlink_cart} = getStore();

        let helpObj = backlink_cart?.carts || {};

        console.log({data , helpObj})

        if(helpObj[data?.backlink?.id]?.[data?.backlink_price?.month]){
            helpObj?.[data?.backlink?.id]?.[data?.backlink_price?.month]?.unshift(data);
        }else{
            if(helpObj[data?.backlink?.id]){
                helpObj[data?.backlink?.id][data?.backlink_price?.month]=[data]
            }else{

                helpObj[data?.backlink?.id]={[data?.backlink_price?.month] : [data]}
            }
        }

        // helpArr.push(data)

        dispath({
            type : "SET_BACKLINK_CART",
            payload : helpObj
        })
    }
}

export const addToBacklinkCartAction = (data) => {
    return (dispath , getStore) => {
        const {backlink_cart} = getStore();

        let helpObj = backlink_cart?.carts || {};

        
        if(helpObj[data?.backlink?.id][data?.backlink_price?.month]){
            helpObj[data?.backlink?.id][data?.backlink_price?.month].shift();
            helpObj[data?.backlink?.id][data?.backlink_price?.month].unshift(data);
        }else{
            if(helpObj[data?.backlink?.id]){
                helpObj[data?.backlink?.id][data?.backlink_price?.month]=[data]
            }else{

                helpObj[data?.backlink?.id]={[data?.backlink_price?.month] : [data]}
            }
        }


        dispath({
            type : "SET_BACKLINK_CART",
            payload : helpObj
        })
    }
}

export const removeFromBacklinkCartBeforeApiAction = (cartItem) => {
    return (dispath , getStore) => {
        const {backlink_cart} = getStore();

        let helpObj = backlink_cart?.carts || {};

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)

        if(helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month]){
            if(helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month]?.length > 1){
                helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month].shift();
            }else{
                delete helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_BACKLINK_CART",
            payload : helpObj
        })
    }
}

export const removeFromBacklinkCartAction = (cartItem) => {
    return (dispath , getStore) => {
        const {backlink_cart} = getStore();

        let helpObj = backlink_cart?.carts || {};

        // helpObj = helpObj.filter( a => a?.id !== cartItem?.id)

        if(helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month]){
            if(helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month]?.length > 1){
                helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month]?.shift();
            }else{
                delete helpObj[cartItem?.backlink?.id]?.[cartItem?.backlink_price?.month];
            }
        }

        // helpObj.push(data)

        dispath({
            type : "SET_BACKLINK_CART",
            payload : helpObj
        })
    }
}
