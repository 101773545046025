import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import $ from "jquery";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";

import InputTemplate from "../../../components/formComponents/inputComponent";
import LoadingButton from "../../../components/loadingButton";

import { useSelector } from "react-redux";
import { updateUserData } from "../../../service/auth";
import { getUserDataAction } from "../../../store/action/user_action";
import { toast } from "react-toastify";

const ProfileUserInfoPage = () => {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);

  const [DisableInputs, setDisableInputs] = useState({
    name: true,
    lastName: true,
    phone: true,
    homePhone: true,
  })

  const [FormValues, setFormValues] = useState({
    name: "",
    lastName: "",
    phone: "",
    homePhone: "",
    email : "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  })

  const [IsSubmitting, setIsSubmitting] = useState(false);
  const [IsSubmittingPassword, setIsSubmittingPassword] = useState(false);

  const handleUpdateUserInfo = async () => {

    if(FormValues?.name?.trim() === ""){
      toast.error("نام را وارد کنید!")
      return
    }

    if(FormValues?.lastName?.trim() === ""){
      toast.error("نام خانوادگی را وارد کنید!")
      return
    }

    // if(FormValues?.email?.trim() === ""){
    //   toast.error("ایمیل را وارد کنید!")
    //   return
    // }

    if(FormValues?.phone?.toString()?.trim() === ""){
      toast.error("شماره تلفن حساب را وارد کنید!")
      return
    }

    if(FormValues?.homePhone?.toString()?.trim() === ""){
      toast.error("شماره تلفن ثابت را وارد کنید!")
      return
    }


    let sendData = {
      "first_name": FormValues?.name,
      "last_name": FormValues?.lastName,
      "email": FormValues?.email,
      "phone": FormValues?.phone,
      "house_phone": FormValues?.homePhone,
    }

    setIsSubmitting(true)
    updateUserData(sendData).then(async ({data}) => {
      await dispatch(getUserDataAction())
      setIsSubmitting(false)
      toast.success("مشخصات شما با موفقیت به روز شد")
    }).catch((err) => {
      console.log(err)
      setIsSubmitting(false)
    })
  }

  const handleUpdateUserPassword = async () => {

    if(FormValues?.oldPassword?.trim() === ""){
      toast.error("رمزعبور فعلی را وارد کنید!")
      return
    }

    if(FormValues?.newPassword?.trim() === ""){
      toast.error("رمزعبور جدید را وارد کنید!")
      return
    }

    if(FormValues?.confirmPassword?.trim() === ""){
      toast.error("تکرار رمزعبور جدید را وارد کنید!")
      return
    }

    if(FormValues?.confirmPassword?.trim() === FormValues?.newPassword?.trim()){
      toast.error("رمز عبور جدید و تکرار آن با یکدیگر برابر نیستند!")
      return
    }


    let sendData = {
      "first_name": FormValues?.name,
      "last_name": FormValues?.lastName,
      "email": FormValues?.email,
      "phone": FormValues?.phone,
      "house_phone": FormValues?.homePhone,
      "current_password": FormValues?.oldPassword,
      "new_password": FormValues?.newPassword
    }

    setIsSubmittingPassword(true)
    updateUserData(sendData).then(async ({data}) => {
      await dispatch(getUserDataAction())
      setIsSubmittingPassword(false)
      toast.success("رمزعبور شما با موفقیت به روز شد")
    }).catch((err) => {
      console.log(err)
      setIsSubmittingPassword(false)
    })
  }

  const handleActiveInputByName = (key) => {
    setDisableInputs({ ...DisableInputs, [key]: false })
    $(`input[name='${key}']`).focus();
  }

  useEffect(() => {
    setFormValues({
      ...FormValues,
      name: user?.first_name,
      lastName: user?.last_name,
      phone: user?.phone,
      email: user?.email,
      homePhone: user?.house_phone,
    })
  }, [user]);

  useEffect(() => {
    dispatch(setCurrentPageTitle_action("پروفایل کاربری"));
  }, []);

  return (
    <>
      <div className="customBox">
        {/* Banner Section */}
        <div className="userProfileBannerSection">
          <h1 className="userProfileBannerSectionText">پروفایل کاربری</h1>
        </div>
        {/* Modification Sections */}
        <div className="userProfileModificationSection">
          {/* Info Modification Section */}
          <div className="userProfileInfoModificationSection">
            <div className="userProfileInfoModificationSectionTitle">
              ویرایش اطلاعات
            </div>
            <div className="userProfileModificationSectionInputs">
              <div className="userProfileInfoInput">
                <div className="title">نام</div>
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="text"
                  name="name"
                  placeholder="نام"
                  value={FormValues?.name || ""}
                  onChange={e => setFormValues({ ...FormValues, name: e.target.value })}
                  readOnly={DisableInputs?.name}
                />
                <img
                  className="image cursorPointer"
                  src="/assets/images/edit1.svg"
                  alt="editWithBox"
                  onClick={() => handleActiveInputByName("name")}
                />
              </div>
              <div className="userProfileInfoInput">
                <div className="title">شماره تلفن حساب</div>
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  name="phone"
                  type="number"
                  placeholder="09xxxxxxxxxxxx"
                  value={FormValues.phone}
                  onChange={e => setFormValues({ ...FormValues, phone: e.target.value })}
                  readOnly={DisableInputs?.phone}
                />
                <img
                  className="image cursorPointer"
                  src="/assets/images/edit1.svg"
                  alt="editWithBox"
                  onClick={() => handleActiveInputByName("phone")}
                />
              </div>
              <div className="userProfileInfoInput">
                <div className="title">نام خانوادگی</div>
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="text"
                  placeholder="نام خانوادگی"
                  name="lastName"
                  value={FormValues.lastName}
                  onChange={e => setFormValues({ ...FormValues, lastName: e.target.value })}
                  readOnly={DisableInputs?.lastName}
                />
                <img
                  className="image cursorPointer"
                  src="/assets/images/edit1.svg"
                  alt="editWithBox"
                  onClick={() => handleActiveInputByName("lastName")}
                />
              </div>
              <div className="userProfileInfoInput">
                <div className="title">شماره تلفن ثابت</div>
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="number"
                  name="homePhone"
                  placeholder="xxx-xxxxxxxx"
                  value={FormValues.homePhone}
                  onChange={e => setFormValues({ ...FormValues, homePhone: e.target.value })}
                  readOnly={DisableInputs?.homePhone}
                />
                <img
                  className="image cursorPointer"
                  src="/assets/images/edit1.svg"
                  alt="editWithBox"
                  onClick={() => handleActiveInputByName("homePhone")}
                />
              </div>
              <div className="userProfileInfoInput">
                <div className="title">ایمیل</div>
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="text"
                  placeholder="example@gmail.com"
                  value={user.email || ""}
                />
              </div>
              <div className="note">
                شماره تلفن به صورت اختیاری بوده و برای هماهنگی های ضروری استفاده
                خواهد گردید.
              </div>
            </div>

            <div className="userProfileInfoModificationSectionSubmitBtn">
              <div className="siteTempBtn totalPaymentBtn">
                <LoadingButton className="paymentLoadingBtn" loading={IsSubmitting} onClick={handleUpdateUserInfo}>
                  ذخیره اطلاعات
                </LoadingButton>
                <img
                  src="/assets/images/tick-square-white.svg"
                  alt="tick-square-white"
                ></img>
              </div>
            </div>
          </div>
          {/* password Modification Section */}
          <div className="userProfilePasswordModificationSection">
            <div className="userProfilePasswordModificationSectionTitle">
              ویرایش رمز عبور
            </div>
            <div className="userProfileModificationSectionInputs">
              <div className="userProfileInfoInput px-1">
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="password"
                  placeholder="رمز عبور فعلی"
                  onChange={e => setFormValues({ ...FormValues, oldPassword: e.target.value })}
                />
                {/* <img
                  className="image"
                  src="/assets/images/hide.svg"
                  alt="hide password"
                /> */}
              </div>
              <div className="userProfileInfoInput px-1">
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="password"
                  placeholder="زمر عبور جدید"
                  onChange={e => setFormValues({ ...FormValues, newPassword: e.target.value })}
                />
                {/* <img
                  className="image"
                  src="/assets/images/hide.svg"
                  alt="show password"
                /> */}
              </div>
              <div className="forgetPassword">
                رمز عبور خود را فراموش کرده اید؟
              </div>
              <div className="userProfileInfoInput px-1">
                <InputTemplate
                  inputClassName="myCustomizedInput"
                  type="password"
                  placeholder="تکرار رمز عبور را وارد کنید"
                  onChange={e => setFormValues({ ...FormValues, confirmPassword: e.target.value })}
                />
                {/* <img
                  className="image"
                  src="/assets/images/hide.svg"
                  alt="hide password"
                /> */}
              </div>
            </div>
            <div className="userProfilePasswordModificationSectionSubmitBtn passwordSubmitBtn">
              <div className="siteTempBtn totalPaymentBtn">
                <LoadingButton className="paymentLoadingBtn" loading={IsSubmittingPassword} onClick={handleUpdateUserPassword}>
                  تغییر رمز عبور
                </LoadingButton>
                <img
                  src="/assets/images/tick-square-white.svg"
                  alt="tick-square-white"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUserInfoPage;
