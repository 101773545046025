import { customAxios } from "./axiosSetup";

export const getAllCampaign = ({
    page = 1 , 
    perPage = 12,
    type ,
    status,
    searchText , 
}) => {
    let link = `/client/campaign?page=${page}&perPage=${perPage}` ; 

    if(searchText && searchText.trim() !== ""){
        link += `&search=${searchText}`
    }

    if(type && type != -1){
        link += `&type=${type}`
    }

    if(status != undefined && status != -1){
        link += `&status=${status}`
    }
    
    return customAxios.get(link)
};

export const getSingleCampaign = (id) => customAxios.get(`/client/campaign/${id}`);

export const getCampaignStatus = () => customAxios.get(`/status/campaign`);

export const deleteCampaign = (id) => customAxios.delete(`/client/campaign/${id}`);