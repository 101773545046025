import { useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import jMoment from "moment-jalaali";
import { Link } from 'react-router-dom';
import SitePagination from "../pagination";
import { routes } from "../../routes";
import ReportStatus from "../reportStatus";
import { perPageArr } from "../../utilities/config";
import { perPageControl, customSelectStyle } from "../reactSelectComponens";
import { LoadingData } from "../loading";
import NoDataComponent from "../noData";
import setupMomentJalaali from "../../utilities/setupMomentJalaali";

const BacklinkListTableRow = ({
    backlink = {},
    handleShowReason = () => null,
}) => {

    setupMomentJalaali();

    const rowRef = useRef(null);

    const SHOW_EDIT_REPORTAGE = ["در انتظار انتشار", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(false);

    function handleClickOutside(event) {
        if (rowRef.current && !rowRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
    }

    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMoreOption]);

    return (
        <>
            <tr className="position-relative" ref={rowRef}>
                <td>
                    <div className="reportageCodeBox">
                        {backlink?.id}
                    </div>
                </td>
                <td>
                    {/* <div className={"" + (index === 0 ? " publishedReportageTitle" : "")}> */}
                    <div className={""}>
                        {(backlink?.key_words || "[]").replace(/\[|\]|"/g, '').replaceAll(',', ' - ') || "ثبت نشده"}
                    </div>
                </td>
                <td className="text-center">
                    {backlink?.backlink_price?.month} ماهه
                </td>
                <td className="text-center">
                    {backlink?.backlink?.news?.name}
                </td>
                <td className="text-center">
                    {(backlink?.publish_suggest && jMoment(backlink?.publish_suggest).format("dddd jD jMMMM jYYYY")) || "ثبت نشده"}
                </td>
                <td className="text-center">
                    <ReportStatus reportStatus={backlink?.status_name} withBox={true} handleShowReason={() => handleShowReason(backlink)}/>
                </td>
                <td className="text-center">
                    {backlink?.backlink?.insert_place?.title}
                </td>
                <td className="text-center">
                    {(backlink?.publish_at && jMoment(backlink?.publish_at).format("dddd jD jMMMM jYYYY")) || "منتشر نشده"}
                </td>
                <td className="text-center">
                    {(backlink?.expiry_date && jMoment(backlink?.expiry_date).format("dddd jD jMMMM jYYYY")) || "منتشر نشده"}
                </td>
                <td>
                    <div className="customFlexBox justify-content-end gap-2">

                        {SHOW_EDIT_REPORTAGE.indexOf(backlink?.status_name) > -1 &&

                            <>
                                <Link to={routes.backLink.create(backlink?.campaign?.id) + `?backlink=${backlink?.id}&edit=true`}>

                                    <div className="siteTempBtn tableBtn "

                                    >
                                        <img src="/assets/images/edit1.svg" alt="" />
                                        <div>ویرایش</div>
                                    </div>
                                </Link>
                            </>

                        }

                        {["در انتظار ایجاد"].indexOf(backlink?.status_name) > -1 &&

                            <>
                                <Link to={routes.backLink.create(backlink?.campaign?.id) + `?backlink=${backlink?.id}`}>

                                    <div className="siteTempBtn tableBtn "

                                    >
                                        <img src="/assets/images/document-upload.svg" alt="" />
                                        <div>ثبت بک لینک</div>
                                    </div>
                                </Link>
                            </>

                        }

                        <div className="siteTempBtn design1 _3dotBtn width-fit"
                            onClick={() => setShowMoreOption(!ShowMoreOption)}
                        >
                            <img src="/assets/images/3dot.svg" alt="" />
                        </div>
                        <div className={"table3dotMainBox" + (ShowMoreOption ? " show" : "")}>
                            <Link to={routes.ticket.add}>
                                <div className="table3dotCard">
                                    <img src="/assets/images/call.svg" alt="" />
                                    <div>پشتیبانی</div>
                                </div>
                            </Link>
                            {/* <Link to={routes.backLink.campaigns}>
                                <div className="table3dotCard">
                                    <img src="/assets/images/eye.svg" alt="" />
                                    <div>مشاهده کمپین</div>
                                </div>
                            </Link> */}
                        </div>

                    </div>
                </td>
            </tr>
        </>
    );
}

const BacklinkListTable = ({
    tableData,
    loading = false,
    showFooter = true,
    currentPage = 1,
    setCurrentPage = () => null,
    perPage = 10,
    setPerPage = () => null,
    handleShowReason = () => null,
}) => {

    const rowRef = useRef(null);

    const SHOW_EDIT_BACKLINK = ["در انتظار انتشار", "رد شده"];

    const [ShowMoreOption, setShowMoreOption] = useState(false);

    function handleClickOutside(event) {
        if (rowRef.current && !rowRef.current.contains(event.target)) {
            setShowMoreOption(false);
        }
    }

    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowMoreOption]);

    return (
        <>
            <div className="siteTempTableBox">
                <div className="table-responsive">
                    <table className="table showFullSize siteTempTable">
                        <tr>
                            <th>کد بک لینک</th>
                            <th>کلمه کلیدی </th>
                            <th className="text-center">مدت زمان</th>
                            <th className="text-center">رسانه</th>
                            <th className="text-center">تاریخ پیشنهادی انتشار</th>
                            <th className="text-center">وضعیت</th>
                            <th className="text-center">محل درج</th>
                            <th className="text-center">تاریخ  انتشار</th>
                            <th className="text-center">تاریخ  انقضا</th>
                            <th className="text-center">اقدامات</th>
                        </tr>
                        {/* {ReportageData.data?.data?.map((reportage, index) => */}
                        {tableData?.data?.map((backlink, index) =>

                            <>
                                <BacklinkListTableRow
                                    key={`backlink-${index}`}
                                    backlink={backlink}
                                    handleShowReason={handleShowReason}
                                />
                            </>

                        )}



                    </table>

                    {loading &&
                        <>
                            <div className="d-flex align-items-center justify-content-center my-3">
                                <LoadingData />
                            </div>
                        </>
                    }
                    {(tableData?.data?.length === 0 && loading === false) &&
                        <>
                            <NoDataComponent
                                text={"شما بک لینکی برای نمایش ندارید!"}
                            />
                        </>
                    }
                </div>
            </div>

            {showFooter &&
                <>
                    <div className="siteTempTableFooter mt-3">


                        <Select
                            defaultValue={perPageArr[0]}
                            options={perPageArr}
                            classNamePrefix="react-select"
                            className="react-select-temp"
                            components={{
                                Control: perPageControl
                            }}
                            styles={{
                                ...customSelectStyle,
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#EBEBEB' : '#EBEBEB',
                                    borderRadius: 8,
                                    fontSize: 13,
                                    color: "#7E8692",
                                    boxShadow: "0px 1px 11px 0px rgba(212, 212, 212, 0.25)",
                                    padding: "7px 12px",
                                })
                            }}
                            onChange={(newValue) => setPerPage(newValue.value)}
                        />

                        <SitePagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalItems={tableData?.meta?.total || 0}
                            perPage={perPage}
                        />
                    </div>
                </>
            }
        </>
    );
}

export default BacklinkListTable;