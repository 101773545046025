import { Link } from "react-router-dom";
import $ from 'jquery';
import { routes } from "../../../routes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../../store/action/app_action";

const CreateCampaignPage = () => {

    const CAMPAIGN_TYPE =[
        {
            image : "/assets/images/document-transparent.png",
            title : "رپورتاژ آگهی",
            desc : "به کمک رپورتاژ آگهی می‌تونید در قالب یک محتوای کاربردی از هر رسانه‌ای که می‌خواید، لینک دائمی دریافت کنید و سایتتون رو به رتبه‌های برتر صفحه نتایج گوگل برسونید.",
            buttonTitle : "ساخت کمپین رپورتاژ",
            link : routes.reportage.list,
            className : "addReportage",
        },
        {
            image : "/assets/images/backLink.png",
            title : "بک لینک",
            desc : "به کمک رپورتاژ آگهی می‌تونید در قالب یک محتوای کاربردی از هر رسانه‌ای که می‌خواید، لینک دائمی دریافت کنید و سایتتون رو به رتبه‌های برتر صفحه نتایج گوگل برسونید.",
            buttonTitle : "ساخت کمپین بک لینک",
            link : routes.backLink.list,
            className : "addBackLink",
        },
        {
            image : "/assets/images/document-edit-1.png",
            title : "تولید محتوا",
            desc : "به کمک رپورتاژ آگهی می‌تونید در قالب یک محتوای کاربردی از هر رسانه‌ای که می‌خواید، لینک دائمی دریافت کنید و سایتتون رو به رتبه‌های برتر صفحه نتایج گوگل برسونید.",
            buttonTitle : "سفارش تولید محتوا",
            link : routes.createContent.root,
            className : "addContent",
        },
    ]

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(setCurrentPageTitle_action("ساخت کمپین جدید"))
    },[])


    return ( 
        <>
            <div 
                className="customBox"
                style={{
                    minHeight:`calc(100vh - 120px)`,
                    display:"flex",
                    alignItems :"center",
                    backgroundColor:"transparent",
                }}
            >
                <div className="createCampaignMainBox">

                    {CAMPAIGN_TYPE.map(campaign => 

                        <>

                            <Link to={campaign.link}>

                                <div 
                                    className={"createCampaignCard " + campaign.className}
                                >
                                    <div className="createCampaignCardImage">
                                        <img src={campaign.image} alt="" />
                                    </div>

                                    <div className="createCampaignCardTitle my-3">
                                        {campaign.title}
                                    </div>

                                    <div className="createCampaignCardDesc mb-2">{campaign.desc}</div>

                                    <div className="siteTempBtn">
                                        {campaign.buttonTitle}
                                    </div>

                                </div>

                            </Link>
                        

                        </>
                    
                    )}

                </div>
            </div>
        </>
    );
}
 
export default CreateCampaignPage;