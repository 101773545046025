import { Helmet } from "react-helmet-async";
import jMoment from 'moment-jalaali';
import TitleWithCircle from "../../components/title";
import CheckboxTemplate from "../../components/formComponents/checkboxComponent";
import { useEffect, useState } from "react";
import SiteAlert from "../../components/alert";
import { Link, useNavigate } from "react-router-dom";
import InputTemplate from "../../components/formComponents/inputComponent";
import SiteModal from "../../components/siteModal";
import MiniCartCompoment from "../../components/cart";
import { getPaymentMethods, payOrderApi } from "../../service/cart";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPageTitle_action, setStepItemInHeader } from "../../store/action/app_action";
import { toast } from "react-toastify";
import { Interweave } from "interweave";
import { LoadingData } from "../../components/loading";
import { handleGenerateLink } from "../../utilities";
import { getReportageData } from "../../service/reportage";
import { routes } from "../../routes";
import { getUserCartAction } from "../../store/action/cart_action";
import { getUserDataAction } from "../../store/action/user_action";
import ReportageDetailModal from "../../components/reportage/detailModal";
import { getUserBacklinkCartAction } from "../../store/action/backlink_cart_action";
import BacklinkMiniCartCompoment from "../../components/cart/backlink_cart";
import { getUserForeignReportageCartAction } from "../../store/action/foreign_reportage_cart_action";
import ForeignReportageMiniCartCompoment from "../../components/cart/foreign_reportage_cart";
import PaymentTypeComponent from "../../components/cart/paymentType";
import DiscountCodeComponent from "../../components/discountCode";

const ForeignReportageCartListPage = () => {

    const pageStep = [
        {
            title: "انتخاب وبسایت ها",
            status: "pass",
        },
        {
            title: "پرداخت کمپین",
            status: "current",
        },
        {
            title: "تایید نهایی کمپین",
            status: "none",
        },
    ]

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userCart = useSelector(store => store.foreign_reportage_cart)

    const [PaymentMethodSelected, setPaymentMethodSelected] = useState({
        index: -1,
        title: ""
    }); //wallet , online , receipt
    const [ShowDiscountInput, setShowDiscountInput] = useState(false);
    const [ShowModalAccount, setShowModalAccount] = useState(false);
    const [PaymentMethods, setPaymentMethods] = useState({
        loading: true,
        data: []
    })
    const [PayOrderLoading, setPayOrderLoading] = useState(false);
    const [CartFormData, setCartFormData] = useState({
        campainName: "کمپین رپورتاژ " + jMoment(new Date()).format("jD jMMMM jYYYY - HH:MM"),
        discountCode: "",
    })
    const [AcceptRoles, setAcceptRoles] = useState(false)
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })
    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        selectedId: -1,
    });
    const [ShowVitrinAdLawModal, setShowVitrinAdLawModal] = useState(false);

    const [ReceiptFile, setReceiptFile] = useState(null);

    const handleGetPaymentMethods = () => {
        getPaymentMethods().then(({ data }) => {

            let helpArr = []
            let selectedPayment = {
                index: -1,
                title: ""
            }

            for (let i = 0; i < data.length; i++) {
                const element = data[i];

                helpArr.push({
                    title: element?.name,
                    description: element?.description,
                    payIndex: i,
                    isEnable: element?.isEnable || false,
                    image: element?.name === "کارت به کارت" ? "/assets/images/document.svg" :
                        element?.name === "درگاه پرداخت" ? "/assets/images/card.svg" :
                            element?.name === "برداشت از موجودی" ? "/assets/images/wallet.svg" : "",

                })


                if (element?.isEnable && selectedPayment.index === -1) {
                    selectedPayment = {
                        index: i,
                        title: helpArr[i].title
                    }

                }
            }


            setPaymentMethodSelected(selectedPayment)
            setPaymentMethods({
                loading: false,
                data: helpArr
            })

        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetOneReportage = (reportageId) => {

        // console.log(ShowReportageModal.show)

        if (reportageId === -1 || ReportageSingleData.loading || !ShowReportageModal.show) return

        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getReportageData(reportageId).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    const handlePayOrder = ({
        payLater = false
    }) => {

        // if(PaymentMethodSelected.index === -1 ) return

        // if (PaymentMethodSelected.index !== 1) {
        //     toast.error("فقط پرداخت به صورت درگاه بانکی قابل دسترس است")
        //     return
        // }

        if (PaymentMethodSelected.index === -1 && !payLater) {
            toast.error("لطفا یک روش پرداخت را انتخاب کنید")
            return
        }

        if (AcceptRoles === false) {
            toast.error("برای پرداخت باید قوانین را بپذیرید")
            return
        }

        if (CartFormData.campainName.trim() === "") {
            toast.error("اسم کمپین نمیتواند خالی باشد")
            return
        }

        if (PaymentMethodSelected?.index == 0 && !ReceiptFile) {
            toast.error("لطفا فیش واریزی خود را آپلود کنید!")
            return
        }

        setPayOrderLoading(true)
        // const sendData = {
        //     type: payLater ? 10 : PaymentMethodSelected.index,
        //     name: CartFormData.campainName,
        //     order_id: userCart.id
        // }
        const sendData = new FormData();

        sendData.append("type", payLater ? 10 : PaymentMethodSelected.index);
        sendData.append("name", CartFormData.campainName);
        sendData.append("order_id", userCart.id);

        if (PaymentMethodSelected?.index == 0 && !payLater) {
            sendData.append("receipt", ReceiptFile);
            // sendData.receipt = 
        }

        if (userCart?.discount?.code && userCart?.discount?.code !== "") {
            sendData.append("discount_code", userCart?.discount?.code);
        }
        payOrderApi(sendData).then(({ data }) => {

            if (payLater) {

                toast.success(data?.message);
                dispatch(getUserDataAction())
                dispatch(getUserCartAction());
                dispatch(getUserBacklinkCartAction())
                dispatch(getUserForeignReportageCartAction());
                navigate(routes.dashboard)

            } else {
                if (PaymentMethodSelected.title === "درگاه پرداخت") {
                    if (data?.url) {
                        localStorage.setItem("payItemName", CartFormData.campainName)
                        window.location.href = data?.url
                    } else {
                        toast.error("در پرداخت مشکلی پیش آمده است")
                    }
                }

                if (
                    PaymentMethodSelected.title === "برداشت از موجودی" ||
                    PaymentMethodSelected.index == 0
                ) {
                    toast.success(data?.message);
                    dispatch(getUserDataAction())
                    dispatch(getUserCartAction());
                    dispatch(getUserBacklinkCartAction())
                    dispatch(getUserForeignReportageCartAction());
                    if (PaymentMethodSelected.title === "برداشت از موجودی") {
                        localStorage.setItem("campaign", JSON.stringify(data?.campaign))

                        localStorage.setItem("payItemName", CartFormData.campainName)
                        navigate(routes.cart.pay + `?walletPay=true`)
                    } else {
                        navigate(routes.dashboard)
                    }
                }
            }




            setPayOrderLoading(false)
        }).catch((err) => {
            setPayOrderLoading(false)
            toast.error(err.data?.error);
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetOneReportage(ShowReportageModal.selectedId);
    }, [ShowReportageModal.show])

    useEffect(() => {
        handleGetPaymentMethods();
        setTimeout(() => {
            dispatch(setCurrentPageTitle_action("قوانین و پرداخت"))
            dispatch(setStepItemInHeader(pageStep))
        }, 10)
    }, [])

    return (
        <>

            <Helmet>
                <title>
                    سبد خرید
                </title>
            </Helmet>

            <SiteModal
                modalClass={"modalDesign3"}
                showModal={ShowModalAccount}
                closeModal={() => setShowModalAccount(false)}
            >
                <div className="siteTempModalTitle mb-5">
                    اطلاعات حساب جهت واریز کارت به کارت
                </div>
                
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <div>برای دریافت اطلاعات حساب جهت واریز، لطفا تیکت ارسال فرمایید</div>
                </div>

                {/* <div className="d-flex align-items-center justify-content-between mb-3">
                    <div>شماره کارت:</div>
                    <div style={{direction : "ltr"}}>6104 3386 2183 5269</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div>شماره شبا:</div>
                    <div style={{direction : "ltr"}}>IR070120000000009813060163</div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>صاحب حساب:</div>
                    <div>مهشاد برومند خوراسگانی</div>
                </div> */}


                <div className="text-primaryColor text-center pe-2 mt-3">
                    پس از واریز تصویر را بخش مربوطه آپلود بفرمایید
                </div>


            </SiteModal>

            <SiteModal
                modalDesign={2}
                title={"رپورتاژ خبر"}
                showModal={ShowReportageModal.show}
                closeModal={() => setShowReportageModal({ ...ShowReportageModal, show: false })}
            >

                {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>
                        <ReportageDetailModal
                            reportageData={ReportageSingleData.data}
                            justLaw={true}
                            isForeign={true}
                        />
                    </>
                }


            </SiteModal>

            <SiteModal
                modalDesign={2}
                title={"قوانین"}
                showModal={ShowVitrinAdLawModal}
                closeModal={() => setShowVitrinAdLawModal(false)}
            >

                <div className="siteTempTitle text-center">
                    قوانین ویترین اد
                </div>


            </SiteModal>

            <div className="d-flex gap-2">
                <div className="customBox hasMiniCart">
                    <div className="cartListMainHeaderBox">
                        <TitleWithCircle
                            title={"نام کمپین"}
                        />
                        <InputTemplate
                            placeholder={"نام کمپین"}
                            value={CartFormData.campainName}
                            onChange={(e) => setCartFormData({ ...CartFormData, campainName: e.target.value })}
                        />
                        {/* <div className="cartListMainHeaderTitle">
                            کمپین رپورتاژ 21 دی 1402 - 00:40"
                        </div> */}
                    </div>


                    <div className="mt-3">


                        <div className="showCartListMainBox">
                            <div className="d-flex">
                                <TitleWithCircle title={"تایید قوانین"} />
                                <div className="siteTempBtn greenBg me-auto mb-3">
                                    <img src="/assets/images/excel.svg" alt="" srcset="" />
                                    <div>خروجی اکسل</div>
                                </div>
                            </div>


                            {Object.keys(userCart?.carts).map((key, index) =>
                                <>

                                    <div className="showCartListCard">
                                        <div className="showCartListCardHeader">
                                            <div className="showCartListCardImage">
                                                <img src={userCart?.carts[key]?.[0]?.reportage?.news?.image || "/assets/images/alef.png"} alt="" />
                                            </div>
                                            <div className="showCartListCardTitle">
                                                {userCart?.carts[key]?.[0]?.reportage?.title}
                                            </div>
                                            <div className="showCartListCardLink">
                                                {handleGenerateLink(userCart?.carts[key]?.[0]?.reportage?.news?.site)}
                                            </div>
                                        </div>

                                        <div className="showCartListCardBody">
                                            <div className="showCartListCardShortDescBox">
                                                <div className="showCartListCardShortDescCard">
                                                    <div className="showCartListCardShortDescCardImage">
                                                        <img src="/assets/images/image.svg" alt="" />
                                                    </div>
                                                    <div className="showCartListCardShortDescCardBody">
                                                        <div className="showCartListCardShortDescCardTitle">تصاویر</div>
                                                        <div className="showCartListCardShortDescCardValue">{userCart?.carts[key]?.[0]?.reportage?.info?.image_count || 0}</div>
                                                    </div>
                                                </div>
                                                <div className="showCartListCardShortDescCard">
                                                    <div className="showCartListCardShortDescCardImage">
                                                        <img src="/assets/images/editWithBox.svg" alt="" />
                                                    </div>
                                                    <div className="showCartListCardShortDescCardBody">
                                                        <div className="showCartListCardShortDescCardTitle">کلمات</div>
                                                        <div className="showCartListCardShortDescCardValue">{userCart?.carts[key]?.[0]?.reportage?.info?.max_word || 0}</div>
                                                    </div>
                                                </div>
                                                <div className="showCartListCardShortDescCard">
                                                    <div className="showCartListCardShortDescCardImage">
                                                        <img src="/assets/images/followLink.svg" alt="" />
                                                    </div>
                                                    <div className="showCartListCardShortDescCardBody">
                                                        <div className="showCartListCardShortDescCardTitle">لینک فالو</div>
                                                        <div className="showCartListCardShortDescCardValue">{userCart?.carts[key]?.[0]?.reportage?.info?.follow_link_count || 0}</div>
                                                    </div>
                                                </div>
                                                <div className="showCartListCardShortDescCard">
                                                    <div className="showCartListCardShortDescCardImage">
                                                        <img src="/assets/images/noFollow.svg" alt="" />
                                                    </div>
                                                    <div className="showCartListCardShortDescCardBody">
                                                        <div className="showCartListCardShortDescCardTitle">لینک نوفالو</div>
                                                        <div className="showCartListCardShortDescCardValue">{userCart?.carts[key]?.[0]?.reportage?.info?.nofollow_link_count || 0}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="siteTempBtn blueBg" onClick={() => setShowReportageModal({ show: true, selectedId: key })}>
                                                مطالعه قوانین رسانه
                                            </div>
                                        </div>

                                    </div>
                                </>
                            )}

                            {/* {userCart?.carts?.map((cart, index) =>


                            )} */}

                            <div className="acceptRulesBox">
                                <div className="acceptRulesCheckBox">
                                    <CheckboxTemplate
                                        title={"قوانین انتشار در رسانه ها و قوانین ویترین اد را مطالعه کرده و می پذیرم"}
                                        onChange={e => setAcceptRoles(e.target.checked)}
                                    />
                                </div>
                                <div className="siteTempBtn blueBg" onClick={() => setShowVitrinAdLawModal(true)}>
                                    مطالعه قوانین ویترین اد
                                </div>
                            </div>

                            <div className="text-accentColor pe-2 mt-2">
                                تایید قوانین انتشار در رسانه الزامی می باشد.
                            </div>


                        </div>
                    </div>

                    {/* <div className="cartPageDiscountMainBox">
                        <div className={"siteTempBtn design3 radius-10" + (ShowDiscountInput ? " d-none" : "")} onClick={() => setShowDiscountInput(true)}>کد تخفیف</div>

                        {ShowDiscountInput &&
                            <div className="cartPageDiscountBox">
                                <InputTemplate
                                    placeholder={'کد تخفیف خود را وارد کنید'}
                                />

                                <div className="d-flex gap-2" onClick={() => setShowDiscountInput(false)}>
                                    <div className="siteTempBtn design3">
                                        انصراف
                                    </div>
                                    <div className="siteTempBtn blueBg">
                                        ثبت کد تخفیف
                                    </div>
                                </div>
                            </div>
                        }
                    </div> */}

                    <DiscountCodeComponent />

                    <PaymentTypeComponent
                        PaymentMethodSelected={PaymentMethodSelected}
                        setPaymentMethodSelected={setPaymentMethodSelected}
                        type="reportage"
                        PaymentMethods={PaymentMethods}
                        setShowModalAccount={setShowModalAccount}
                        setReceiptFile={setReceiptFile}
                        ReceiptFile={ReceiptFile}
                    />
                </div>


                {/* <div className="showMiniCartMainBox"> */}
                <ForeignReportageMiniCartCompoment
                    payOrderFunction={handlePayOrder}
                    loading={PayOrderLoading}
                    cartStep={2}
                // className={""}
                />
                {/* </div> */}
            </div>

        </>
    );
}

export default ForeignReportageCartListPage;