import { useEffect, useState } from "react";
import StepPattern from "../../../components/stepPattern";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routes } from "../../../routes";
import SiteAlert from "../../../components/alert";
import InputTemplate from "../../../components/formComponents/inputComponent";
import { Helmet } from "react-helmet-async";
import TagsInput from "react-tagsinput";
import RadioButtonTemplate from "../../../components/formComponents/radioButtonComponent";
import { getSingleCampaign } from "../../../service/campaign";
import { createUserReportage, getReportageData, getUserReportageِDetail, uploadReporatgeFile } from "../../../service/reportage";
import DatePicker from "react-multi-date-picker";
import DatePickerComponent from "../../../components/datePicker";
import { toast } from "react-toastify";
import jMoment from "moment-jalaali";
import $ from "jquery"
import { LoadingData } from "../../../components/loading";
import LoadingButton from "../../../components/loadingButton";
import SiteModal from "../../../components/siteModal";
import ReportageDetailModal from "../../../components/reportage/detailModal";
import NoDataComponent from "../../../components/noData";
import { handleGenerateLink } from "../../../utilities";
import CheckboxTemplate from "../../../components/formComponents/checkboxComponent";
import { createUserBacklink, updateUserBacklink } from "../../../service/backlink";
import { imageThumbUrl } from "../../../utilities/config";


const CreateBacklinkPage = () => {

    const { id } = useParams();

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const editMode = searchParams.get("edit");
    const seletedBacklinkId = searchParams.get("backlink");

    const STEP_ARRAY = [
        {
            title: "انتخاب وبسایت",
            status: "current"
        },
        {
            title: "تنظیمات بک لینک",
            status: "none"
        },
    ]

    const [StepArray, setStepArray] = useState(STEP_ARRAY);
    const [CampaignReport, setCampaignReport] = useState({});
    const [CampaignLoading, setCampaignLoading] = useState(true);
    const [CreateLoading, setCreateLoading] = useState(false);
    const [PublishData, setPublishData] = useState([])
    const [ReportageResults, setReportageResults] = useState({
        loading: false,
        loadingProgress: 0,
        data: {},
    })
    const [ShowReportageModal, setShowReportageModal] = useState({
        show: false,
        selectedId: -1,
        selectedReportage: {}
    });
    const [ReportageSingleData, setReportageSingleData] = useState({
        loading: false,
        data: {},
    })

    const handleSelectBacklink = (backlink) => {
        let helpArr = [...PublishData];

        helpArr = helpArr.filter(item => item?.selectedBacklink !== backlink?.id);

        if (helpArr.length === PublishData?.length) {
            helpArr.push({
                selectedBacklink: backlink?.id,
                selectedBacklinkObj: backlink,
                tag: [],
                publishTime: new Date(),
                primaryWord: "",
                backlinkLink: "",
                renew: false,
                description: "",
            })
        }

        setPublishData(helpArr);

    }

    const handleChangePublishDataForm = (key, value, index) => {
        // let helpArr = [...PublishData];

        // helpArr[index][key] = value;

        setPublishData((oldValue) =>
            oldValue.map((item, i) => {
                if (i === index) {
                    return { ...item, [key]: value };
                }
                return item;
            })
        );
    }


    const handleRemoveUploadedReportageFile = (e) => {
        e.preventDefault();
        setPublishData({ ...PublishData, reportageFile: null })
    }

    const handleRemoveUploadedReportageAttachFile = (e) => {
        e.preventDefault();
        setPublishData({ ...PublishData, reportageAttachFile: null })
    }

    const handleDrop = (e, stateName) => {
        e.preventDefault();

        if (ReportageResults.loading) {
            return
        }

        const file = e.dataTransfer.files;
        if (stateName === "reportageFile") {
            setPublishData({ ...PublishData, reportageFile: file })
            handleUploadFile()
        } else if (stateName === "reportageAttachFile") {
            setPublishData({ ...PublishData, reportageAttachFile: file })
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleGetCampaignReportage = () => {
        getSingleCampaign(id).then(({ data }) => {
            // const seletedBacklinkId = searchParams.get("backlink");
            if(seletedBacklinkId && !editMode){
                handleSelectBacklink(data?.backlink?.find(item => item?.id === parseInt(seletedBacklinkId)) || {})
            }


            if (editMode && seletedBacklinkId) {

                const selectedBacklink = data?.backlink?.find(item => item?.id === parseInt(seletedBacklinkId));

               const helpObj =  {
                    selectedBacklink: selectedBacklink?.id,
                    selectedBacklinkObj: selectedBacklink || {},
                    tag: [],
                    publishTime: new Date(selectedBacklink?.publish_suggest || ""),
                    primaryWord: (selectedBacklink?.key_words || "")?.replace(/\[|\]|"/g, '').replaceAll(',', ' - '),
                    backlinkLink: selectedBacklink?.address,
                    renew: selectedBacklink?.reminder === 1 ? true : false,
                    description: selectedBacklink?.description,
                }

                setPublishData([helpObj])
            }

            setCampaignLoading(false);
            setCampaignReport(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetReportageDetail = () => {
        getUserReportageِDetail(id).then(({ data }) => {
            setCampaignReport(data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleGetOneReportage = (reportageId) => {

        console.log(reportageId)

        if (reportageId === -1 || ReportageSingleData.loading || !ShowReportageModal.show) return

        setReportageSingleData({
            ...ReportageSingleData,
            loading: true,
        })
        getUserReportageِDetail(reportageId).then(({ data }) => {
            setReportageSingleData({
                loading: false,
                data: data
            })
        }).catch((err) => {
            setReportageSingleData({
                loading: false,
                data: {}
            })
            console.log(err);
        })
    }

    const handleCreateReportage = () => {

        const promises = [];

        for (let i = 0; i < PublishData.length; i++) {
            const element = PublishData[i];
            if (element.selectedBacklink === -1) {
                toast.error("یک بک لینک را انتخاب کنید!");
                $('html, body').animate({
                    scrollTop: $("#step1").offset().top - 50
                }, 400);
                return
            }

            if (element.primaryWord.trim() === "") {
                toast.error("کلمه کلیدی خود را وارد کنید!");
                $('html, body').animate({
                    scrollTop: $("#step2").offset().top - 50
                }, 10);
                return
            }
            if (element.backlinkLink === "") {
                toast.error("آدرس بک لینک خود را وارد کنید!");
                $('html, body').animate({
                    scrollTop: $("#step2").offset().top - 50
                }, 10);
                return
            }
            if (element.publishTime === "") {
                toast.error("زمان پیشنهادی خود را تعیین کنید!");
                $('html, body').animate({
                    scrollTop: $("#step2").offset().top - 50
                }, 10);
                return
            }

            // if (element.tag.length === 0) {
            //     toast.error("کلمات کلیدی خود را وارد کنید!");
            //     $('html, body').animate({
            //         scrollTop: $("#step2").offset().top - 50
            //     }, 10);
            //     return
            // }

            let formData = new FormData();
            formData.append("backlink_client_id", element.selectedBacklink);
            formData.append("description", element.description);
            formData.append("key_words[]", element.primaryWord);

            // for (let i = 0; i < element.tag.length; i++) {
            //     const elementTag = element.tag[i];
            //     formData.append("key_words[]", elementTag);

            // }

            formData.append("address", element.backlinkLink);
            formData.append("reminder", element.renew ? 1 : 0);
            formData.append("publish_suggest", jMoment(new Date(element.publishTime)).format("YYYY-MM-DD"));
            
            let promise;
            
            if (editMode && parseInt(seletedBacklinkId) === parseInt(element.selectedBacklink)) {
                formData.append("_method", "put");
                promise = updateUserBacklink(element.selectedBacklink, formData);
            } else {
                promise = createUserBacklink(formData);
            }

            promises.push(promise);

        }



        setCreateLoading(true);

        Promise.all(promises).then((res) => {
            toast.success("با موفقیت ثبت شد")
            setCreateLoading(false);
            navigate(routes.backLink.myBackLink)

        }).catch((err) => {
            console.log(err);
            setCreateLoading(false);

        })
        // console.log(formData);
    }

    const handleAddReportageFile = (file) => {

        if (ReportageResults.loading) {
            return
        }

        setPublishData({ ...PublishData, reportageFile: file })
        // handleUploadFile();
    }

    const handleUploadFile = () => {

        const formData = new FormData();

        formData.append("file", PublishData.reportageFile[0]);

        setReportageResults({ data: {}, loading: true });
        const inter = setInterval(() => {
            setReportageResults(prevCounter => prevCounter.loadingProgress + 1);
        }, 1000)
        uploadReporatgeFile(formData).then(({ data }) => {
            clearInterval(inter);
            setReportageResults({ data: data, loading: false });
            console.log(data)
        }).catch((err) => {
            clearInterval(inter);
            setReportageResults({ ...ReportageResults, loading: false });
            console.log(err)
        })
    }

    const handleCloseModal = () => {
        setShowReportageModal({
            show: false,
            selectedId: -1,
            selectedReportage: {}
        })
    }

    const handleOpenDatePicker = (index) => {
        $(".datePickerInput").eq(index).focus();
    }

    useEffect(() => {
        handleGetCampaignReportage();
    }, [id])

    useEffect(() => {
        if (PublishData.length > 0) {

            setStepArray((oldValue) => {
                // Create a new array by mapping over the old array and updating the status
                const newArray = oldValue.map((step, index) => {
                    if (index === 0) {
                        return { ...step, status: "pass" };
                    } else if (index === 1) {
                        return { ...step, status: "current" };
                    }
                    return step; // Return unchanged for other steps
                });
            
                return newArray;
            })
        }else{
            setStepArray(STEP_ARRAY)

        }
    }, [PublishData])

    useEffect(() => {
        handleGetOneReportage(ShowReportageModal.selectedId);
    }, [ShowReportageModal.show])



    return (
        <>
            <Helmet>
                <title>ساخت بک لینک</title>
            </Helmet>

            <SiteModal
                modalDesign={2}
                title={"بک لینک خبر"}
                showModal={ShowReportageModal.show}
                closeModal={handleCloseModal}
            >

                {/* {ReportageSingleData.loading ?

                    <>
                        <div className="d-flex align-items-center justify-content-center my-3">
                            <LoadingData />
                        </div>
                    </>
                    :
                    <>
                    </>
                } */}
                <ReportageDetailModal
                    reportageData={ShowReportageModal.selectedReportage?.reportage}
                    justLaw={true}
                />


            </SiteModal>

            <div className="container">
                <div className="headerCreateReportageMainBox">

                    <div className="siteTempTitle">ساخت بک لینک</div>

                    <StepPattern stepArray={StepArray} />

                    {/* <Link to={}> */}
                    <div className="siteTempBtn headerCreateReportageBack" onClick={() => navigate(-1)}>

                        <div>بازگشت</div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <path d="M25.9997 25.6773L3.21484 3" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M25.7848 3.32263L3 25.9999" stroke="#28323D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </div>
                    {/* </Link> */}
                </div>


                <div className="customBox">

                    <div className="createReportageBodyMainBox">


                        <div className="createReportageBodyHeadBox">
                            <div className="siteTempTitle">
                                وبسایت مورد نظر خود را انتخاب کنید
                            </div>
                            <div className="createReportageBodyCampaignTitle">
                                {CampaignReport?.name}
                            </div>
                        </div>

                        <div id="step1" className="createReportageItemBox">
                            <div className="createReportageItemStepBox">
                                <div>مرحله</div>
                                <div className="step">01</div>
                            </div>
                            <div className="createReportageItemCard">

                                {CampaignLoading ?
                                    <>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <LoadingData />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {CampaignReport?.backlink?.map((backlink, index) =>

                                            <>
                                                <div
                                                    className={"showCartListCard showOnCreateCampaign" + ((backlink?.status_name === "منتشر شده") ? " disable" : "")}
                                                >
                                                    <CheckboxTemplate
                                                        // name="reportageItem"
                                                        checked={PublishData.findIndex(item => item?.selectedBacklink === backlink?.id) > -1}
                                                        value={backlink?.id}
                                                        onChange={e => { handleSelectBacklink(backlink); console.log(e.target); }}
                                                    >
                                                        <div className="d-flex align-items-center justify-content-between">

                                                            <div className="showCartListCardHeader">
                                                                <div className="showCartListCardImage">
                                                                    <img src={backlink?.backlink?.news?.image || "/assets/images/alef.png"} alt="" />
                                                                </div>
                                                                <div className="showCartListCardTitle">
                                                                    {backlink?.backlink?.news?.name}
                                                                </div>
                                                                <div className="showCartListCardLink">
                                                                    {handleGenerateLink(backlink?.backlink?.news?.site)}
                                                                </div>
                                                            </div>

                                                            <div className="showCartListCardBody">
                                                                {/* <div className="reportageCreateListCount">
                                                                    <div className="text-accentColor">{index === 2 ? 0 : 3}</div>
                                                                    <div className="text-accentColor">{(reportage?.file || reportage?.status_name === "منتشر شده") ? "0" : "1"}</div>
                                                                    <div className="text-gray3">باقی مانده</div>
                                                                </div> */}

                                                                {backlink?.status_name === "منتشر شده" ?
                                                                    <>
                                                                        <div className="publishReportageContentInList">
                                                                            منتشر شده است
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="showCartListCardShortDescBox backlink">
                                                                            <div className="showCartListCardShortDescCard">
                                                                                <div className="showCartListCardShortDescCardImage">
                                                                                    <img src="/assets/images/global-search.svg" alt="" />
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCardBody">
                                                                                    {/* <div className="showCartListCardShortDescCardTitle"></div> */}
                                                                                    {/* <div className="showCartListCardShortDescCardValue">{reportage?.reportage?.info?.image_count}</div> */}
                                                                                    <div className="showCartListCardShortDescCardValue">{backlink?.backlink?.release_place?.title}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="showCartListCardShortDescCard">
                                                                                <div className="showCartListCardShortDescCardImage">
                                                                                    <img src="/assets/images/location.svg" alt="" />
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCardBody">
                                                                                    {/* <div className="showCartListCardShortDescCardTitle">کلمات</div> */}
                                                                                    <div className="showCartListCardShortDescCardValue">{backlink?.backlink?.insert_place?.title}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="showCartListCardShortDescCard">
                                                                                <div className="showCartListCardShortDescCardImage">
                                                                                    <img src="/assets/images/calendar.svg" alt="" />
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCardBody">
                                                                                    {/* <div className="showCartListCardShortDescCardTitle">لینک </div> */}
                                                                                    <div className="showCartListCardShortDescCardValue">{backlink?.backlink_price?.month} ماهه</div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="showCartListCardShortDescCard">
                                                                                <div className="showCartListCardShortDescCardImage">
                                                                                    <img src="/assets/images/noFollow.svg" alt="" />
                                                                                </div>
                                                                                <div className="showCartListCardShortDescCardBody">
                                                                                    <div className="showCartListCardShortDescCardTitle">لینک نوفالو</div>
                                                                                    <div className="showCartListCardShortDescCardValue">1</div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>
                                                                    </>

                                                                }


                                                            </div>

                                                        </div>


                                                    </CheckboxTemplate>
                                                </div>
                                            </>

                                        )}

                                        {CampaignReport?.backlink?.length === 0 &&

                                            <>
                                                <NoDataComponent text={"بک لینکی یافت نشد !"} />
                                            </>

                                        }

                                    </>
                                }


                            </div>
                        </div>

                        {PublishData.length === 0 &&
                            <>
                                <div
                                    style={{
                                        width: "calc(100% - 70px)",
                                        marginRight: "auto"
                                    }}
                                >
                                    <SiteAlert
                                        status={"error"}
                                        showImage={true}
                                    >
                                        هنوز هیچ وبسایت را انتخاب نکرده اید ، یکی از رسانه هارا به جهت انتشار انتخاب نمایید.
                                    </SiteAlert>
                                </div>
                            </>
                        }



                        {PublishData.length > 0 &&
                            <>
                                <div className="text-blueColor fw-bold mb-3 mt-5 configBacklinkCreateTitle">
                                    تنظیمات بک لینک
                                </div>

                                {PublishData.map((backlink, index) =>
                                    <>

                                        <div id="step2" className="createReportageItemBox backlink" key={`selectedBacklinkForm-${index}`}>
                                            <div className="createReportageItemStepBox">
                                                {index === 0 &&
                                                    <>
                                                        <div>مرحله</div>
                                                        <div className="step">02</div>
                                                    </>
                                                }
                                            </div>
                                            <div className="createReportageItemCard position-relative backlink">

                                                <div className="newsAgencyCreateBacklinkBox">
                                                    <img src={backlink?.selectedBacklinkObj?.backlink?.news?.image || imageThumbUrl} alt="" />
                                                    <div className="newsAgencyCreateBacklinkTitle">
                                                        {backlink?.selectedBacklinkObj?.backlink?.news?.name}
                                                    </div>
                                                </div>

                                                <InputTemplate
                                                    title={"کلمه کلیدی"}
                                                    value={backlink.primaryWord}
                                                    onChange={e => handleChangePublishDataForm("primaryWord", e.target.value, index)}
                                                />
                                                {/* <div className="siteTempInputLabel">کلمه کلیدی</div>
                                                <TagsInput
                                                    className="siteTempInput tagTempInput mt-2"
                                                    inputProps={{ placeholder: "کلمه کلیدی" }}
                                                    value={backlink.tag}
                                                    onChange={(tags) => handleChangePublishDataForm("tag", tags, index)}
                                                /> */}

                                                <InputTemplate
                                                    title={"آدرس بک لینک"}
                                                    parentClassName={"mt-2 backlinkAddressLinkInCreate"}
                                                    placeholder={"دقیقا آدرس مد نظر را وارد بفرمایید.      نمونه لینک :    https://test.ir"}
                                                    value={backlink.backlinkLink}
                                                    onChange={e => handleChangePublishDataForm("backlinkLink", e.target.value, index)}
                                                />

                                                <div className="backlinkStep2DateAndRenewMainBox">

                                                    <div className="selectShowTimeReportageMainBox backlink">
                                                        <div className="selectShowTimeReportageBox">
                                                            <img src="/assets/images/calendar.svg" alt="" />
                                                            <DatePickerComponent
                                                                value={new Date(backlink?.publishTime)} 
                                                                placeholder="زمان پیشنهادی انتشار"
                                                                inputClassName={"datePickerInput"}
                                                                onChange={value => handleChangePublishDataForm("publishTime", value, index)}
                                                            // onChange={value => setPublishData({ ...PublishData, publishTime: value })}
                                                                minDate={new Date()}
                                                            />
                                                        </div>

                                                        <div className="siteTempBtn justify-content-center" onClick={() => handleOpenDatePicker(index)}>
                                                            <div>انتخاب از روی تقویم</div>
                                                        </div>

                                                    </div>

                                                    <div className="backlinkRenewBox">
                                                        <div className="backlinkRenewBoxHeader">
                                                            <img src="/assets/images/message-time.svg" alt="" />
                                                            <div>آیا برای تمدید بک لینک به شما یادآوری شود ؟</div>
                                                        </div>
                                                        <div className="backlinkRenewBoxBody">
                                                            <div className={"siteTempBtn" + (backlink?.renew ? " active" : "")}
                                                                onClick={e => handleChangePublishDataForm("renew", true, index)}
                                                            >بله</div>
                                                            <div className={"siteTempBtn" + (backlink?.renew ? "" : " active")}
                                                                onClick={e => handleChangePublishDataForm("renew", false, index)}
                                                            >خیر</div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="selectPublishAdvanceDataRightBox w-100 mt-2">

                                                    <div className="text-blueColor fw-bold mb-2">
                                                        توضیحات به نمایش دهنده
                                                    </div>

                                                    <textarea
                                                        className="siteTempTextarea"
                                                        rows="6"
                                                        value={backlink?.description}
                                                        onChange={e => handleChangePublishDataForm("description", e.target.value, index)}
                                                    // onChange={e => setPublishData({ ...PublishData, description: e.target.value })}
                                                    />

                                                </div>


                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        }


                    </div>

                    {PublishData.length > 0 &&

                        <div className="createReportageFooterMainBox">
                            <LoadingButton
                                className={"submitBtnWithCheckmark"}
                                onClick={handleCreateReportage}
                                loading={CreateLoading}
                            >
                                <div className="checkmarkSingle"></div>
                                <div>ثبت بک لینک</div>
                            </LoadingButton>
                        </div>

                    }


                </div>

            </div>
        </>
    );
}

export default CreateBacklinkPage;