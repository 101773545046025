import { useState } from "react";
import InputTemplate from "../formComponents/inputComponent";
import LoadingButton from "../loadingButton";
import { toast } from "react-toastify";
import { discountOrder } from "../../service/cart";
import { useDispatch, useSelector } from "react-redux";
import { setDiscountCartAction } from "../../store/action/cart_action";
import TitleWithCircle from "../title";

const DiscountCodeComponent = ({
    isForeign = false,
    backLink = false,
}) => {

    const userCart = useSelector(store => {
        if (isForeign) {
            return store.foreign_reportage_cart
        } else if (backLink) {
            return store.backlink_cart
        } else {
            return store.cart
        }
    });

    const dispatch = useDispatch();

    const [ShowDiscountInput, setShowDiscountInput] = useState(false);
    const [LoadingCode, setLoadingCode] = useState(false)
    const [CodeText, setCodeText] = useState('');
    const [ShowMessage, setShowMessage] = useState("");
    const [ErrorMode, setErrorMode] = useState(false)

    const handleAddDiscountCode = () => {
        if (CodeText?.trim() === "") {
            toast.error("لطفا کد تخفیف را وارد کنید!");
            return
        }
        setLoadingCode(true);

        discountOrder({
            discount_code : CodeText,
            order_id : userCart?.id
        }).then(({data}) => {
            dispatch(setDiscountCartAction({
                code : CodeText,
                amount : data?.price
            }))
            setLoadingCode(false);
            // setShowDiscountInput(false);
            // toast.success(data?.message)
            setShowMessage(data?.message)
            // setCodeText('');
        }).catch((err) => {
            setErrorMode(true)
            setShowMessage(err?.data?.["message"] || err?.data?.["error"] || "مشکلی پیش آمده است")
            setLoadingCode(false);
            console.log(err)
        })

    }

    return (
        <>
            <div className="cartPageDiscountMainBox">
                <div className={"siteTempBtn design3 radius-10" + (ShowDiscountInput ? " d-none" : "")} onClick={() => setShowDiscountInput(true)}>کد تخفیف</div>

                {ShowDiscountInput &&
                    <div className={"cartPageDiscountBox design1 " + (ShowMessage === "" ? "" : " showMessage")}>
                        <TitleWithCircle
                            title={"کد تخفیف"}
                        />
                        <InputTemplate
                            placeholder={'کد تخفیف خود را وارد کنید'}
                            value={CodeText}
                            onChange={e => setCodeText(e?.target?.value)}
                        />

                        {ShowMessage !== "" &&
                            <>
                                <div className={"discountMessageText " + (ErrorMode ? " error" : "")}>{ShowMessage}</div>
                            </>
                        }

                        <div className="d-flex gap-2">
                            <div className="siteTempBtn design3"  onClick={() => {setShowDiscountInput(false); setErrorMode(false) ; setShowMessage("");setCodeText("")}}>
                                انصراف
                            </div>
                            <LoadingButton
                                className={"blueBg"}
                                loading={LoadingCode}
                                onClick={handleAddDiscountCode}
                            >
                                ثبت کد تخفیف
                            </LoadingButton>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default DiscountCodeComponent;