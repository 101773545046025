import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle_action } from "../../store/action/app_action";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

const CreateContentPage = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPageTitle_action("تولید محتوا"))
    }, [])

    return (
        <>
            <div className="customBox">
                <div className="createContentMainBox">
                    <div className="createContentImage">
                        <img src="/assets/images/document-edit-1.png" alt="" />
                    </div>
                    <div className="createContentTitle">
                        به جهت سفارش تولید محتوا در تیکت و یا به صورت تلفنی با ما در ارتباط باشید
                    </div>
                    <a href="tel:09901104954">
                        <div className="createContentContantBox">
                            <div>شماره تماس : </div>
                            <div>09901104954</div>
                        </div>
                    </a>
                    <div className="createContentFooter">
                        <Link to={routes.ticket.add} className="d-flex">
                            <div className="siteTempBtn">
                                <img src="/assets/images/message.svg" alt="" />
                                <div>ارسال تیکت </div>
                            </div>
                        </Link>
                        <a href="tel:09901104954">
                            <div className="siteTempBtn blueBg noHover">
                                <img src="/assets/images/call-outgoing.svg" alt="" />
                                <div>تماس با ما </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateContentPage;