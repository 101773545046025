import { combineReducers } from "redux";
import { themeReducer } from "./theme";
import { breadcrumbReducer } from "./breadcrumbs";
import { cartReducer } from "./cart";
import { loadingReducer } from "./loading";
import { userReducer } from "./user";
import { appReducer } from "./app";
import { backlinkCartReducer } from "./backlink_cart";
import { foreignReportageCartReducer } from "./foreign_reportage_cart";

export const rootReducer = combineReducers({
    app : appReducer,
    loading : loadingReducer,
    theme:themeReducer,
    breadcrumb:breadcrumbReducer, 
    cart : cartReducer,
    backlink_cart : backlinkCartReducer,
    foreign_reportage_cart : foreignReportageCartReducer,
    user : userReducer,
})